
    import Vue, { PropOptions } from 'vue';
    import mixins from 'vue-typed-mixins';
    import { logInvalidParams } from '@/tsfiles/errorlog';
    import * as constants from '@/tsfiles/constants';
    import * as analytics from '@/tsfiles/analytics';
    import VueConstants from '@/components/VueConstants';
    import { RecommendationUpdateEmit } from '@/tsfiles/interfaces';
    import ContentRecommendation from '@/components/content/ContentRecommendationV2.vue';
    import Avatar from '@/components/Avatar.vue';
    import Video from '@/components/Video.vue';
    import ViewAllComments from '@/components/uiutils/ViewAllComments.vue';
    import StarRating from '@/components/uiutils/StarRating.vue';
    import YearRunlength from '@/components/content/ContentYearRunlength.vue';
    import CountryCodeMenu from '@/components/uiutils/CountryCodeMenu.vue';
    import { Content, UserRecommendation, Source, SharedConstants } from 'api';

    export default mixins(VueConstants).extend({
        name: 'ContentDetails',

        components: {
            'content-recommendation': ContentRecommendation,
            'url-avatar': Avatar,
            'video-ui': Video,
            'view-all-comments': ViewAllComments,
            'star-rating': StarRating,
            'year-runlength': YearRunlength,
            'country-code-menu': CountryCodeMenu,
        },

        props: {
            content: {
                type: Object,
                required: true,
            } as PropOptions<Content>,
        },

        data() {
            return {
                recommendingUser: undefined as UserRecommendation | undefined,
                showAllCrew: false,
                countryCodes: [] as string[],
                currentCountryCode: '',
            };
        },

        watch: {
            content: {
                immediate: true,
                deep: true,
                handler(newVal: Content, oldVal: Content) {
                    if (newVal && newVal !== oldVal) {
                        if (this.content && this.content.recommendedBy && this.content.recommendedBy.length > 0) {
                            this.recommendingUser = this.content.recommendedBy[0];
                        }

                        //
                        // Set the country code 'sources' list.
                        //
                        if (newVal.sources && newVal.sources.length > 0) {
                            for (const item of newVal.sources) {
                                if (item.countryCode && !this.countryCodes.includes(item.countryCode)) {
                                    this.countryCodes.push(item.countryCode);

                                    // Default the country code to what user has is profile (set in store)
                                    if (item.countryCode === this.$store.getters.getStreamingCountryCode) {
                                        this.currentCountryCode = item.countryCode;
                                    }
                                }
                            }

                            if (this.currentCountryCode === '' && this.countryCodes.length > 0) {
                                this.currentCountryCode = this.countryCodes[0];
                            }
                        } else {
                            this.countryCodes = [] as string[];
                            this.currentCountryCode = '';
                        }
                    }
                },
            },
        },

        computed: {
            getGenreDisplayText(): string {
                if (!this.content || !this.content.genreJson || this.content.genreJson === '') {
                    logInvalidParams(this.$options.name, 'getGenreDisplayText');
                    return '';
                }

                const obj = JSON.parse(this.content.genreJson);
                if (!obj.genres) {
                    logInvalidParams(this.$options.name, 'getGenreDisplayText', this.content.genreJson);
                    return '';
                }

                let ret = '';
                for (const genre of obj.genres) {
                    if (ret !== '') {
                        ret += ', ';
                    }
                    ret += genre;
                }

                if (
                    this.content.year ||
                    (this.content.runlength && this.content.format === SharedConstants.CONTENT_FORMAT_MOVIE)
                ) {
                    return ' - ' + ret;
                }

                return ret;
            },

            getYouTubeBackupSrc(): string {
                if (this.content && this.content.backdropUrl) {
                    return this.content.backdropUrl;
                } else if (this.content && this.content.posterUrl) {
                    return this.content.posterUrl;
                }

                return '';
            },

            sourcesWithLogo(): Source[] {
                if (!this.content) {
                    logInvalidParams(this.$options.name, 'sourcesWithLogos');
                    return [] as Source[];
                }

                if (!this.content.sources || this.content.sources.length === 0) {
                    return [] as Source[];
                }

                return this.content.sources.filter((source) => {
                    return source.logo && source.countryCode === this.currentCountryCode;
                });
            },

            sourcesWithNoLogo(): Source[] {
                if (!this.content) {
                    logInvalidParams(this.$options.name, 'sourcesWithNoLogos');
                    return [] as Source[];
                }

                if (!this.content.sources || this.content.sources.length === 0) {
                    return [] as Source[];
                }

                return this.content.sources.filter((source) => {
                    return !source.logo && source.countryCode === this.currentCountryCode;
                });
            },

            isAmazonPrimeTrailer(): boolean {
                if (!this.content) {
                    logInvalidParams(this.$options.name, 'isAmazonPrimeTrailer');
                    return false;
                } else if (!this.content.trailerUrl) {
                    return false;
                }

                return this.content.trailerUrl.includes('primevideo');
            },
        },

        methods: {
            getSourceLink(source: Source): string {
                if (source.webDeepLink && source.webDeepLink !== '') {
                    return source.webDeepLink;
                } else if (source.url && source.url !== '') {
                    return source.url;
                }

                return '';
            },

            //
            // When the lower level components edit the recommendation comment, do like or unlike, or reply
            // to a comment, we eventually get the 'recommendation-updated' emit, with the data
            // RecommendationUpdateEmit.  That contains the new recommendation, and, if defined, the
            // new comments totals.
            //
            recommendationUpdated(data: RecommendationUpdateEmit) {
                if (!this.content || !data || !data.recommendation) {
                    logInvalidParams(this.$options.name, 'recommendationUpdated');
                    return;
                }

                Vue.set(this, 'recommendingUser', data.recommendation);

                if (data.totalComments) {
                    Vue.set(this.content, 'totalComments', data.totalComments);
                }

                if (data.totalFolloweeComments) {
                    Vue.set(this.content, 'totalFolloweeComments', data.totalFolloweeComments);
                }

                // Tell the parent
                this.$emit('recommendation-updated', data);

                // Go to the comments page if this was a comment reply
                if (data.type === constants.RECOMMENDATION_UPDATE_EMIT_TYPE_REPLY) {
                    this.$emit('view-comments');
                }
            },

            //
            // When a user clicks on an external link, we log the analytics event.  The
            // click is usually an "@click" without ".prevent", so the href will be triggered.
            //
            analyticsExternalClick(source: Source) {
                if (source.sourceId) {
                    const data = {
                        source_id: source.sourceId,
                        source_name: source.name,
                    };

                    analytics.logAppInteraction(analytics.ANALYTICS_ACTION_EXTERNAL_CONTENT_CLICK, 'sourceclick', data);
                }
            },

            gotoCrew(crewId: number | undefined) {
                if (!crewId) {
                    logInvalidParams(this.$options.name, 'gotoCrew');
                    return;
                }

                this.$router.push({ name: constants.ROUTE_CREW_CONTENT, params: { crewId: crewId.toString() } });
            },
        },
    });
