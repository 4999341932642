/*
 * Copyright 2019-2022 Reki LLC - All rights reserved.
 * File: eventbus.ts
 * Project: rekitv
 */

import Vue from 'vue';

//
// google:any was added here to make vetur understand
// window.google.maps.Map.  Google's typescript
// npm didn't help.  This doesn't really belong in the
// eventbus ts, but it solves the vetur complaint.
//
declare global {
    interface Window { VueEventBus: any; google: any; webkit: any; android: any; }
}

const EventBus = new Vue();
window.VueEventBus = EventBus;
export default EventBus;
