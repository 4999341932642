
    import Vue, { PropOptions } from 'vue';
    import { logInvalidParams } from '@/tsfiles/errorlog';
    import validator from 'validator';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { Utils } from '@/tsfiles/utils';
    import Avatar from '@/components/Avatar.vue';
    import { ContentService, Crew, CrewImageQueryItem, SetCrewImageRequest } from 'api';

    export default Vue.extend({
        name: 'CrewEditImageModal',

        components: {
            'url-avatar': Avatar,
        },

        props: {
            crew: {
                type: Object,
                required: false,
                default: () => undefined as Crew | undefined,
            } as PropOptions<Crew>,
        },

        data() {
            return {
                currentTab: 0,
                imageUrl: '', // For manual edit
                attributionLink: '', // For manual edit
                attributionTitle: '', // For manual edit

                imageList: [] as CrewImageQueryItem[], // From google search api results
                imageListCheckboxes: [] as any,
            };
        },

        watch: {},

        mounted() {
            this.imageSearch();
        },

        computed: {
            getModalTitle(): string {
                if (!this.crew) {
                    logInvalidParams(this.$options.name, 'getModalTitle');
                    return '';
                }

                return 'Image Edit: ' + this.crew.name;
            },
        },

        methods: {
            isInitialsAvatar(url: string | undefined): boolean {
                if (!url) {
                    return false;
                }

                return url.includes('initials.avatar');
            },

            urlValid(url: string): boolean {
                return (
                    !validator.isEmpty(url) &&
                    validator.isURL(url) &&
                    (url.startsWith('http://') || url.startsWith('https://'))
                );
            },

            //
            // Set the attribution title when the attribution link changes
            //
            attributionLinkInputChanged() {
                if (this.urlValid(this.attributionLink)) {
                    const domain = new URL(this.attributionLink);
                    this.attributionTitle = domain.hostname;
                }
            },

            //
            // Unselect all others
            //
            imageListCheckboxClicked(idx: number) {
                if (idx < 0 || idx >= this.imageList.length) {
                    logInvalidParams(this.$options.name, 'imageListCheckboxClicked');
                    return;
                }

                for (let i = 0; i < this.imageListCheckboxes.length; i++) {
                    if (i !== idx) {
                        Vue.set(this.imageListCheckboxes, i, false);
                    }
                }
            },

            //
            // Return true if save button should be disabled
            //
            disableSave(): boolean {
                if (this.currentTab === 0) {
                    return (
                        !this.urlValid(this.imageUrl) ||
                        !this.urlValid(this.attributionLink) ||
                        (this.imageUrl === this.crew.imageUrl &&
                            this.attributionLink === this.crew.imageAttributionLink)
                    );
                }

                // One of the google search images
                let selectedIdx = -1;
                for (let i = 0; i < this.imageListCheckboxes.length; i++) {
                    if (this.imageListCheckboxes[i]) {
                        selectedIdx = i;
                    }
                }

                return (
                    selectedIdx === -1 ||
                    (this.imageList[selectedIdx].imageUrl === this.crew.imageUrl &&
                        this.imageList[selectedIdx].imageAttributionUrl === this.crew.imageAttributionLink)
                );
            },

            //
            // Get a few images from google search api, based on the crew member's name and role.
            //
            async imageSearch() {
                try {
                    const ret = await ApiUtils.apiWrapper(ContentService.queryForCrewImage, this.crew.crewId);
                    if (ret && ret.images && ret.images.length > 0) {
                        this.imageList = Utils.deepCopy(ret.images);
                    } else {
                        this.imageList = [] as CrewImageQueryItem[];
                    }

                    // default selected to one that matches, if any
                    for (let i = 0; i < this.imageList.length; i++) {
                        if (
                            this.imageList[i].imageUrl === this.crew.imageUrl &&
                            this.imageList[i].imageAttributionUrl === this.crew.imageAttributionLink
                        ) {
                            Vue.set(this.imageListCheckboxes, i, true);
                        }
                    }
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            //
            // Set the new crew image and attribution to the manual values, or the selected
            // google search data if on the search tab.
            //
            async submit() {
                let imageUrl = this.imageUrl;
                let imageAttributionUrl = this.attributionLink;
                let imageAttributionTitle = this.attributionTitle;

                if (this.currentTab === 1) {
                    // Get which one is selected
                    let selectedIdx = -1;

                    for (let i = 0; i < this.imageListCheckboxes.length; i++) {
                        if (this.imageListCheckboxes[i]) {
                            selectedIdx = i;
                            break;
                        }
                    }

                    if (selectedIdx < 0) {
                        logInvalidParams(this.$options.name, 'submit');
                        return;
                    }

                    imageUrl = this.imageList[selectedIdx].imageUrl as string;
                    imageAttributionUrl = this.imageList[selectedIdx].imageAttributionUrl as string;
                    imageAttributionTitle = this.imageList[selectedIdx].imageAttributionTitle as string;
                }

                let newCrew = undefined as Crew | undefined;

                try {
                    newCrew = await ApiUtils.apiWrapper(ContentService.setCrewImage, {
                        crewId: this.crew.crewId,
                        imageUrl,
                        imageAttributionUrl,
                        imageAttributionTitle,
                    } as SetCrewImageRequest);
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }

                this.$emit('closed', newCrew);
            },

            //
            // Remove image and attribution information so the crew member's image defaults back
            // to their initials
            //
            async unsetImage() {
                let newCrew = undefined as Crew | undefined;

                try {
                    newCrew = await ApiUtils.apiWrapper(ContentService.unsetCrewImage, this.crew.crewId);
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }

                this.$emit('closed', newCrew);
            },
        },
    });
