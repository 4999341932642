
    import Vue from 'vue';
    import mixins from 'vue-typed-mixins';
    import { Utils } from '@/tsfiles/utils';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import * as constants from '@/tsfiles/constants';
    import * as analytics from '@/tsfiles/analytics';
    import { logInvalidParams, logInvalidResponse } from '@/tsfiles/errorlog';
    import { PageNavigationData } from '@/tsfiles/interfaces';
    import VueConstants from '@/components/VueConstants';
    import ProfileBackdrop from '@/components/user/ProfileBackdrop.vue';
    import Avatar from '@/components/Avatar.vue';
    import FollowButton from '@/components/uiutils/FollowButton.vue';
    import SocialMediaLinks from '@/components/user/SocialMediaLinks.vue';
    import UserProfileRekis from '@/components/user/UserProfileRekis.vue';
    import UserLists from '@/components/lists/UserLists.vue';
    import { SharedConstants, UserService, User, ListService, ContentV2, GenericPageRetrieval } from 'api';

    export default mixins(VueConstants).extend({
        name: 'UserProfile',

        components: {
            'profile-backdrop': ProfileBackdrop,
            'url-avatar': Avatar,
            'follow-button': FollowButton,
            'social-media-links': SocialMediaLinks,
            'profile-rekis': UserProfileRekis,
            'user-lists': UserLists,
        },

        props: {
            saveDS: {
                type: Boolean,
                required: false,
                default: false,
            },
        },

        data() {
            return {
                publicUrl: '',
                user: undefined as User | undefined,
                top10List: [] as ContentV2[],
                isMe: false,
                currentTab: 0,
                showBackdrop: false,

                totalLists: 0,
                totalRecommendations: 0,

                currentNavigation: { page: constants.ROUTE_USER_HOME } as PageNavigationData,
                blockedUser: false,
            };
        },

        watch: {
            '$route.params.publicUrl': {
                immediate: true,
                handler(newVal, oldVal) {
                    if (newVal && newVal !== oldVal) {
                        this.publicUrl = newVal;
                        this.fetchUser();

                        //
                        // If page navigation contains data, process.
                        //
                        const nav = this.$store.getters.getPageNavigation(constants.ROUTE_USER_HOME);
                        if (nav && nav.tab) {
                            this.$nextTick(() => {
                                this.currentTab = nav.tab;
                                this.currentNavigation.tab = nav.tab;
                            });
                        }
                    }
                },
            },
        },

        beforeDestroy() {
            this.$store.commit('setPageNavigation', this.currentNavigation);
        },

        computed: {
            getNumFollowingText(): string {
                if (!this.user || !this.user.numFollowing) {
                    return '0 Following';
                }

                return this.user.numFollowing + ' Following';
            },

            getNumFollowersText(): string {
                if (!this.user || !this.user.numFollowers) {
                    return '0 Followers';
                }

                if (this.user.numFollowers === 1) {
                    return '1 Follower';
                }

                return this.user.numFollowers + ' Followers';
            },

            getRecommendationsTabTitle(): string {
                return "Reki's (" + this.totalRecommendations + ')';
            },

            getListTabTitle(): string {
                return 'Lists (' + this.totalLists + ')';
            },
        },

        methods: {
            async fetchUser() {
                try {
                    this.blockedUser = false;

                    this.user = await ApiUtils.apiWrapper(UserService.getUser, { publicUrl: this.publicUrl });
                    if (!this.user || !this.user.publicUrl) {
                        logInvalidResponse(this.$options.name, 'fetchUser');
                        return;
                    }

                    this.isMe = this.$store.getters.isUsersPublicUrl(this.publicUrl);

                    //
                    // If the parent asked us to save this userId as the DS in adChannel,
                    // make the change.  Overwrite DS, but leave everything else the same.  We
                    // tried ignoring internal celebrities, but since Paul English is marked
                    // as a celebrity, we couldn't add this check to the 'if'.
                    //
                    // NOTE: If there is a ds query parameter, which will set the downloadSource
                    // in router.ts afterEach, this will overwrite that value.  For example:
                    //    https://m.reki.tv/@amy?ds=tiktok&cs=video&aid=92
                    // router.ts will set downloadSource to tiktok, but this code will then
                    // reset to amy's user Id. After discussion with Sam, we said that's ok for now, since
                    // there are no plans for ad buys that go to a specific user's profile.
                    //
                    if (!this.isMe && this.saveDS) {
                        const adChannel = this.$store.getters.getAdChannel;
                        adChannel.downloadSource = this.user.id?.toString();
                        this.$store.commit('setAdChannel', adChannel);
                    }

                    //
                    // Fetch the user's top-10 for backdrop images
                    //
                    const ret = await ApiUtils.apiWrapper(ListService.getUserLists, this.publicUrl);

                    //
                    // Loop through looking for top10.  If not found, or no content, ignore.
                    //
                    if (ret && ret.predefinedList) {
                        for (const item of ret.predefinedList) {
                            if (item.predefinedName === SharedConstants.PREDEFINED_TOP10_LIST) {
                                if (item.numContentItems) {
                                    const listRet = await ApiUtils.apiWrapper(ListService.getListContents, {
                                        listId: item.listId,
                                        paging: {
                                            publicUrl: this.$router.currentRoute.params.publicUrl,
                                            pageNumber: 1,
                                            numberOfItems: 10,
                                        } as GenericPageRetrieval,
                                    });

                                    if (listRet && listRet.list) {
                                        this.top10List = listRet.list;
                                    }
                                }
                                break;
                            }
                        }
                    } else {
                        this.top10List = [] as ContentV2[];
                    }

                    // Reset showbackdrop, since a new user could be fetched.
                    this.showBackdrop =
                        (this.user !== undefined &&
                            this.user.profileBackdrop !== undefined &&
                            this.user.profileBackdrop.imageUrl !== undefined) ||
                        this.top10List.length > 0;
                } catch (error: any) {
                    if (
                        error.response.status === 409 &&
                        error.response.data.message === SharedConstants.WARNING_RESOURCE_BLOCKED
                    ) {
                        this.blockedUser = true;
                        return;
                    }

                    Utils.CommonErrorHandler(error);
                }
            },

            async followUser() {
                if (!this.$router.currentRoute.params.publicUrl) {
                    logInvalidParams(this.$options.name, 'followUser');
                    return;
                }

                //
                // If not signed in, go to  if allowed to; otherwise the marketing page.
                //
                if (this.$store.getters.isSignedOut) {
                    if (this.$store.getters.hideWebsiteSignin) {
                        this.$router.push({ name: constants.ROUTE_MARKETING });
                    } else {
                        this.$router.push({ name: constants.ROUTE_FIREBASE_SIGNIN });
                    }

                    return;
                }

                try {
                    await ApiUtils.apiWrapper(UserService.followUser, this.$router.currentRoute.params.publicUrl);

                    this.fetchUser();

                    analytics.logAppInteraction(
                        analytics.ANALYTICS_ACTION_FOLLOW_FRIEND,
                        this.$router.currentRoute.params.publicUrl,
                    );
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async unfollowUser() {
                if (!this.$router.currentRoute.params.publicUrl) {
                    logInvalidParams(this.$options.name, 'unFollowUser');
                    return;
                }

                try {
                    await ApiUtils.apiWrapper(UserService.unfollowUser, this.$router.currentRoute.params.publicUrl);
                    this.fetchUser();

                    analytics.logAppInteraction(
                        analytics.ANALYTICS_ACTION_UNFOLLOW_FRIEND,
                        this.$router.currentRoute.params.publicUrl,
                    );
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            openSettings() {
                this.$router.push({ name: constants.ROUTE_USER_SETTINGS });
            },

            async reportAbuse() {
                if (!this.user || !this.user.publicUrl) {
                    logInvalidParams(this.$options.name, 'reportAbuse');
                    return;
                }

                try {
                    await ApiUtils.apiWrapper(UserService.reportAbuse, {
                        publicUrl: this.user.publicUrl,
                    });

                    alert('This user has been reported as potentially abusing the platform');
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async blockUser() {
                if (!this.user || !this.user.publicUrl) {
                    logInvalidParams(this.$options.name, 'blockUser');
                    return;
                }

                try {
                    await ApiUtils.apiWrapper(UserService.blockUser, this.user.publicUrl);

                    alert('This user has been block and you will no longer see them');

                    //
                    // Go to the user's timeline.  We don't want to do this.$router.go(-1),
                    // since the previous page may have been the blocked user's list contents, etc.
                    //
                    this.$router.replace({ name: constants.ROUTE_USER_TIMELINE });
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },
        },
    });
