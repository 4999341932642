
    import Vue, { PropOptions } from 'vue';
    import mixins from 'vue-typed-mixins';
    import VueConstants from '@/components/VueConstants';
    import { logInvalidParams, logInvalidResponse } from '@/tsfiles/errorlog';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { Utils } from '@/tsfiles/utils';
    import * as analytics from '@/tsfiles/analytics';
    import * as constants from '@/tsfiles/constants';
    import { SortMenuItem, RecommendationUpdateEmit } from '@/tsfiles/interfaces';
    import { RoleUtils } from '@/tsfiles/roleutils';
    import Avatar from '@/components/Avatar.vue';
    import ContentRow from '@/components/content/ContentRowV2.vue';
    import SortMenu from '@/components/uiutils/SortMenu.vue';
    import FilterMenu from '@/components/uiutils/FilterMenu.vue';
    import AddRecommendationModal from '@/components/content/AddRecommendationModal.vue';
    import RecommendationEdit from '@/components/content/RecommendationEditModal.vue';
    import ListAddRemoveContentModal from '@/components/lists/ListAddRemoveContentModal.vue';
    import EditImageModal from '@/components/content/CrewEditImageModal.vue';
    import ModalAlert from '@/generic-modals/ModalAlert.vue';
    import {
        ContentService,
        ContentV2,
        Crew,
        SharedConstants,
        GenericPageRetrieval,
        FilterOption,
        UserRecommendation,
    } from 'api';

    export default mixins(VueConstants).extend({
        name: 'CrewContent',

        components: {
            'content-row': ContentRow,
            'url-avatar': Avatar,
            'sort-menu': SortMenu,
            'filter-menu': FilterMenu,
            'add-recommendation': AddRecommendationModal,
            'recommendation-edit': RecommendationEdit,
            'list-add-remove-content': ListAddRemoveContentModal,
            'edit-image': EditImageModal,
            'modal-alert': ModalAlert,
        },

        props: {},

        data() {
            return {
                crew: undefined as Crew | undefined,
                content: [] as ContentV2[],
                userWantsToAddContent: false,

                userHasOwnRecommendations: false,
                ownRecommendationsCheckbox: false,
                hiddenCheckbox: false,
                moviesOnlyCheckbox: false,
                editImage: false,

                //
                // Three string arrays used for filterBy when retrieving content.  These
                // container all the genres and sources for the entire list of
                // followee recommendations.
                //
                genresForFiltering: [] as string[],
                topSourcesForFiltering: [] as string[],
                allOtherSourcesForFiltering: [] as string[],

                //
                // Lists of selected items used for filtering.  They are saved
                // when the user applies genre or source filters from the FilterMenu.
                //
                selectedGenresForFiltering: [] as any[],
                selectedTopSourcesForFiltering: [] as any[],
                selectedAllOtherSourcesForFiltering: [] as any[],

                totalItems: 0,
                currentPage: 1,
                perPage: constants.RECOMMENDATIONS_PER_PAGE,

                currentSort: SharedConstants.SORT_BY_YEAR,
                sortMenuList: [
                    { name: 'Recently Recommended or Updated', value: SharedConstants.SORT_BY_UPDATED_DATE },
                    { name: 'Rating', value: SharedConstants.SORT_BY_RATING },
                    { name: 'Year (newest to oldest)', value: SharedConstants.SORT_BY_YEAR },
                ] as SortMenuItem[],

                genreFilterOptions: [] as FilterOption[],
                sourceFilterOptions: [] as FilterOption[],

                addRecommendation: undefined as ContentV2 | undefined,
                editRecommendation: undefined as ContentV2 | undefined,
                deleteRecommendation: undefined as ContentV2 | undefined,
                listAddRemoveContent: undefined as ContentV2 | undefined,
            };
        },

        watch: {},

        mounted() {
            if (!this.$router.currentRoute.params.crewId) {
                logInvalidParams(this.$options.name, 'mounted');
                return;
            }

            const val = parseInt(this.$router.currentRoute.params.crewId as string, 10);
            if (isNaN(val)) {
                logInvalidParams(
                    this.$options.name,
                    'mounted: invalid Id - ' + this.$router.currentRoute.params.crewId,
                );
                return;
            }

            this.fetchCrew(val);
        },

        computed: {
            //
            // The header and footer offsets are needed to determine default height.
            //
            cssVars(): any {
                return {
                    '--header-offset': constants.HEADER_HEIGHT + 'px',
                    '--footer-offset': constants.FOOTER_HEIGHT + 'px',
                };
            },

            getGenreTitle(): string {
                let ret = 'Genre Filter';

                if (this.genreFilterOptions.length > 0) {
                    ret += ' (' + this.genreFilterOptions.length + ')';
                }

                return ret;
            },

            getSourcesTitle(): string {
                let ret = 'Source Filter';

                if (this.sourceFilterOptions.length > 0) {
                    ret += ' (' + this.sourceFilterOptions.length + ')';
                }

                return ret;
            },

            filtersApplied(): boolean {
                return (
                    this.selectedGenresForFiltering.length > 0 ||
                    this.selectedTopSourcesForFiltering.length > 0 ||
                    this.selectedAllOtherSourcesForFiltering.length > 0
                );
            },

            //
            // We can only show the add content component if the user has a verified email or phone.
            // If they are allowed to add content, show the form if they explicitly asked to add content
            // of if there are no results at all.  The results list can be empty if all results are filtered
            // out, so we need to check for that case.  If that happens we don't want to force the
            // add content component.
            //
            showAddContent(): boolean {
                // if (!this.$store.getters.isEmailVerified && !this.$store.getters.isPhoneVerified) {
                //      return false;
                // }

                return (
                    this.userWantsToAddContent ||
                    (this.totalItems === 0 && !this.filtersApplied && !this.userHasOwnRecommendations)
                );
            },

            canEditImage(): boolean {
                return RoleUtils.CanSupportUsers();
            },
        },

        methods: {
            isInitialsAvatar(url: string | undefined): boolean {
                if (!url) {
                    return false;
                }

                return url.includes('initials.avatar');
            },

            async fetchCrew(crewId: number) {
                if (crewId <= 0) {
                    logInvalidParams(this.$options.name, 'fetchCrewContent');
                    return;
                }

                try {
                    this.crew = await ApiUtils.apiWrapper(ContentService.getCrewById, crewId);

                    this.fetchAll();
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            fetchAll() {
                //
                // WARNING: The user may have selected filtering options.  We reset
                // here, since the list can change.  If the user is in the middle
                // of filtering, this change will be jarring.  We might have to compare
                // the new set of genre and sources and leave the filters selected if they
                // still exist.  For now, just clear out so the user sees everything.
                //
                this.selectedGenresForFiltering = [] as any[];
                this.selectedTopSourcesForFiltering = [] as any[];
                this.selectedAllOtherSourcesForFiltering = [] as any[];
                this.genreFilterOptions = [] as FilterOption[];
                this.sourceFilterOptions = [] as FilterOption[];
                this.currentPage = 1;
                this.ownRecommendationsCheckbox = false;
                this.hiddenCheckbox = false;
                this.moviesOnlyCheckbox = false;

                this.fetchGenresAndSources();
                this.fetchCrewContent();
            },

            async fetchGenresAndSources() {
                if (!this.crew || !this.crew.crewId) {
                    logInvalidParams(this.$options.name, 'fetchCrewContent');
                    return;
                }

                try {
                    const ret = await ApiUtils.apiWrapper(
                        ContentService.getGenresAndSourcesForCrewContent,
                        this.crew.crewId,
                    );

                    if (ret.genres) {
                        this.genresForFiltering = ret.genres;
                    } else {
                        this.genresForFiltering = [] as string[];
                    }

                    if (ret.topSources) {
                        this.topSourcesForFiltering = ret.topSources;
                    } else {
                        this.topSourcesForFiltering = [] as string[];
                    }

                    if (ret.allOtherSources) {
                        this.allOtherSourcesForFiltering = ret.allOtherSources;
                    } else {
                        this.allOtherSourcesForFiltering = [] as string[];
                    }
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async fetchCrewContent() {
                if (!this.crew || !this.crew.crewId) {
                    logInvalidParams(this.$options.name, 'fetchCrewContent');
                    return;
                }

                try {
                    const filterOptions = this.genreFilterOptions.concat(this.sourceFilterOptions);

                    // Add in the 'movies only' if checked
                    if (this.moviesOnlyCheckbox) {
                        filterOptions.push({
                            type: SharedConstants.FILTER_BY_FORMAT,
                            data: SharedConstants.FILTER_FORMAT_MOVIES,
                        });
                    }

                    //
                    // TODO: There's a bug in the backend where it returns no results if there are only
                    // hidden results.
                    //

                    const ret = await ApiUtils.apiWrapper(ContentService.getCrewContent, {
                        uniqueIdRequested: this.crew.crewId,
                        pageNumber: this.currentPage,
                        numberOfItems: this.perPage,
                        sortBy: [{ type: this.currentSort, descending: true }],
                        filterBy: filterOptions,
                    } as GenericPageRetrieval);

                    if (ret.list) {
                        this.content = ret.list;
                        this.totalItems = ret.totalItemsIrregardlessOfPaging as number;

                        // Move to top of page.  Paging from bottom will not scroll to top unless forced
                        window.scrollTo(0, 0);
                    } else {
                        this.content = [] as ContentV2[];
                        this.totalItems = 0;
                    }
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            pageChanged(newPage: number) {
                this.currentPage = newPage;
                this.fetchCrewContent();
            },

            sortResults(type: string) {
                this.currentSort = type;
                this.pageChanged(1);
            },

            resetGenreFilters() {
                this.genreFilterOptions = [] as FilterOption[];
                this.fetchCrewContent();
            },

            applyGenreFilters(data: any) {
                const selectedCheckboxes = data.top;

                this.currentPage = 1;

                //
                // Save the list so that it stays the same when reopening the filter menu.
                //
                this.selectedGenresForFiltering = Utils.deepCopy(selectedCheckboxes);

                // FilterOptions are sent to the server when fetching content.
                this.genreFilterOptions = [] as FilterOption[];

                for (let i = 0; i < this.genresForFiltering.length; i++) {
                    if (selectedCheckboxes[i]) {
                        this.genreFilterOptions.push({
                            type: 'genre',
                            data: this.genresForFiltering[i],
                        } as FilterOption);
                    }
                }

                this.fetchCrewContent();
            },

            applySourceFilters(data: any) {
                const topSelected = data.top;
                const allOtherSelected = data.allOther;

                this.currentPage = 1;

                //
                // Save the list so that it stays the same when reopening the filter menu.
                //
                this.selectedTopSourcesForFiltering = Utils.deepCopy(topSelected);
                this.selectedAllOtherSourcesForFiltering = Utils.deepCopy(allOtherSelected);

                this.sourceFilterOptions = [] as FilterOption[];

                for (let i = 0; i < this.topSourcesForFiltering.length; i++) {
                    if (topSelected[i]) {
                        this.sourceFilterOptions.push({
                            type: 'source',
                            data: this.topSourcesForFiltering[i],
                        } as FilterOption);
                    }
                }

                for (let i = 0; i < this.allOtherSourcesForFiltering.length; i++) {
                    if (allOtherSelected[i]) {
                        this.sourceFilterOptions.push({
                            type: 'source',
                            data: this.allOtherSourcesForFiltering[i],
                        } as FilterOption);
                    }
                }

                this.fetchCrewContent();
            },

            contentAdded(data: any) {
                this.userWantsToAddContent = false;
                this.fetchAll();
            },

            async saveNewRecommendation(data: any) {
                if (!this.addRecommendation) {
                    logInvalidParams(this.$options.name, 'saveNewRecommendation');
                    return;
                }

                const updatedText = data.comment;
                const updatedRating = data.rating;

                // Comment can be empty, but not rating
                if (!this.addRecommendation || updatedRating === 0) {
                    logInvalidParams(this.$options.name, 'saveNewRecommendation');
                    return;
                }

                try {
                    const ret = await ApiUtils.apiWrapper(ContentService.addRecommendation, {
                        contentId: this.addRecommendation.contentId,
                        comment: updatedText,
                        rating: updatedRating,
                    });

                    if (!ret || !ret.recommendedBy || ret.recommendedBy.length !== 1) {
                        logInvalidResponse(this.$options.name, 'saveNewRecommendation');
                        return;
                    }

                    this.fetchCrewContent();

                    analytics.logAppInteraction(
                        analytics.ANALYTICS_ACTION_NEW_RECOMMENDATION,
                        this.addRecommendation.contentPublicUrl,
                    );

                    this.addRecommendation = undefined as ContentV2 | undefined;
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            //
            // The authenticated user is editing their own reki
            //
            async saveRecommendationUpdate(data: any) {
                const updatedText = data.comment;
                const updatedRating = data.rating;

                // Comment can be empty, but not rating
                if (!this.editRecommendation || updatedRating === 0) {
                    logInvalidParams(this.$options.name, 'saveRecommendationUpdate');
                    return;
                }

                try {
                    const ret = await ApiUtils.apiWrapper(ContentService.updateRecommendation, {
                        contentId: this.editRecommendation.contentId,
                        comment: updatedText,
                        rating: updatedRating,
                    });

                    if (!ret || !ret.recommendedBy || ret.recommendedBy.length !== 1) {
                        logInvalidResponse(this.$options.name, 'saveRecommendationUpdate');
                        return;
                    }

                    this.fetchCrewContent();

                    analytics.logAppInteraction(
                        analytics.ANALYTICS_ACTION_UPDATE_RECOMMENDATION,
                        this.editRecommendation.contentPublicUrl,
                    );

                    this.editRecommendation = undefined as ContentV2 | undefined;
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            //
            // When the lower level components do like or unlike, or reply, we eventually get
            // the 'recommendation-updated' emit, with the data RecommendationUpdateEmit.  That contains the
            // new recommendation, and, if defined, the new comments totals.
            //
            recommendationUpdated(idx: number, data: RecommendationUpdateEmit) {
                if (!this.content || idx < 0 || idx >= this.content.length || !data || !data.recommendation) {
                    logInvalidParams(this.$options.name, 'recommendationUpdated');
                    return;
                }

                if (this.content[idx].authedUserRecommendation) {
                    Vue.set(
                        this.content[idx].authedUserRecommendation as UserRecommendation,
                        'totalCommentLikes',
                        data.recommendation.totalCommentLikes,
                    );

                    Vue.set(
                        this.content[idx].authedUserRecommendation as UserRecommendation,
                        'authedUserLikedComment',
                        data.recommendation.authedUserLikedComment,
                    );
                } else {
                    if (this.content[idx].latestRecommendation) {
                        Vue.set(
                            this.content[idx].latestRecommendation as UserRecommendation,
                            'totalCommentLikes',
                            data.recommendation.totalCommentLikes,
                        );

                        Vue.set(
                            this.content[idx].latestRecommendation as UserRecommendation,
                            'authedUserLikedComment',
                            data.recommendation.authedUserLikedComment,
                        );
                    }
                }

                if (data.totalComments) {
                    Vue.set(this.content[idx], 'totalComments', data.totalComments);
                }

                if (data.totalFolloweeComments) {
                    Vue.set(this.content[idx], 'totalFolloweeComments', data.totalFolloweeComments);
                }
            },

            getDeleteRecommendationTitle(): string {
                if (!this.deleteRecommendation) {
                    logInvalidParams(this.$options.name, 'getDeleteRecommendationTitle');
                    return '';
                }

                return (
                    'Are you sure you want to delete your Reki: <br><br> <b>' +
                    this.deleteRecommendation.name +
                    '</b></br><br>'
                );
            },

            async okToDeleteRecommendation() {
                if (!this.deleteRecommendation) {
                    logInvalidParams(this.$options.name, 'okToDeleteRecommendation');
                    return;
                }

                try {
                    const deletedPublicUrl = this.deleteRecommendation.contentPublicUrl;

                    await ApiUtils.apiWrapper(
                        ContentService.deleteRecommendation,
                        this.deleteRecommendation.contentId as number,
                    );

                    this.fetchCrewContent();

                    analytics.logAppInteraction(analytics.ANALYTICS_ACTION_DELETE_RECOMMENDATION, deletedPublicUrl);
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            gotoDetails(item: ContentV2) {
                this.$router.push({
                    name: constants.ROUTE_CONTENT_DETAILS,
                    params: { contentPublicUrl: item.contentPublicUrl as string },
                });
            },

            gotoComments(item: ContentV2) {
                this.$router.push({
                    name: constants.ROUTE_CONTENT_COMMENTS,
                    params: { contentPublicUrl: item.contentPublicUrl as string },
                });
            },

            closeEditImage(newCrew: Crew | undefined) {
                if (newCrew && newCrew.crewId) {
                    this.crew = newCrew;
                }

                this.editImage = false;
            },
        },
    });
