
    import { logInvalidParams } from '@/tsfiles/errorlog';
    import Vue, { PropOptions } from 'vue';

    export default Vue.extend({
        name: 'Video',

        components: {},

        props: {
            // Source location for the video (url)
            srcUrl: {
                type: String,
                required: true,
            },

            // Is the source external
            external: {
                type: Boolean,
                required: true,
            } as PropOptions<boolean | undefined>,

            //
            // Some youtube links are invalid or point to private content.
            // We check for validity.  If invalid, this prop can be
            // used as the backup.  An example is "Love, Death & Robots".
            //
            invalidYouTubeBackupImage: {
                type: String,
                required: false,
                default: '',
            },
        },

        data() {
            return {
                keyVal: '1',
                youTubeValidationComplete: false,
                validYouTubeSrc: false,
                youTubeVideoId: '',
                youTubeEmbeddedSrc: '',
            };
        },

        watch: {
            //
            // Using the browser back button when there are iframes can cause
            // navigation issues.  The browser thinks the iframe is the same
            // when navigating to a new route when the component isn't recreated.
            // For example, content details pages only swap out the content.  The components
            // are the same and not recreated.  They way around this is to set the 'key'
            // value into the iframe to a different value everytime the src changes.
            //
            srcUrl: {
                immediate: true,
                handler(newVal: string, oldVal: string) {
                    if (newVal && newVal !== oldVal) {
                        this.keyVal = Math.random().toString(36).substring(2, 15);

                        //
                        // If it's a youtube link, check to see if we have access.  Some
                        // videos are private, or may not exist anymore.
                        //
                        if (this.isYoutubeVideo(newVal)) {
                            this.youTubeValidationComplete = false;
                            this.validateYouTubeSrc(newVal);
                        }
                    }
                },
            },
        },

        computed: {},

        methods: {
            isYoutubeVideo(url: string): boolean {
                return url.includes('youtu.be') || url.includes('youtube.com');
            },

            //
            // Validate the YouTube url using fetch.  This method catches errors
            // such as linking to private content.  The default timeout for fetch
            // is 300 seconds, so we need to solve that with an AbortController.
            // A timeout code example was found at:
            //   https://codesource.io/learn-how-to-timeout-a-fetch-request/
            //
            async validateYouTubeSrc(url: string) {
                this.parseYouTubeSrc(url); // Stores Id and embedded string in this component

                const abortController = new AbortController();
                const id = setTimeout(() => abortController.abort(), 2000);

                const urlToTest =
                    'https://www.youtube.com/oembed?url=http://www.youtube.com/watch?v=' +
                    this.youTubeVideoId +
                    '&format=json';

                let ret;
                try {
                    ret = await fetch(urlToTest, { signal: abortController.signal });
                } catch (error) {
                    return false;
                }

                clearTimeout(id);
                this.validYouTubeSrc = ret && ret.status === 200;
                this.youTubeValidationComplete = true;
            },

            //
            // Youtube external URL has to use the embedded value for use in an
            // iframe.  The user may copy in the link directly or use the embedded code.
            // We need to tidy up...
            //
            parseYouTubeSrc(url: string) {
                if (!url.includes('youtu.be') && !url.includes('youtube.com')) {
                    logInvalidParams(this.$options.name, 'parseYouTubeSrc');
                }

                if (url.includes('youtu.be')) {
                    //
                    // The user did a direct link copy.  We need to pull out the video Id.
                    //
                    const regex = new RegExp('.*youtu.be/(.*)$');
                    const results = regex.exec(this.srcUrl);

                    if (!results || results.length !== 2) {
                        return '';
                    }

                    this.youTubeVideoId = results[1];
                    this.youTubeEmbeddedSrc = 'https://www.youtube.com/embed/' + results[1] + '?enablejsapi=1';
                } else if (url.includes('iframe')) {
                    //
                    // The user copied in the embedded code, we only need the video Id
                    //
                    const regex = new RegExp('.*youtube.com/embed/([^"]+).*');
                    const results = regex.exec(url);

                    if (!results || results.length !== 2) {
                        return '';
                    }

                    this.youTubeVideoId = results[1];
                    this.youTubeEmbeddedSrc = 'https://www.youtube.com/embed/' + results[1] + '?enablejsapi=1';
                } else if (url.includes('watch')) {
                    //
                    // From google search or on youtube, the URL could be something like:
                    //     https://www.youtube.com/watch?v=jVWvky3bv8U
                    // There might be other params, but we ignore those.  If the 'v'
                    // moves around, parse the URL.
                    //

                    const parsedUrl = new URL(url);
                    const params = new URLSearchParams(parsedUrl.search.slice(1));
                    if (params.get('v') === '') {
                        return '';
                    }

                    this.youTubeVideoId = params.get('v') as string;
                    this.youTubeEmbeddedSrc = 'https://www.youtube.com/embed/' + params.get('v') + '?enablejsapi=1';
                }
            },
        },
    });
