/*
 * Copyright 2019-2022 Reki LLC - All rights reserved.
 * File: store.ts
 * Project: rekitv
 */
import Vue from 'vue';
import Vuex from 'vuex';
import { Utils } from '@/tsfiles/utils';
import * as constants from '@/tsfiles/constants';
import { SigninStatus } from '@/tsfiles/enums';
import { SharedConstants, AdminRole } from 'api';
import {
    Session, PageMessage, NotificationCommentReply,
    PageNavigationData, AdChannel,
} from '@/tsfiles/interfaces';

Vue.use(Vuex);

const DEFAULT_REGION_CODE = 'US';


export default new Vuex.Store({
    state: {
        touchScreen: false,
        segmentAnalytics: undefined as any | undefined,
        appVersion: '',
        device: {
            type: '',
            token: '',
            info: '',
            regionCode: DEFAULT_REGION_CODE,
        },
        numericKeyboardHack: false,
        postBeep: undefined as any | undefined,

        firebaseSigninState: SigninStatus.UNKNOWN as SigninStatus,
        serverSigninState: SigninStatus.UNKNOWN as SigninStatus,

        session: {
            userId: 0,
            displayName: '',
            email: '',
            emailVerified: false,
            phone: '',
            phoneVerified: false,
            avatarUrl: '',
            cachedAvatarUrl: '',
            publicUrl: '',
            masterPublicUrl: '',
            qrcodeUrl: '',
            roles: [] as AdminRole[],
            countryCode: '',
            streamingCountryCode: '',
            blueCheckmark: false,
        } as Session,

        unauthenticatedRedirect: '',

        settingsDirty: false,
        currentMenu: constants.ROUTE_EXPLORE,

        //
        // Instead of keeping 'tab', what page of content, filtering, etc. data as query
        // parameters, for making the browser back button work, use the store.
        // Some views and top-level components will use this information to
        // restore the page the user was on (when user is hitting browser
        // navigation buttons).  The various types of pages that need
        // this information are initialized in initializeStore() below.
        //
        pageNavigation: new Map(),

        //
        // Someone wants to show a message on the page, usually the one
        // we are routing to.  router.push/replace with a 'path' doesn't
        // allow params to be set.  If you want to go to the same page
        // but with one param change, you can't do that either (you'll get
        // a NavigationDuplicated error).  For easily setting a notification message
        // to display, use this store value.
        //
        // The id is specifically set here, so Vue's reactivity rules will apply;
        // otherwise we need to Vue.set during setPageMessage.  The user might
        // have multiple in-app notifications that go to the same page.  We want
        // to make sure the message on the page changes.
        //
        pageMessages: [] as PageMessage[],

        //
        // AdChannel data, which goes to analytics and is part of a branch link.
        //
        adChannel: {} as AdChannel,
    },
    mutations: {
        //
        // initializeStore is called by main.ts, when our app is
        // recreated. It's used to initialize pageNavigation and sync items with localStorage.
        //
        // tslint:disable:no-empty
        initializeStore(state) {
            state.pageNavigation.set(constants.ROUTE_EXPLORE, [] as PageNavigationData[]);
            state.pageNavigation.set(constants.ROUTE_USER_PROFILE, [] as PageNavigationData[]);
            state.pageNavigation.set(constants.ROUTE_USER_HOME, [] as PageNavigationData[]);
            state.pageNavigation.set(constants.ROUTE_CONTENT_COMMENTS, [] as PageNavigationData[]);
            state.pageNavigation.set(constants.ROUTE_CONTENT_CHAT, [] as PageNavigationData[]);
            state.pageNavigation.set(constants.ROUTE_SEARCH, [] as PageNavigationData[]);

            // Get current AdChannel data from localStorage
            const str = localStorage.getItem(constants.LOCAL_STORAGE_KEY_AD_CHANNEL);
            if (str) {
                state.adChannel = JSON.parse(str);
            }
        },

        setFirebaseSigninState(state, val: SigninStatus) {
            state.firebaseSigninState = val;
        },

        setServerSigninState(state, val: SigninStatus) {
            state.serverSigninState = val;
        },

        clearSession(state) {
            state.session.userId = 0;
            state.session.displayName = '';
            state.session.email = '';
            state.session.emailVerified = false;
            state.session.phone = '';
            state.session.phoneVerified = false;
            state.session.avatarUrl = '';
            state.session.cachedAvatarUrl = '';
            state.session.publicUrl = '';
            state.session.masterPublicUrl = '';
            state.session.qrcodeUrl = '';
            state.session.roles = [] as AdminRole[];
            state.session.countryCode = '';
            state.session.streamingCountryCode = '';
            state.session.blueCheckmark = false;
        },

        //
        // setSession should only be called upon first signin, or for
        // updates to profile, etc.  DO NOT use setSession with an
        // empty givenSession to reset the session back to empty, just
        // use userSignedOut.  If this gets called when the user is not
        // signed in, resetting the unauthenticatedRedirect back to empty
        // means you would lose the referral tracking as the unauthenticated
        // user is traversing tabs.
        //
        setSession(state, givenSession: Session) {
            state.session.userId = givenSession.userId || 0;
            state.session.displayName = givenSession.displayName || '';
            state.session.email = givenSession.email || '';
            state.session.emailVerified = givenSession.emailVerified;
            state.session.phone = givenSession.phone || '';
            state.session.phoneVerified = givenSession.phoneVerified;
            state.session.avatarUrl = givenSession.avatarUrl || '';
            state.session.cachedAvatarUrl = givenSession.cachedAvatarUrl || '';
            state.session.publicUrl = givenSession.publicUrl || '';
            state.session.masterPublicUrl = givenSession.masterPublicUrl || '';
            state.session.qrcodeUrl = givenSession.qrcodeUrl || '';
            state.session.blueCheckmark = givenSession.blueCheckmark;

            if (givenSession.roles) {  // Can be undefined (coming from grpc)
                state.session.roles = [...givenSession.roles];
            } else {
                state.session.roles = [] as AdminRole[];
            }

            state.session.countryCode = givenSession.countryCode || '';
            state.session.streamingCountryCode = givenSession.streamingCountryCode || '';


            state.unauthenticatedRedirect = '';  // Reset to empty, now that we are signed in
        },

        //
        // Set the user's admin roles, which can be empty.  These roles are
        // user specific and NOT related to company roles.
        //
        setSessionRoles(state, givenRoles: AdminRole[]) {
            if (givenRoles) {  // Can be undefined (coming from grpc)
                state.session.roles = [...givenRoles];
            } else {
                state.session.roles = [] as AdminRole[];
            }

        },

        setSessionDataWithUpdatedProfile(state, data) {
            if (data.userId) {
                state.session.userId = data.userId;
            }
            if (data.displayName) {
                state.session.displayName = data.displayName;
            }
            if (data.email) {
                state.session.email = data.email;
                state.session.emailVerified = data.emailVerified;
            }
            if (data.phone) {
                state.session.phone = data.phone;
                state.session.phoneVerified = data.phoneVerified;
            }
            if (data.avatarUrl) {
                state.session.avatarUrl = data.avatarUrl;
            }
            if (data.cachedAvatarUrl) {
                state.session.cachedAvatarUrl = data.cachedAvatarUrl;
            }
            if (data.publicUrl) {
                state.session.publicUrl = data.publicUrl;
            }
            if (data.countryCode) {
                state.session.countryCode = data.countryCode;
            }
            if (data.streamingCountryCode) {
                state.session.streamingCountryCode = data.streamingCountryCode;
            }
        },

        setCurrentMenu(state, newVal: string) {
            state.currentMenu = newVal;
        },

        setSettingsDirty(state, newVal: boolean) {
            state.settingsDirty = newVal;
        },

        //
        // Fake Notification Id of 0 can be used for a single, client-created
        // Page Message.  For example, if the user's private page notices the user
        // has no verified phone or email, put in a message that has no notification
        // db coorelation.
        //
        setPageMessage(state, givenMessage: PageMessage) {
            let variant = 'danger';
            if (givenMessage.variant && givenMessage.variant !== '') {
                variant = givenMessage.variant as string;
            }

            let targetPages = [] as string[];
            if (givenMessage.targetPages && givenMessage.targetPages.length > 0) {
                targetPages = [...givenMessage.targetPages];
            }

            //
            // If existing id match is found, remove and readd to end.
            //
            let idx = 0;
            for (const msg of state.pageMessages) {
                if (msg.notification.id === givenMessage.notification.id) {
                    state.pageMessages.splice(idx, 1);
                    break;
                }

                idx++;
            }

            state.pageMessages.push({
                notification: Utils.deepCopy(givenMessage.notification),
                targetPages: [...targetPages],
                secondsToDisplay: givenMessage.secondsToDisplay,
                variant,
            } as PageMessage);
        },

        clearPageMessage(state, id: number) {
            // Find the pageMessage to delete, based on notification id
            for (let i = 0; i < state.pageMessages.length; i++) {
                if (state.pageMessages[i].notification.id === id) {
                    state.pageMessages.splice(i, 1);
                    return;
                }
            }
        },

        emailHasBeenVerified(state) {
            state.session.emailVerified = true;
        },
        phoneHasBeenVerified(state) {
            state.session.phoneVerified = true;
        },

        setSegmentAnalytics(state, val) {
            state.segmentAnalytics = val;
        },

        setUnauthenticatedRedirect(state, val) {
            state.unauthenticatedRedirect = val;
        },

        setPageNavigation(state, data) {
            if (data && data.page) {
                const currentNavigation = state.pageNavigation.get(data.page) as PageNavigationData[];
                currentNavigation.unshift(data);
            }
        },

        setAdChannel(state, data: AdChannel) {
            state.adChannel.advertisingId = data.advertisingId;
            state.adChannel.downloadSource = data.downloadSource;
            state.adChannel.currentSource = data.currentSource;

            // Put into localStorage
            localStorage.setItem(constants.LOCAL_STORAGE_KEY_AD_CHANNEL, JSON.stringify(data));
        },

        /////////////////////////////////////////////////////////////////////
        // Mobile
        /////////////////////////////////////////////////////////////////////
        setTouchScreen(state, val) {
            state.touchScreen = val;
        },

        setAppVersion(state, val) {
            state.appVersion = val;
        },

        setDevice(state, data) {
            state.device.type = data.type;
            state.device.token = data.token;
            state.device.info = data.info;

            // Use region code provided; otherwise default to DEFAULT_REGION_CODE
            if (data.regionCode && data.regionCode !== '') {
                state.device.regionCode = data.regionCode;
            } else {
                state.device.regionCode = DEFAULT_REGION_CODE;
            }
        },

        setPostBeep(state, val) {
            state.postBeep = val;
        },

        setNumericKeyboardHack(state, val) {
            state.numericKeyboardHack = val;
        },

        /////////////////////////////////////////////////////////////////////
        // Server admin tools
        /////////////////////////////////////////////////////////////////////

    },

    getters: {
        getFirebaseSigninState: (state) => {
            return state.firebaseSigninState;
        },
        getServerSigninState: (state) => {
            return state.serverSigninState;
        },

        isSignedIn: (state) => {
            return state.firebaseSigninState === SigninStatus.SIGNEDIN &&
                state.serverSigninState === SigninStatus.SIGNEDIN;
        },
        isSignedOut: (state) => {
            return state.firebaseSigninState === SigninStatus.SIGNEDOUT &&
                state.serverSigninState === SigninStatus.SIGNEDOUT;
        },
        isSigninStateKnown: (state) => {
            return state.firebaseSigninState !== SigninStatus.UNKNOWN &&
                state.serverSigninState !== SigninStatus.UNKNOWN;
        },
        isUsersPublicUrl: (state, getters) => (publicUrl: string) => {
            return (
                getters.isSignedIn && state.session.publicUrl === publicUrl
            );
        },
        getSessionUserId: (state) => {
            return state.session.userId;
        },
        getSessionEmail: (state) => {
            return state.session.email;
        },
        isEmailVerified: (state) => {
            return state.session.email !== undefined && state.session.emailVerified;
        },
        getSessionPhone: (state) => {
            return state.session.phone;
        },
        isPhoneVerified: (state) => {
            return state.session.phone !== undefined && state.session.phoneVerified;
        },
        getPublicUrl: (state) => {
            return state.session.publicUrl;
        },
        getAvatar: (state) => {
            return state.session.avatarUrl;
        },
        getCachedAvatar: (state) => {
            return state.session.cachedAvatarUrl;
        },
        getQRCode: (state) => {
            return state.session.qrcodeUrl;
        },
        getCountryCode: (state) => {
            return state.session.countryCode;
        },
        getStreamingCountryCode: (state) => {
            return state.session.streamingCountryCode;
        },
        getBlueCheckmark: (state) => {
            return state.session.blueCheckmark;
        },

        areSettingsDirty: (state) => {
            return state.settingsDirty;
        },

        getCurrentMenu: (state) => {
            return state.currentMenu;
        },

        getPageMessages: (state) => {
            return state.pageMessages;
        },

        getSegmentAnalytics: (state) => {
            return state.segmentAnalytics;
        },

        getUnauthenticatedRedirect: (state) => {
            return state.unauthenticatedRedirect;
        },

        getPageNavigation: (state) => (page: string): PageNavigationData | undefined => {
            const currentPageNavigationList = state.pageNavigation.get(page) as PageNavigationData[];
            if (currentPageNavigationList) {
                return currentPageNavigationList.shift();
            }

            return undefined;
        },

        getAdChannel: (state) => {
            return state.adChannel;
        },


        /////////////////////////////////////////////////////////////////////
        // User's roles, stored in session (not related to company roles)
        //
        // WARNING: In most cases you should never use these directly.  Please
        // see roleutils.ts for functions to use for access verification.  This
        // way we only have one place where it is decided what role has access to
        // what feature.
        /////////////////////////////////////////////////////////////////////
        isServerSuperAdmin: (_, getters) => {
            return getters.isSignedIn && getters.userHasRole(SharedConstants.USER_SERVER_ADMIN);
        },

        isStatsSuperAdmin: (_, getters) => {
            return getters.isSignedIn && getters.userHasRole(SharedConstants.USER_STATS_ADMIN);
        },

        isSupportSuperAdmin: (_, getters) => {
            return getters.isSignedIn && getters.userHasRole(SharedConstants.USER_SUPPORT_ADMIN);
        },

        userHasRole: (state) => {
            return (keyword: string) => {
                if (!state.session.roles || state.session.roles.length === 0) {
                    return false;
                }

                for (const role of state.session.roles) {
                    if (role.role === keyword) {
                        return true;
                    }
                }

                return false;
            };
        },

        /////////////////////////////////////////////////////////////////////
        // Mobile
        /////////////////////////////////////////////////////////////////////
        isTouchScreen: (state) => {
            return state.touchScreen;
        },
        getAppVersion: (state) => {
            return state.appVersion;
        },
        getDevice: (state) => {
            return state.device;
        },
        getDeviceType: (state) => {
            return state.device.type;
        },
        getDeviceRegionCode: (state) => {
            return state.device.regionCode;
        },
        deviceIsIos: (state) => {
            return state.device.type === 'ios';
        },
        deviceIsAndroid: (state) => {
            return state.device.type === 'android';
        },

        isMobileDevice: (_, getters) => {
            return getters.deviceIsIos || getters.deviceIsAndroid;
        },

        getPostBeep: (state) => {
            return state.postBeep;
        },

        needsNumericKeyboardHack: (state) => {
            return state.numericKeyboardHack;
        },

        //
        // NOTE: If there's an webUI emergency, change this 'true' and quickly redeploy if you
        // want the public part of the site, but don't allow any new users or signins.  Leave
        // in the cookie checking, so devs can investigate issues.
        //
        hideWebsiteSignin: (state) => {
            return true && document.cookie.match(new RegExp('DebugAllowSignin' + '=([^;]+)')) === null;
        },

        //
        // The app doesn't have Email signin yet, so only show if the debug cookie is there.
        //
        showWebsiteEmailSignin: (state) => {
            return document.cookie.match(new RegExp('DebugAllowEmailSignin' + '=([^;]+)')) !== null;
        },

        /////////////////////////////////////////////////////////////////////
        // Only used to make it easy to find out where MVP features were
        // turned on or off.
        /////////////////////////////////////////////////////////////////////
        postMVP: (state) => {
            return false;
        },
        forMVP: (state) => {
            return true;
        },
        forMVPRoles: (state) => {
            return state.session.roles;
        },

        /////////////////////////////////////////////////////////////////////
        // Server admin tools
        /////////////////////////////////////////////////////////////////////

    },
    actions: {
    },
});
