
    import Vue from 'vue';
    import { Utils } from '@/tsfiles/utils';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { logInvalidParams } from '@/tsfiles/errorlog';
    import Avatar from '@/components/Avatar.vue';
    import ListOfFollowing from '@/components/user/ListOfFollowing.vue';
    import ListOfFollowers from '@/components/user/ListOfFollowers.vue';
    import { UserService, User } from 'api';

    export default Vue.extend({
        name: 'Follow',

        components: {
            'url-avatar': Avatar,
            'list-of-following': ListOfFollowing,
            'list-of-followers': ListOfFollowers,
        },

        props: {
            tab: {
                type: Number,
                required: false,
                default: 0,
            },
        },

        data() {
            return {
                user: undefined as User | undefined,
                currentTab: this.tab,
            };
        },

        watch: {},

        mounted() {
            this.fetchUser();
        },

        computed: {},

        methods: {
            async fetchUser() {
                if (!this.$router.currentRoute.params.publicUrl) {
                    logInvalidParams(this.$options.name, 'fetchUser');
                    return;
                }

                try {
                    this.user = await ApiUtils.apiWrapper(UserService.getUser, {
                        publicUrl: this.$router.currentRoute.params.publicUrl,
                    });
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },
        },
    });
