
    import Vue, { PropOptions } from 'vue';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { Utils } from '@/tsfiles/utils';
    import { logInvalidParams } from '@/tsfiles/errorlog';
    import * as constants from '@/tsfiles/constants';
    import ListRow from '@/components/lists/ListRow.vue';
    import UserNameDisplay from '@/components/user/UserNameDisplay.vue';
    import { User, ListService, List } from 'api';

    export default Vue.extend({
        name: 'UserLists',

        components: {
            'list-row': ListRow,
            'user-name-display': UserNameDisplay,
        },

        props: {
            user: {
                type: Object,
                required: true,
                default: () => undefined as User | undefined,
            } as PropOptions<User>,
        },

        data() {
            return {
                lists: [] as List[],
                listFetchComplete: false,
            };
        },

        watch: {},

        mounted() {
            this.fetchLists();
        },

        computed: {},

        methods: {
            async fetchLists() {
                if (!this.user) {
                    logInvalidParams(this.$options.name, 'fetchLists');
                    return;
                }

                try {
                    this.lists = [] as List[];

                    const ret = await ApiUtils.apiWrapper(ListService.getUserLists, this.user.publicUrl);

                    if (ret.predefinedList) {
                        this.lists = Utils.deepCopy(ret.predefinedList);
                    }

                    if (ret.userList) {
                        this.lists = this.lists.concat(Utils.deepCopy(ret.userList));
                    }

                    this.$emit('total-lists', this.lists.length);
                    this.listFetchComplete = true;
                } catch (error: any) {
                    Utils.CommonErrorHandler(error);
                }
            },

            gotoList(list: List) {
                if (!list.owner || !list.listId) {
                    logInvalidParams(this.$options.name, 'gotoList');
                    return;
                }

                this.$router.push({
                    name: constants.ROUTE_LIST_CONTENTS,
                    params: { publicUrl: list.owner.publicUrl as string, listId: list.listId.toString() },
                });
            },
        },
    });
