/*
 * Copyright 2019-2022 Reki LLC - All rights reserved.
 * File: errorlog.ts
 * Project: rekitv
 */
import { AdminService } from 'api';
import * as constants from '@/tsfiles/constants';
import { ApiUtils } from '@/tsfiles/apiutils';


//
// Global logging of errors or debug messages.  They go to the console, then the server.  It doesn't
// matter if the server gets them or not.
//
// If you see a 403 coming back from nginx, it might be caused by you sending
// in objects instead of strings.  You can also send in 'extraParams', instead of
// concatenating strings together.  Because we don't require csrf for this call to the
// server, you should not see 403 related to this being called before the csrf is
// set inside the getIdentity call.  See also gateway.go and jslogapi.go
//
export async function log(errorStr: string, ...extraParams: any[]) {
    //
    // NOTE: Don't expand extraParams for console.log, since we want full
    // object display in the console.
    //

    //
    // Using console.trace will dump out the entire stack, which is helpful,
    // but clutters the console.  Use groupCollapsed() to help with this
    // problem.  Multiples of the same error don't see to show up though?
    //
    console.groupCollapsed(constants.COMPANY_NAME.replace(/\s+/g, '') + 'Error');
    console.trace(errorStr, extraParams);
    console.groupEnd();

    //
    // Convert extra params to strings for sending to server.  Pure objects won't work,
    // sending just the string [Object object].  Maybe enhance?
    //
    let fullMessage = errorStr;
    for (const msg of extraParams) {
        if (msg) {  // element in empty extraParams could be undefined
            fullMessage += ' ' + msg;
        }
    }

    try {
        await ApiUtils.apiWrapper(AdminService.feJsLog, { value: fullMessage });
    } catch (error) {
        console.error('error logging error to server: ', error);
        console.error(errorStr, extraParams);
    }
}


//
// Utility function to consistently write out invalid access to specific
// views or components.  The caller adds on the function name.  For example:
//    logInvalidAccess(this.$options.name, 'myFunction');
// If the view or component doesn't check, and a call hits the server, the
// server will respond with codes.PermissionDenied, which will force a user
// signout in the UI.
//
export function logAccessDenied(componentName: string | undefined, functionName: string, extraParams?: any) {
    log('access denied: ' + componentName + ' - ' + functionName, extraParams);
}


//
// Utility function to consistently write out invalid input parameters.  The
// caller adds on the function name.  For example:
//    logInvalidParams(this.$options.name, 'myFunction');
//
export function logInvalidParams(componentName: string | undefined, functionName: string, extraParams?: any) {
    log('invalid input parameters: ' + componentName + ' - ' + functionName, extraParams);
}



//
// Utility function to consistently write out invalid responses from server
// calls.  The caller adds on the grpc api name, and potentially the response.
//    logInvalidResponse(this.$options.name, 'adminAddCompanyByDomain: ', warningCode);
//
export function logInvalidResponse(componentName: string | undefined, functionName: string, extraParams?: any) {
    log('invalid server response: ' + componentName + ' - ' + functionName, extraParams);
}
