
    import Vue from 'vue';
    import { Utils } from '@/tsfiles/utils';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { TimeUtils } from '@/tsfiles/timeutils';
    import Avatar from '@/components/Avatar.vue';
    import UserNameDisplay from '@/components/user/UserNameDisplay.vue';
    import AddFriendsInput from '@/components/user/AddFriendsInput.vue';
    import { InviteService, GenericInvite } from 'api';

    export default Vue.extend({
        name: 'UserInvites',

        components: {
            'url-avatar': Avatar,
            'user-name-display': UserNameDisplay,
            'add-friends-input': AddFriendsInput,
        },

        props: {},

        data() {
            return {
                countAvailableRetrieved: false,
                invitesAvailable: 0,

                invites: [] as GenericInvite[],
            };
        },

        watch: {},

        mounted() {
            this.fetchAll();
        },

        computed: {},

        methods: {
            //
            // Create date string to display how long ago the creation date was
            //
            getDateStr(dateStr: Date | undefined): string {
                return TimeUtils.pastTimeDistanceStringWithoutHelperWords(dateStr);
            },

            fetchAll() {
                this.fetchInvitesAvailable();
                this.fetchOpenInvites();
            },

            async fetchInvitesAvailable() {
                try {
                    const ret = await ApiUtils.apiWrapper(InviteService.getNumberOfFriendInvitesAllowed);
                    if (ret && ret.num) {
                        this.invitesAvailable = ret.num;
                    } else {
                        this.invitesAvailable = 0;
                    }

                    this.countAvailableRetrieved = true;
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async fetchOpenInvites() {
                try {
                    const ret = await ApiUtils.apiWrapper(InviteService.getOpenFriendInvites);
                    if (ret && ret.list) {
                        this.invites = ret.list;
                    } else {
                        this.invites = [] as GenericInvite[];
                    }
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },
        },
    });
