
    import { Vue } from 'vue-property-decorator';
    import validator from 'validator';
    import * as constants from '@/tsfiles/constants';
    import { logAccessDenied, logInvalidParams } from '@/tsfiles/errorlog';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { Utils } from '@/tsfiles/utils';
    import { RoleUtils } from '@/tsfiles/roleutils';
    import { SharedConstants, InviteService } from 'api';

    export default Vue.extend({
        name: 'SuperAdminInviteModal',

        props: {},

        data() {
            return {
                emailAddress: '',
                submitting: false,
                selectedCheckboxes: [],

                options: [
                    { text: 'Server Admin', value: SharedConstants.USER_SERVER_ADMIN },
                    { text: 'Support Admin', value: SharedConstants.USER_SUPPORT_ADMIN },
                    { text: 'Stats Admin', value: SharedConstants.USER_STATS_ADMIN },
                ],
            };
        },

        mounted() {
            // Just double-check that the current user has access
            if (!RoleUtils.IsServerSuperAdmin()) {
                logAccessDenied(this.$options.name, 'mounted');
                this.$router.replace({ name: constants.ROUTE_MARKETING });
            }
        },

        computed: {
            //
            // Should the submit button be disabled?  It should if all required fields are
            // not valid.
            //
            disableSubmit(): boolean {
                return !this.emailValid || this.selectedCheckboxes.length === 0;
            },

            //
            // Email must be valid and match the domain
            //
            emailValid(): boolean {
                const email = this.emailAddress;

                if (!validator.isEmail(email)) {
                    return false;
                }

                return constants.COMPANY_DOMAIN === email.substring(email.lastIndexOf('@') + 1);
            },
        },

        methods: {
            //
            // If email is invalid, what is the reason displayed to the user.
            //
            invalidEmailReason(): string {
                const email = this.emailAddress;
                if (email === '') {
                    return '';
                }

                if (!validator.isEmail(email)) {
                    return 'Enter a valid email address';
                }

                const domain = email.substring(email.lastIndexOf('@') + 1);

                if (constants.COMPANY_DOMAIN !== domain) {
                    return 'Email must match company domain (' + constants.COMPANY_DOMAIN + ')';
                }

                return '';
            },

            //
            // Valid email for the COMPANY_DOMAIN and one or more checkboxes selected,
            // so it's ok to submit to the server.
            //
            async inviteSubmit() {
                if (this.disableSubmit) {
                    logInvalidParams(this.$options.name, 'inviteSubmit');
                    return;
                }

                try {
                    const ret = await ApiUtils.apiWrapper(InviteService.adminInviteSuperAdmin, {
                        email: this.emailAddress,
                        selectedRoles: this.selectedCheckboxes,
                    });

                    this.$emit('invite-sent');
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },
        },
    });
