// cSpell:disable
//
// Copyright 2019-2022 Reki LLC - All rights reserved.
// File: config-prod.ts
// Project: rekitv
//

//
// Production config overrides.  When prod it built, this file
// is copied into src/config-env.ts, via stage.Dockerfile.
//

// @ts-ignore
import config from '@/config';

// @ts-ignore
import * as constants from '@/tsfiles/constants';

export function initConfigs() {
    config.runEnvironment = 'prod';
    config.domainName = 'app.' + constants.COMPANY_DOMAIN;
    config.firebase.projectId = 'rekitv-prod';
    config.firebase.appId = '1:899592454464:web:add8286a5de5f76772baa5';
    config.firebase.apiKey = 'AIzaSyB-_dZm2aDMwun3C8yao8v0bf1Ou4atJFI';
    config.firebase.authDomain = 'auth.' + constants.COMPANY_DOMAIN;
    config.recaptchaSiteKey = '6LdN6vwUAAAAAHbcp2LwuHOaoEWkS6eT7ZpJoA9a';
    config.segmentAnalyticsWriteKey = 'r1tH9r8xDgftmRbHB5ujGZsqIiJFrzt4';
    config.branchioLink = 'https://mlink.reki.tv/gen';
}