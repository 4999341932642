
    import Vue from 'vue';
    import UserTimeline from '@/components/user/UserTimeline.vue';

    export default Vue.extend({
        name: 'Timeline',

        components: {
            'user-timeline': UserTimeline,
        },

        props: {},

        data() {
            return {};
        },

        watch: {},

        computed: {},

        methods: {},
    });
