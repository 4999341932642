//
// Copyright 2019-2022 Reki LLC - All rights reserved.
// File: profilerefresh.ts
// Project: rekitv
//
import store from '@/tsfiles/store';
import { ApiUtils } from '@/tsfiles/apiutils';
import { Utils } from '@/tsfiles/utils';
import { UserService } from 'api';

//
// Refresh the user's profile, putting results in store.  This
// is called by various views and components, which is why
// it was made as a comment ts file.
//
export async function profileRefresh() {
    try {
        const profile = await ApiUtils.apiWrapper(UserService.getUserAndProfile);

        store.commit('setSessionDataWithUpdatedProfile', {
            displayName: profile.name,
            email: profile.email,
            emailVerified: profile.emailVerified,
            phone: profile.phone,
            phoneVerified: profile.phoneVerified,
            avatarUrl: profile.imageUrl,
            publicUrl: profile.publicUrl,
        });
    } catch (error) {
        Utils.CommonErrorHandler(error);
    }
}
