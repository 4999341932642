
    import Vue, { PropOptions } from 'vue';
    import Avatar from '@/components/Avatar.vue';
    import FollowButton from '@/components/uiutils/FollowButton.vue';
    import { User } from 'api';

    export default Vue.extend({
        name: 'SingleFriend',

        components: {
            'url-avatar': Avatar,
            'follow-button': FollowButton,
        },

        props: {
            // Friend to display
            friend: {
                type: Object,
                required: true,
            } as PropOptions<User>,

            // If on a public page, followees should be readonly (no follow/unfollow)
            readonly: {
                type: Boolean,
                required: false,
                default: false,
            },
        },

        data() {
            return {
                rowHover: false,
            };
        },

        computed: {
            getNumRecommendations(): string {
                if (!this.friend.numRecommendations) {
                    return '0 Reki';
                }

                return this.friend.numRecommendations.toString() + ' Reki';
            },

            getNumFollowers(): string {
                if (!this.friend.numFollowers) {
                    return '0 Followers';
                }
                if (this.friend.numFollowers === 1) {
                    return '1 Follower';
                }

                return this.friend.numFollowers.toString() + ' Followers';
            },

            getImageUrl(): string {
                if (this.friend.cachedImageUrl && this.friend.cachedImageUrl !== '') {
                    return this.friend.cachedImageUrl;
                } else if (this.friend.imageUrl && this.friend.imageUrl !== '') {
                    return this.friend.imageUrl;
                }

                return '';
            },

            isAuthedUser(): boolean {
                // If not signed in , return false
                if (!this.$store.getters.isSignedIn) {
                    return false;
                }

                // Return true if friend publicUrl is same is authed user
                return this.$store.getters.isUsersPublicUrl(this.friend.publicUrl);
            },
        },

        methods: {},
    });
