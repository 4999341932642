
    import Vue, { PropOptions } from 'vue';
    import StarRating from '@/components/uiutils/StarRating.vue';
    import { ContentV2 } from 'api';
    import { logInvalidParams } from '@/tsfiles/errorlog';

    export default Vue.extend({
        name: 'ContentImage',

        components: {
            'star-rating': StarRating,
        },

        props: {
            content: {
                type: Object,
                required: true,
            } as PropOptions<ContentV2>,
        },

        data() {
            return {
                posterImage: '',
                backdropImage: '',
            };
        },

        mounted() {
            if (!this.content.contentId) {
                logInvalidParams(this.$options.name, 'mounted');
                return;
            }

            if (this.content.cachedPosterUrl) {
                this.posterImage = this.content.cachedPosterUrl;
            } else if (this.content.posterUrl) {
                this.posterImage = this.content.posterUrl;
            }

            if (this.content.cachedBackdropUrl) {
                this.backdropImage = this.content.cachedBackdropUrl;
            } else if (this.content.backdropUrl) {
                this.backdropImage = this.content.backdropUrl;
            }
        },

        computed: {},

        methods: {},
    });
