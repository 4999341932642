
    import Vue from 'vue';

    export default Vue.extend({
        name: 'CommentLike',

        components: {},

        props: {
            readonly: {
                type: Boolean,
                required: false,
                default: false,
            },

            markAsLiked: {
                type: Boolean,
                required: false,
                default: false,
            },

            likedCount: {
                type: Number,
                required: false,
                default: 0,
            },
        },

        data() {
            return {};
        },

        watch: {},

        computed: {},

        methods: {},
    });
