
    import mixins from 'vue-typed-mixins';
    import VueConstants from '@/components/VueConstants';
    export default mixins(VueConstants).extend({
        name: 'UserNameDisplay',

        components: {},

        props: {
            name: {
                type: String,
                required: false,
                default: '',
            },

            publicUrl: {
                type: String,
                required: true,
            },

            linkToHome: {
                type: Boolean,
                required: false,
                default: false,
            },
        },

        data() {
            return {};
        },

        watch: {},

        computed: {},

        methods: {},
    });
