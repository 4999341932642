import { render, staticRenderFns } from "./ContentDetailsPage.vue?vue&type=template&id=7fa70c32&scoped=true&"
import script from "./ContentDetailsPage.vue?vue&type=script&lang=ts&"
export * from "./ContentDetailsPage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fa70c32",
  null
  
)

export default component.exports