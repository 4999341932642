
    import Vue from 'vue';
    import mixins from 'vue-typed-mixins';
    import VueConstants from '@/components/VueConstants';

    export default mixins(VueConstants).extend({
        name: 'ContentYearRunlength',

        components: {},

        props: {
            year: {
                type: Number,
                required: false,
                default: 0,
            },

            runlength: {
                type: Number,
                required: false,
                default: 0,
            },

            format: {
                type: String,
                required: true,
            },
        },

        data() {
            return {};
        },

        watch: {},

        computed: {},

        methods: {},
    });
