
    import Vue, { PropOptions } from 'vue';
    import { logInvalidParams, logInvalidResponse } from '@/tsfiles/errorlog';
    import { RecommendationUpdateEmit } from '@/tsfiles/interfaces';
    import * as constants from '@/tsfiles/constants';
    import { TimeUtils } from '@/tsfiles/timeutils';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { Utils } from '@/tsfiles/utils';
    import * as analytics from '@/tsfiles/analytics';
    import Avatar from '@/components/Avatar.vue';
    import UserNameDisplay from '@/components/user/UserNameDisplay.vue';
    import RecommendationEdit from '@/components/content/RecommendationEditModal.vue';
    import CommentReplyComponent from '@/components/uiutils/CommentReply.vue';
    import CommentLike from '@/components/uiutils/CommentLike.vue';
    import ModalAlert from '@/generic-modals/ModalAlert.vue';
    import StarRating from '@/components/uiutils/StarRating.vue';
    import { UserService, ContentService, User, UserRecommendation, CommentRequest, CommentReply } from 'api';

    export default Vue.extend({
        name: 'CommentRow',

        components: {
            'url-avatar': Avatar,
            'star-rating': StarRating,
            'user-name-display': UserNameDisplay,
            'recommendation-edit': RecommendationEdit,
            'comment-reply': CommentReplyComponent,
            'comment-like': CommentLike,
            'modal-alert': ModalAlert,
        },

        props: {
            contentId: {
                type: Number,
                required: true,
            },

            contentName: {
                type: String,
                required: true,
            },

            contentPublicUrl: {
                type: String,
                required: false,
            },

            //
            // If no commentReply, this is a top-level recommendation display
            // If a commentReply is included, we are showing the commentReply, but
            // do need to know some info from the recommendation (e.g., id).
            //
            recommendation: {
                type: Object,
                required: true,
                default: () => undefined as UserRecommendation | undefined,
            } as PropOptions<UserRecommendation>,

            //
            // If supplied, commentReply is a reply to a recommendation comment or
            // to another reply.
            //
            commentReply: {
                type: Object,
                required: false,
                default: () => undefined as CommentReply | undefined,
            } as PropOptions<CommentReply>,
        },

        data() {
            return {
                user: undefined as User | undefined,
                comment: undefined as string | undefined,

                showEditRecommendation: false, // top-level recommendation comment editing
                showEditCommentReply: false, // Reply to a recommendation comment or reply
                updatedCommentReply: '' as string | undefined,
                showDeleteCommentReply: false,
            };
        },

        watch: {
            recommendation: {
                immediate: true,
                deep: true,
                handler(newVal: UserRecommendation, oldVal: UserRecommendation) {
                    this.resetLocals();
                },
            },

            commentReply: {
                immediate: true,
                deep: true,
                handler(newVal: UserRecommendation, oldVal: UserRecommendation) {
                    this.resetLocals();
                },
            },
        },

        computed: {
            getLikeCount(): number {
                //
                // Do not combine the ifs, since we want 0 if commentReply is defined
                // and there are no totalComments
                //
                if (this.commentReply) {
                    if (this.commentReply.totalCommentLikes) {
                        return this.commentReply.totalCommentLikes;
                    }
                } else if (this.recommendation && this.recommendation.totalCommentLikes) {
                    return this.recommendation.totalCommentLikes;
                }

                return 0;
            },

            getCommentReplyDeleteTitle(): string {
                return (
                    'Are you sure you want to delete your reply: <br><br> <b>' +
                    this.commentReply.comment +
                    '</b></br><br>'
                );
            },

            validComment(): boolean {
                return this.comment !== undefined && this.comment.trim() !== '';
            },

            updateCommentReplyValid(): boolean {
                return this.updatedCommentReply !== undefined && this.updatedCommentReply.trim() !== '';
            },
        },

        methods: {
            resetLocals() {
                if (this.commentReply && this.commentReply.user) {
                    this.user = this.commentReply.user;
                    this.comment = this.commentReply.comment;
                } else if (this.recommendation && this.recommendation.user) {
                    this.user = this.recommendation.user;
                    this.comment = this.recommendation.comment;
                }
            },

            //
            // Create date string to display how long ago the creation date was
            //
            getDateStr(dateStr: Date | undefined): string {
                return TimeUtils.pastTimeDistanceStringWithoutHelperWords(dateStr);
            },

            tellParentAboutChanges(
                type: string,
                recommendation: UserRecommendation,
                totalComments: number | undefined,
                totalFolloweeComments: number | undefined,
            ) {
                this.$emit('recommendation-updated', {
                    type,
                    recommendation,
                    totalComments,
                    totalFolloweeComments,
                } as RecommendationUpdateEmit);
            },

            //
            // The authenticated user is the most recent recommender, and they changed their
            // recommendation comment.
            //
            async saveRecommendationComment(data: any) {
                const updatedText = data.comment;
                const updatedRating = data.rating;

                if (!this.recommendation || updatedText === '') {
                    logInvalidParams(this.$options.name, 'saveRecommendationComment');
                    return;
                }

                try {
                    const ret = await ApiUtils.apiWrapper(ContentService.updateRecommendation, {
                        contentId: this.contentId,
                        comment: updatedText,
                        rating: updatedRating,
                    });

                    if (!ret || !ret.recommendedBy || ret.recommendedBy.length !== 1) {
                        logInvalidResponse(this.$options.name, 'saveRecommendationComment');
                        return;
                    }

                    this.tellParentAboutChanges(
                        constants.RECOMMENDATION_UPDATE_EMIT_TYPE_EDIT,
                        ret.recommendedBy[0],
                        undefined,
                        undefined,
                    );

                    analytics.logAppInteraction(
                        analytics.ANALYTICS_ACTION_UPDATE_RECOMMENDATION,
                        this.contentPublicUrl,
                    );
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async submitCommentReply(val: string) {
                if (!this.recommendation || !this.recommendation.recommendationId || val === '') {
                    logInvalidParams(this.$options.name, 'submitCommentReply');
                    return;
                }

                let commentReplyId = 0;
                if (this.commentReply && this.commentReply.commentReplyId) {
                    commentReplyId = this.commentReply.commentReplyId;
                }

                try {
                    const ret = await ApiUtils.apiWrapper(ContentService.replyToComment, {
                        contentId: this.contentId,
                        recommendationId: this.recommendation.recommendationId,
                        commentReplyId,
                        comment: val,
                    } as CommentRequest);

                    if (!ret || !ret.userRecommendation || !ret.userRecommendation.recommendationId) {
                        logInvalidResponse(this.$options.name, 'submitCommentReply');
                        return;
                    }

                    this.tellParentAboutChanges(
                        constants.RECOMMENDATION_UPDATE_EMIT_TYPE_REPLY,
                        ret.userRecommendation,
                        ret.totalComments,
                        ret.totalFolloweeComments,
                    );

                    analytics.logAppInteraction(analytics.ANALYTICS_ACTION_COMMENT_REPLY, this.contentPublicUrl);
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async submitCommentReplyUpdate() {
                if (!this.recommendation || !this.recommendation.recommendationId || this.updatedCommentReply === '') {
                    logInvalidParams(this.$options.name, 'submitCommentReplyUpdate');
                    return;
                }

                let commentReplyId = 0;
                if (this.commentReply && this.commentReply.commentReplyId) {
                    commentReplyId = this.commentReply.commentReplyId;
                }

                try {
                    const ret = await ApiUtils.apiWrapper(ContentService.updateCommentReply, {
                        contentId: this.contentId,
                        recommendationId: this.recommendation.recommendationId,
                        commentReplyId,
                        comment: this.updatedCommentReply,
                    } as CommentRequest);

                    if (!ret || !ret.userRecommendation || !ret.userRecommendation.recommendationId) {
                        logInvalidResponse(this.$options.name, 'submitCommentReplyUpdate');
                        return;
                    }

                    this.tellParentAboutChanges(
                        constants.RECOMMENDATION_UPDATE_EMIT_TYPE_REPLY,
                        ret.userRecommendation,
                        ret.totalComments,
                        ret.totalFolloweeComments,
                    );

                    analytics.logAppInteraction(analytics.ANALYTICS_ACTION_UPDATE_COMMENT_REPLY, this.contentPublicUrl);
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async likeComment() {
                if (!this.recommendation || !this.recommendation.recommendationId) {
                    logInvalidParams(this.$options.name, 'likeComment');
                    return;
                }

                let commentReplyId = 0;
                if (this.commentReply && this.commentReply.commentReplyId) {
                    commentReplyId = this.commentReply.commentReplyId;
                }

                try {
                    const ret = await ApiUtils.apiWrapper(ContentService.likeComment, {
                        contentId: this.contentId,
                        recommendationId: this.recommendation.recommendationId,
                        commentReplyId,
                    } as CommentRequest);

                    if (!ret || !ret.recommendationId) {
                        logInvalidResponse(this.$options.name, 'likeComment');
                        return;
                    }

                    this.tellParentAboutChanges(
                        constants.RECOMMENDATION_UPDATE_EMIT_TYPE_REACTION,
                        ret,
                        undefined,
                        undefined,
                    );

                    analytics.logAppInteraction(analytics.ANALYTICS_ACTION_COMMENT_LIKE, this.contentPublicUrl);
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async unlikeComment() {
                if (!this.recommendation || !this.recommendation.recommendationId) {
                    logInvalidParams(this.$options.name, 'unlikeComment');
                    return;
                }

                let commentReplyId = 0;
                if (this.commentReply && this.commentReply.commentReplyId) {
                    commentReplyId = this.commentReply.commentReplyId;
                }

                try {
                    const ret = await ApiUtils.apiWrapper(ContentService.unlikeComment, {
                        contentId: this.contentId,
                        recommendationId: this.recommendation.recommendationId,
                        commentReplyId,
                    } as CommentRequest);

                    if (!ret || !ret.recommendationId) {
                        logInvalidResponse(this.$options.name, 'unlikeComment');
                        return;
                    }

                    this.tellParentAboutChanges(
                        constants.RECOMMENDATION_UPDATE_EMIT_TYPE_REACTION,
                        ret,
                        undefined,
                        undefined,
                    );

                    analytics.logAppInteraction(analytics.ANALYTICS_ACTION_COMMENT_UNLIKE, this.contentPublicUrl);
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            gotoPerson() {
                if (!this.user) {
                    logInvalidParams(this.$options.name, 'gotoPerson');
                    return;
                }

                this.$router.push({
                    name: constants.ROUTE_USER_HOME,
                    params: { publicUrl: this.user.publicUrl as string },
                });
            },

            async reportAbuse() {
                try {
                    let commentReplyId = 0;
                    if (this.commentReply && this.commentReply.commentReplyId && this.commentReply.commentReplyId > 0) {
                        commentReplyId = this.commentReply.commentReplyId;
                    }

                    await ApiUtils.apiWrapper(UserService.reportAbuse, {
                        publicUrl: this.user?.publicUrl,
                        recommendationId: this.recommendation.recommendationId,
                        commentReplyId,
                    });

                    alert('This comment has been reported as potential abuse');
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },
        },
    });
