/*
 * Copyright 2019-2022 Reki LLC - All rights reserved.
 * File: mainroutes.ts
 * Project: rekitv
 */
import * as constants from '@/tsfiles/constants';
import MarketingHome from '@/views/MarketingHome.vue';
import SigninHome from '@/views/SigninHome.vue';
import EmailVerification from '@/views/EmailVerification.vue';
import Unsubscribe from '@/views/user/Unsubscribe.vue';
import SigninComplete from '@/views/SigninComplete.vue';
import AdminInvite from '@/views/AdminInvite.vue';
import ProcessFriendInvite from '@/views/user/ProcessFriendInvite.vue';
import GotoContentDetails from '@/views/GotoContentDetails.vue';
import GotoCommentReply from '@/views/GotoCommentReply.vue';
import AuthedUserPageRedirect from '@/views/user/AuthedUserPageRedirect.vue';
import Error from '@/views/Error.vue';
import ServerStats from '@/views/ServerStats.vue';
import ServerAdmin from '@/views/ServerAdmin.vue';

import Explore from '@/views/Explore.vue';
import Search from '@/views/Search.vue';
import Timeline from '@/views/user/Timeline.vue';
import Lists from '@/views/user/Lists.vue';
import NotificationsPage from '@/views/user/NotificationsPage.vue';
import ListContents from '@/views/user/ListContents.vue';
import PublicProfile from '@/views/user/PublicProfile.vue';
import PrivateProfile from '@/views/user/PrivateProfile.vue';
import Settings from '@/views/user/Settings.vue';
import Follow from '@/views/user/Follow.vue';
import BlockedUsers from '@/views/user/BlockedUsers.vue';
import UserInvites from '@/views/user/UserInvites.vue';
import ContentDetailsPage from '@/views/content/ContentDetailsPage.vue';
import ContentComments from '@/views/content/ContentComments.vue';
import CrewContent from '@/views/content/CrewContent.vue';
import ContentChat from '@/views/content/ContentChat.vue';
import QrcodeApp from '@/views/QrcodeApp.vue';
import SquarespaceRedirect from '@/views/user/SquarespaceRedirect.vue';


import DeepLinkTest from '@/views/DeepLinkTest.vue';

export default [
    {
        path: '/',
        name: constants.ROUTE_MARKETING,
        component: MarketingHome,
    },

    {
        path: '/firebasesignin',
        name: constants.ROUTE_FIREBASE_SIGNIN,
        props: true,
        component: SigninHome,
    },

    {
        path: '/signincomplete',
        name: 'signin_complete',
        component: SigninComplete,
        meta: { requiresAuth: true },
    },

    {
        path: '/emailverification',
        name: 'email_verification',
        component: EmailVerification,
        meta: { requiresAuth: true },
    },

    {
        path: '/admininvite',
        name: 'admin_invite',
        component: AdminInvite,
    },

    {
        path: '/friendinvite',
        name: 'friend_invite',
        component: ProcessFriendInvite,
        props: true,
    },

    {
        path: '/gotocontentdetails',
        name: 'goto_content_details',
        component: GotoContentDetails,
        meta: { requiresAuth: true },
    },

    {
        path: '/gotocommentreply',
        name: 'goto_comment_reply',
        component: GotoCommentReply,
        meta: { requiresAuth: true },
    },

    {
        path: '/error',
        name: 'error',
        component: Error,
        props: true,
    },

    {
        path: '/unsubscribe',
        name: 'unsubscribe',
        component: Unsubscribe,
    },

    {
        path: '/serverstats',
        name: 'server_stats',
        component: ServerStats,
        meta: { requiresAuth: true },
    },

    {
        path: '/serveradmin',
        name: 'server_admin',
        component: ServerAdmin,
        meta: { requiresAuth: true },
    },

    //
    // Redirect to authed user page.  This is used when signed out, and
    // the anonymous user clicks on a menu choice that requires signin.  When
    // signin is complete, the user should go to the tab they were clicking on.
    // 'aupr' stands for authed-user-page.
    //
    {
        path: '/aupr',
        name: 'authed_user_page_redirect',
        props: true,
        component: AuthedUserPageRedirect,
    },

    {
        path: '/explore',
        name: constants.ROUTE_EXPLORE,
        props: true,
        component: Explore,
    },

    {
        path: '/search',
        name: constants.ROUTE_SEARCH,
        props: true,
        component: Search,
    },

    //
    // spr stands for 'SquareSpaceRedirect'.  We use squarespace to
    // simplify URLs for ambassadors.  For example, reki.tv/marlee should
    // redirect to m.reki.tv/spr?uid=99.
    // We don't use the username (e.g., @marlee), since the user can change
    // their username.  We hard-code the user's id (unique id from user table).
    //
    {
        path: '/spr',
        name: 'squarespace_redirect',
        props: true,
        component: SquarespaceRedirect,
    },

    //
    // aqr stands for 'App Qrcode'.  It's meant as the desktop
    // webpage showing a qrcode that the user can process with their phone.
    // The qrcode url will be a branch link.
    //
    {
        path: '/aqr',
        name: 'qrcode_app',
        props: true,
        component: QrcodeApp,
    },

    {
        path: '/@:publicUrl([a-zA-Z0-9\\_\\-]+)',
        name: constants.ROUTE_USER_HOME,
        props: true,
        component: PublicProfile,
    },

    {
        path: '/@:publicUrl([a-zA-Z0-9\\_\\-]+)/timeline',
        name: constants.ROUTE_USER_TIMELINE,
        props: true,
        component: Timeline,
        meta: { requiresAuth: true },
    },

    {
        path: '/@:publicUrl([a-zA-Z0-9\\_\\-]+)/lists',
        name: constants.ROUTE_USER_LISTS,
        props: true,
        component: Lists,
        meta: { requiresAuth: true },
    },

    {
        path: '/@:publicUrl([a-zA-Z0-9\\_\\-]+)/listcontents/:listId',
        name: constants.ROUTE_LIST_CONTENTS,
        props: true,
        component: ListContents,
    },

    {
        path: '/@:publicUrl([a-zA-Z0-9\\_\\-]+)/profile',
        name: constants.ROUTE_USER_PROFILE,
        props: true,
        component: PrivateProfile,
        meta: { requiresAuth: true },
    },

    {
        path: '/@:publicUrl([a-zA-Z0-9\\_\\-]+)/profile/settings',
        name: constants.ROUTE_USER_SETTINGS,
        props: true,
        component: Settings,
        meta: { requiresAuth: true },
    },

    {
        path: '/@:publicUrl([a-zA-Z0-9\\_\\-]+)/profile/follow',
        name: constants.ROUTE_USER_FOLLOWING,
        props: true,
        component: Follow,
    },

    {
        path: '/@:publicUrl([a-zA-Z0-9\\_\\-]+)/notifications',
        name: constants.ROUTE_USER_NOTIFICATIONS,
        props: true,
        component: NotificationsPage,
        meta: { requiresAuth: true },
    },

    {
        path: '/@:publicUrl([a-zA-Z0-9\\_\\-]+)/blocked',
        name: constants.ROUTE_USER_BLOCKED,
        props: true,
        component: BlockedUsers,
        meta: { requiresAuth: true },
    },

    {
        path: '/@:publicUrl([a-zA-Z0-9\\_\\-]+)/invites',
        name: constants.ROUTE_USER_INVITES,
        props: true,
        component: UserInvites,
        meta: { requiresAuth: true },
    },

    {
        path: '/:contentPublicUrl([a-zA-Z0-9\\-\\$\\*\\!\\(\\)\']+)',
        name: constants.ROUTE_CONTENT_DETAILS,
        props: true,
        component: ContentDetailsPage,
    },

    {
        path: '/:contentPublicUrl([a-zA-Z0-9\\-\\$\\*\\!\\(\\)\']+)/comments',
        name: constants.ROUTE_CONTENT_COMMENTS,
        props: true,
        component: ContentComments,
    },

    {
        path: '/crew/:crewId([0-9]+)',
        name: constants.ROUTE_CREW_CONTENT,
        props: true,
        component: CrewContent,
    },

    {
        path: '/:contentPublicUrl([a-zA-Z0-9\\-\\$\\*\\!\\(\\)\']+)/chat',
        name: constants.ROUTE_CONTENT_CHAT,
        props: true,
        component: ContentChat,
    },
];
