
    import mixins from 'vue-typed-mixins';
    import * as constants from '@/tsfiles/constants';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { Utils } from '@/tsfiles/utils';
    import VueConstants from '@/components/VueConstants';
    import { SharedConstants, UserService } from 'api';

    export default mixins(VueConstants).extend({
        name: 'Unsubscribe',

        components: {},

        props: {},

        data() {
            return {
                alertMessage: '',
            };
        },

        watch: {},

        mounted() {
            this.completeUnsubscribe();
        },

        computed: {},

        methods: {
            //
            // The user clicked on the email unsubscribe link from an email.
            // The code is unique, and does not require authentication.
            //
            async completeUnsubscribe() {
                const code = String(this.$router.currentRoute.query.code);

                // If missing data, just go home
                if (!this.$router.currentRoute.query.code) {
                    this.$router.replace({ name: constants.ROUTE_MARKETING });
                    return;
                }

                try {
                    const ret = await ApiUtils.apiWrapper(UserService.completeUnsubscribe, code);
                    if (ret && ret.warningCode) {
                        switch (ret.warningCode) {
                            case SharedConstants.WARNING_RESOURCE_NOT_FOUND:
                                this.alertMessage = 'Invalid unsubscribe code';
                                break;
                        }
                    }
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },
        },
    });
