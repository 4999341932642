//
// Copyright 2019-2022 Reki - All rights reserved.
// File: adchannel.ts
// Project: rekitv
//

import store from './store';


//
//
//
export function getAdChannelQueryParams(first: boolean) {
    const adChannel = store.getters.getAdChannel;
    let params = '';

    if (adChannel.downloadSource && adChannel.downloadSource !== '') {
        if (first) {
            params += '?ds=';
        } else {
            params += '&ds=';
        }

        params += adChannel.downloadSource;
    }

    if (adChannel.currentSource && adChannel.currentSource !== '') {
        if (first && params === '') {
            params = '?cs=' + adChannel.currentSource;
        } else {
            params += '&cs=' + adChannel.currentSource;
        }

        // Don't include aid unless currentSource is provided
        if (adChannel.advertisingId && adChannel.advertisingId !== '') {
            if (first && params === '') {
                params = '?aid=' + adChannel.advertisingId;
            } else {
                params += '&aid=' + adChannel.advertisingId;
            }
        }
    }

    return params;
}
