
    import Vue, { PropOptions } from 'vue';
    import mixins from 'vue-typed-mixins';
    import * as constants from '@/tsfiles/constants';
    import VueConstants from '@/components/VueConstants';
    import { PageMessage } from '@/tsfiles/interfaces';
    import Avatar from '@/components/Avatar.vue';
    import { SharedConstants, Notification, NotificationList } from 'api';

    export default mixins(VueConstants).extend({
        name: 'notifications',

        components: {
            'url-avatar': Avatar,
        },

        props: {
            // Notifications list
            notificationList: {
                type: Object,
                required: false,
            } as PropOptions<NotificationList>,

            // Should we do the slide in animation
            slideInAnimation: {
                type: Boolean,
                required: false,
                default: false,
            },
        },

        data() {
            return {
                notifications: [] as Notification[],
                moreToShow: false,
                fromEmail: false,
                firstSlideInComplete: false,
                onNotificationsPage: false,
            };
        },

        watch: {
            //
            // Save this.onNotificationsPage when route is changing.  We don't want the
            // see-all when on the notification page.
            //
            '$route.name': {
                immediate: true,
                handler(newVal, oldVal) {
                    this.onNotificationsPage = newVal === constants.ROUTE_USER_NOTIFICATIONS;
                },
            },

            // tslint:disable-next-line
            notificationList: {
                immediate: true,
                deep: true,
                handler(newVal: NotificationList, oldVal: NotificationList) {
                    if (newVal && newVal !== oldVal) {
                        if (newVal.list && newVal.list.length > 0) {
                            this.notifications = newVal.list;

                            if (newVal.totalItemsIrregardlessOfPaging) {
                                this.moreToShow = newVal.list.length < newVal.totalItemsIrregardlessOfPaging;
                            } else {
                                this.moreToShow = false;
                            }
                        } else {
                            this.notifications = [] as Notification[];
                            this.moreToShow = false;
                        }
                    }
                },
            },

            // tslint:disable-next-line
            slideInAnimation: {
                immediate: true,
                handler(newVal, oldVal) {
                    if (newVal) {
                        // With the new aggregated notifications, don't do slide in if the first item is an aggregated
                        // notification and the count is > 1.  We also check firstSlideInComplete, since we want
                        // don't want a slide-in every time the aggregation count changes inside the notification.
                        //
                        if (
                            this.firstSlideInComplete &&
                            this.notifications &&
                            this.notifications[0] &&
                            this.notifications[0].count &&
                            this.notifications[0].type === SharedConstants.NOTIFICATION_TYPE_AGGREGATION &&
                            this.notifications[0].count > 1
                        ) {
                            // Tell parent animation is complete, even though we skipped
                            this.$emit('done-with-animation');
                            return;
                        }

                        this.firstSlideInComplete = false;

                        // Do in nextTick so the div is ready
                        this.$nextTick(() => {
                            this.doSlideIn();
                        });
                    }
                },
            },
        },

        computed: {},

        methods: {
            doSlideIn() {
                const div = this.$refs.slider as HTMLDivElement;
                if (div) {
                    div.classList.add('show-animation-in');
                    setTimeout(() => {
                        div.classList.remove('show-animation-in');
                        this.$emit('done-with-animation');
                        this.firstSlideInComplete = true;
                    }, 2200);
                } else {
                    this.$emit('done-with-animation');
                }
            },

            //
            // Called when the notification slider is activated and slides in from
            // the left.  We want a short string to display.
            //
            getShortNotificationString(msg: Notification): string {
                switch (msg.event) {
                    case SharedConstants.NOTIFICATION_VERIFY_EMAIL:
                        return 'Verify email';
                    case SharedConstants.NOTIFICATION_VERIFY_PHONE:
                        return 'Verify phone';
                    case SharedConstants.NOTIFICATION_EMAIL_FLAGGED:
                        return 'Invalid email';
                    case SharedConstants.NOTIFICATION_PHONE_FLAGGED:
                        return 'Invalid phone';
                    case SharedConstants.NOTIFICATION_FRIEND_INVITE:
                        return 'Friend invite';
                    case SharedConstants.NOTIFICATION_FRIEND_RECOMMENDATION:
                        return 'New Reki';
                    case SharedConstants.NOTIFICATION_RECOMMENDATION_COMMENT:
                        return 'New Comment';
                    case SharedConstants.NOTIFICATION_COMMENT_REPLY:
                        return 'New Comment';
                    case SharedConstants.NOTIFICATION_NEW_FOLLOWER:
                        return 'New Follower';
                    case SharedConstants.NOTIFICATION_ADD_TO_LIST:
                        return 'Show added to list';
                    case SharedConstants.NOTIFICATION_RECOMMENDATION_LIKE:
                        return 'New Reki like';
                    case SharedConstants.NOTIFICATION_COMMENT_LIKE:
                        return 'New comment like';
                    case SharedConstants.NOTIFICATION_NEW_POST:
                        return 'New chat post';
                }

                return this.getNotificationString(msg);
            },

            //
            // Called when the user clicks the notification icon to show
            // the popup list of notifications.  This string can be slightly
            // larger than the short one that slides in.
            //
            getNotificationString(msg: Notification): string {
                // Get the json data from the message
                let data = undefined as any | undefined;
                if (msg.jsonData) {
                    data = JSON.parse(msg.jsonData);
                }

                switch (msg.event) {
                    case SharedConstants.NOTIFICATION_VERIFY_EMAIL:
                        return 'Email not verified';
                    case SharedConstants.NOTIFICATION_VERIFY_PHONE:
                        return 'Phone not verified';
                    case SharedConstants.NOTIFICATION_EMAIL_FLAGGED:
                        return 'Invalid email detected';
                    case SharedConstants.NOTIFICATION_PHONE_FLAGGED:
                        return 'Invalid phone detected';
                    case SharedConstants.NOTIFICATION_FRIEND_INVITE:
                        if (msg.creator && msg.creator.name) {
                            return 'Accept invite from ' + msg.creator.name;
                        }

                        return 'Friend invite';
                    case SharedConstants.NOTIFICATION_FRIEND_RECOMMENDATION:
                        return 'New Reki: ' + data.contentName;
                    case SharedConstants.NOTIFICATION_RECOMMENDATION_COMMENT:
                    case SharedConstants.NOTIFICATION_COMMENT_REPLY:
                        return 'New Comment: ' + data.contentName;
                    case SharedConstants.NOTIFICATION_NEW_FOLLOWER:
                        if (data.followerName) {
                            return 'New Follower: ' + data.followerName;
                        }

                        return 'New Follower: @' + data.followerPublicUrl;
                    case SharedConstants.NOTIFICATION_ADD_TO_LIST:
                        if (data.ownerName) {
                            return data.ownerName + ' added a show to ' + data.listName;
                        }

                        return '@' + data.ownerPublicUrl + ' added a show to ' + data.listName;
                    case SharedConstants.NOTIFICATION_RECOMMENDATION_LIKE:
                        return 'New Reki like: ' + data.contentName;
                    case SharedConstants.NOTIFICATION_COMMENT_LIKE:
                        return 'New comment like: ' + data.contentName;
                    case SharedConstants.NOTIFICATION_NEW_POST:
                        return 'New chat post: ' + data.contentName;
                }

                return '';
            },

            getNotificationAvatarUrl(msg: Notification): string {
                if (msg.isMe) {
                    return this.$store.state.session.avatarUrl;
                } else if (msg.creator && msg.creator.imageUrl) {
                    return msg.creator.imageUrl;
                }

                return '/favicon-32x32.png';
            },

            //
            // When a user clicks on a notification message, we go to different places
            // depending on what the notification is. The MainApp gets the
            // closeSingleNotification and will handle deleting it.
            //
            notificationClick(msg: Notification) {
                //
                // For now, we only handle one click, then the popover is closed.
                //
                const popover = this.$refs.notificationPopover as any;
                if (popover) {
                    popover.$emit('close');
                }

                // Get the json data from the message
                let data = undefined as any | undefined;
                if (msg.jsonData) {
                    data = JSON.parse(msg.jsonData);
                }

                switch (msg.event) {
                    case SharedConstants.NOTIFICATION_VERIFY_EMAIL:
                    case SharedConstants.NOTIFICATION_EMAIL_FLAGGED:
                        //
                        // Save page message in case user leaves settings page, which we
                        // will send them to if they are not already there.
                        //
                        this.$store.commit('setPageMessage', {
                            notification: msg,
                            targetPages: [constants.ROUTE_USER_HOME],
                        } as PageMessage);

                        if (this.$router.currentRoute.name !== constants.ROUTE_USER_SETTINGS) {
                            this.$router.push({
                                name: constants.ROUTE_USER_SETTINGS,
                                params: { publicUrl: this.$store.state.session.publicUrl },
                            });
                        }
                        break;
                    case SharedConstants.NOTIFICATION_VERIFY_PHONE:
                    case SharedConstants.NOTIFICATION_PHONE_FLAGGED:
                        this.$store.commit('setPageMessage', {
                            notification: msg,
                        } as PageMessage);

                        if (this.$router.currentRoute.name !== constants.ROUTE_USER_SETTINGS) {
                            this.$router.push({
                                name: constants.ROUTE_USER_SETTINGS,
                                params: { publicUrl: this.$store.state.session.publicUrl },
                            });
                        }
                        break;
                    case SharedConstants.NOTIFICATION_FRIEND_INVITE:
                        this.$router.push({
                            name: 'friend_invite',
                            params: { code: data.code, fromEmail: 'false' },
                        });
                        break;
                    case SharedConstants.NOTIFICATION_FRIEND_RECOMMENDATION:
                        // Go to friend recommendations, highlighting given content id
                        this.$store.commit('setCurrentMenu', constants.ROUTE_USER_TIMELINE);

                        //
                        // The user may not be on their own private page.  If not, go there.
                        //
                        if (
                            this.$router.currentRoute.name !== constants.ROUTE_USER_HOME ||
                            this.$router.currentRoute.params.publicUrl !== this.$store.state.session.publicUrl
                        ) {
                            this.$router.push({
                                name: constants.ROUTE_USER_HOME,
                                params: { publicUrl: this.$store.state.session.publicUrl },
                            });
                        }
                        break;

                    case SharedConstants.NOTIFICATION_RECOMMENDATION_COMMENT:
                    case SharedConstants.NOTIFICATION_COMMENT_REPLY:
                    case SharedConstants.NOTIFICATION_RECOMMENDATION_LIKE:
                    case SharedConstants.NOTIFICATION_COMMENT_LIKE:
                        if (data.contentId && data.recommendationId) {
                            this.$store.commit('setCurrentMenu', constants.ROUTE_USER_TIMELINE);
                            if (this.$router.currentRoute.name !== constants.ROUTE_CONTENT_COMMENTS) {
                                this.$router.push({
                                    name: constants.ROUTE_CONTENT_COMMENTS,
                                    params: { publicUrl: this.$store.state.session.publicUrl },
                                });
                            }
                        }
                        break;
                    case SharedConstants.NOTIFICATION_NEW_FOLLOWER:
                        this.$router.push({
                            name: constants.ROUTE_USER_HOME,
                            params: { publicUrl: data.followerPublicUrl },
                        });
                        break;
                    case SharedConstants.NOTIFICATION_ADD_TO_LIST:
                        // If no contentPublicUrl, use contentId.
                        let publicUrl = data.contentPublicUrl;
                        if (!publicUrl) {
                            publicUrl = data.contentId.toString();
                        }

                        this.$router.push({
                            name: constants.ROUTE_CONTENT_DETAILS,
                            params: { contentId: data.contentId.toString(), contentPublicUrl: publicUrl as string },
                        });
                        break;
                }

                this.$emit('clear-single-notification', msg);
            },

            clearNotifications() {
                this.$emit('clear-notifications', null);
            },

            seeAllNotifications() {
                // Close popup
                const popover = this.$refs.notificationPopover as any;
                if (popover) {
                    popover.$emit('close');
                }

                if (this.$router.currentRoute.name !== constants.ROUTE_USER_NOTIFICATIONS) {
                    this.$router.push({
                        name: constants.ROUTE_USER_NOTIFICATIONS,
                        params: { publicUrl: this.$store.getters.getPublicUrl },
                    });
                }
            },
        },
    });
