
    import Vue from 'vue';
    import { logInvalidParams } from '@/tsfiles/errorlog';
    import { Utils } from '@/tsfiles/utils';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import * as constants from '@/tsfiles/constants';
    import ListRow from '@/components/lists/ListRow.vue';
    import { ListService, List } from 'api';

    export default Vue.extend({
        name: 'Lists',

        components: {
            'list-row': ListRow,
        },

        props: {},

        data() {
            return {
                lists: [] as List[],
                following: [] as List[],

                showAddList: false,
                addListValue: '',
                submitting: false,
                goodNameFromServer: true,
            };
        },

        watch: {},

        mounted() {
            this.fetchLists();
        },

        computed: {
            isValidName(): boolean {
                return this.goodNameFromServer && this.addListValue !== '' && this.addListValue.trim() !== '';
            },

            invalidNameText(): string {
                if (this.addListValue === '' || this.addListValue.trim() === '') {
                    return 'Name required';
                }

                return 'Name already taken';
            },
        },

        methods: {
            async fetchLists() {
                try {
                    const ret = await ApiUtils.apiWrapper(ListService.getUserLists, this.$store.getters.getPublicUrl);

                    if (ret.predefinedList) {
                        this.lists = Utils.deepCopy(ret.predefinedList);
                    }

                    if (ret.userList) {
                        this.lists = this.lists.concat(Utils.deepCopy(ret.userList));
                    }

                    if (ret.followingList) {
                        this.following = Utils.deepCopy(ret.followingList);
                    }
                } catch (error: any) {
                    Utils.CommonErrorHandler(error);
                }
            },

            //
            // Ask the server if this list name is available for this user.
            //
            async nameChanged(newVal: string) {
                if (newVal.trim() === '') {
                    return;
                }

                try {
                    const ret = await ApiUtils.apiWrapper(ListService.isListNameAvailable, {
                        value: this.addListValue.trim(),
                    });

                    this.goodNameFromServer = ret && ret.value !== undefined;
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async handleSave() {
                if (this.submitting) {
                    return;
                }

                this.submitting = true;

                try {
                    const ret = await ApiUtils.apiWrapper(ListService.addList, {
                        name: this.addListValue.trim(),
                    } as List);

                    if (ret.predefinedList) {
                        this.lists = Utils.deepCopy(ret.predefinedList);
                    }

                    if (ret.userList) {
                        this.lists = this.lists.concat(Utils.deepCopy(ret.userList));
                    }

                    if (ret.followingList) {
                        this.following = Utils.deepCopy(ret.followingList);
                    }

                    this.showAddList = false;
                    this.addListValue = '';
                    this.submitting = false;
                } catch (error: any) {
                    //
                    // The UI should never send in an existing name, but look just in case
                    //
                    if (error.response.status === 409) {
                        this.showAddList = false;
                        this.addListValue = '';
                        this.submitting = false;
                    } else {
                        Utils.CommonErrorHandler(error);
                    }
                }
            },

            gotoList(list: List) {
                if (!list.owner || !list.listId) {
                    logInvalidParams(this.$options.name, 'gotoList');
                    return;
                }

                this.$router.push({
                    name: constants.ROUTE_LIST_CONTENTS,
                    params: { publicUrl: list.owner.publicUrl as string, listId: list.listId.toString() },
                });
            },
        },
    });
