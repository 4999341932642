/*
 * Copyright 2019-2022 Reki LLC - All rights reserved.
 * File: pubsub.ts
 * Project: rekitv
 */

import { log } from '@/tsfiles/errorlog';

//
// Should match server structure
//
interface PubSubEvent {
    event: string;
    jsonData: string;
}

class PubSubClient {
    private eventSource?: EventSource;
    private onNewPubSubMessage?: (message: PubSubEvent) => void;

    constructor(onNewPubSubMessage: (message: PubSubEvent) => void) {
        if (!typeof (EventSource)) {
            log('Browser does not support EventSource');
        } else {
            this.onNewPubSubMessage = onNewPubSubMessage;
            this.eventSource = new EventSource('/sub_upstream');

            const client = this;
            this.eventSource.onmessage = (e: MessageEvent) => {
                if (client.onNewPubSubMessage) {
                    client.onNewPubSubMessage(JSON.parse(e.data));
                }
            };
            this.eventSource.onerror = this.onError;
        }
    }

    public stop() {
        if (this.eventSource) {
            this.eventSource.close();
            this.eventSource = undefined;
        }
    }

    //
    // Browser continually tries to reconnect.  If the server is dead, do we care, or
    // should we close on error then reconnect on an increasing time interval?  For now,
    // just ignore the error.
    //
    private onError(e: Event) {
        // log('EventSource connection error: ', e);
    }
}


export { PubSubClient, PubSubEvent };
