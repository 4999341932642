//
// Copyright 2019-2022 Reki LLC - All rights reserved.
// File: enums.ts
// Project: rekitv
//

export enum SigninStatus {
    UNKNOWN,
    SIGNEDIN,
    SIGNEDOUT,
}
