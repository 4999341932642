
    import Vue from 'vue';
    import * as constants from '@/tsfiles/constants';
    import { logInvalidParams } from '@/tsfiles/errorlog';

    export default Vue.extend({
        name: 'SigninComplete',

        data() {
            return {
                alertMessage: '',
            };
        },

        mounted() {
            switch (String(this.$router.currentRoute.query.type)) {
                default:
                    logInvalidParams(this.$options.name, 'mounted', this.$router.currentRoute.query.type);
                    this.$router.replace({
                        name: constants.ROUTE_USER_HOME,
                        params: { publicUrl: this.$store.getters.getPublicUrl },
                    });
                    break;
            }
        },

        computed: {
            headerText(): string {
                return 'Verification Service';
            },
        },

        methods: {
            goHome() {
                this.$router.replace({
                    name: constants.ROUTE_USER_HOME,
                    params: { publicUrl: this.$store.getters.getPublicUrl },
                });
            },
        },
    });
