
    import Vue from 'vue';
    import { logInvalidParams, logInvalidResponse } from '@/tsfiles/errorlog';
    import * as constants from '@/tsfiles/constants';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { Utils } from '@/tsfiles/utils';
    import { SharedConstants, UserService } from 'api';
    import apiutils from '@/tsfiles/apiutils';
    import { profileRefresh } from '@/tsfiles/profilerefresh';

    export default Vue.extend({
        name: 'EmailVerification',

        data() {
            return {
                alertMessage: '',
            };
        },

        mounted() {
            this.verifyWithServer();
        },

        computed: {},

        methods: {
            async verifyWithServer() {
                const code = String(this.$router.currentRoute.query.code);
                const redirect = String(this.$router.currentRoute.query.redirect);

                // If missing data, just go home
                if (!this.$router.currentRoute.query.code) {
                    logInvalidParams(this.$options.name, 'verifyWithServer');
                    this.$router.replace({ name: constants.ROUTE_MARKETING });
                    return;
                }

                try {
                    const ret = await ApiUtils.apiWrapper(UserService.confirmChannelVerification, code);

                    // Ret will contain warning; otherwise it worked ok.
                    if (ret && ret.value) {
                        switch (ret.value) {
                            case SharedConstants.WARNING_RESOURCE_EXPIRED:
                                this.alertMessage = 'Your verification link has already expired.';
                                break;
                            case SharedConstants.WARNING_RESOURCE_NOT_FOUND:
                                this.alertMessage = 'Invalid verification link';
                                break;
                            case SharedConstants.WARNING_ALREADY_VERIFIED:
                                // If there's a redirect, go there; otherwise go to ROUTE_USER_HOME
                                if (redirect && redirect !== '') {
                                    this.$router.replace({ path: '/' + redirect });
                                } else {
                                    this.$router.replace({
                                        name: constants.ROUTE_USER_HOME,
                                        params: { publicUrl: this.$store.getters.getPublicUrl },
                                    });
                                }
                                break;
                            default:
                                logInvalidResponse(this.$options.name, 'verifyWithServer: ', ret.value);
                                this.$router.replace({ name: constants.ROUTE_MARKETING });
                                break;
                        }
                    } else {
                        //
                        // The verification worked.  The server will send a profile refresh, which refetches
                        // the profile and notifications.  If something else needs to be updated, MainApp's
                        // notification processing can do some extra checking/processing.
                        //
                        // NOTE: If the user clicks on the email verification link and it opens a new tab,
                        // the new tab, as well as the original tab, should get the pub/sub message, but
                        // there might be a timing issue.  Only once did I see the notification bell
                        // stay active with the verify-email notification, even though it was deleted from
                        // the DB and a pub/sub message was sent to the user (both tabs should have been
                        // notified).  This is why a profileRefresh() is here.
                        //
                        profileRefresh();

                        // If there's a redirect, go there; otherwise go to ROUTE_USER_HOME
                        if (redirect && redirect !== '') {
                            this.$router.replace({ path: '/' + redirect });
                        } else {
                            this.$router.replace({
                                name: constants.ROUTE_USER_HOME,
                                params: { publicUrl: this.$store.getters.getPublicUrl },
                            });
                        }
                    }
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },
        },
    });
