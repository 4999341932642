//
// Copyright 2019-2022 Reki LLC - All rights reserved.
// File: analytics.ts
// Project: rekitv
//
// Originally firebase analytics was used, but they we switched to Segment,
// which forwards messages to various services, including Google Analytics.
//
import router from '@/tsfiles/router';
import store from './store';
import Analytics from 'analytics';
import segmentPlugin from '@analytics/segment';
import config from '@/config';
import { logInvalidParams } from './errorlog';
import { SharedConstants } from 'api';


//
// WARNING: As of the initial implementation, the server's global analytics logging
// setting is true for dev, stage and prod.  If a server admin decides
// to turn off the setting via the serveradmin tools, the server's global setting
// will be changed and all authenticated users will be notified via nchan.  This is not
// efficient.  Unauthenticated users will not be notified.  The setting will get fetched
// during the next full page refresh via getIdentity.  Rarely, if ever, will this
// be required.  It's meant to shut things off immediately.  Another option is to
// restart the server with REKITV_OPTIONAL_GLOBALANALYTICSON set to false.
// Existing users that don't refresh will not notice the changes though.  Maybe a way
// to do an in-app message to all clients, not just signed in ones via nchan???
//

//
// NOTE: The Segment logging includes the window.document.title, which is a user's
// publicUrl when on a user public or private page.  The CommonApp.vue code will
// unset the title in the beforeDestroy().  There are times when the Segment title
// value is wrong because the setting of the title happens after we log even though
// it's in CommonApp's beforeDestroy.  The afterEach in router.js can actually
// happen before the beforeDestroy in CommonApp.  If you try to do something like
// Vue.nextTick(), that one problem will be fix, but another created.  With nextTick
// the first route to a user's home page will not have the title set in time.
//

//
// TEST: By default for dev each created user has allowAnalytics set to false
// in their profile.  It's set to true for Stage and Prod.  getIdentity will
// look at the mapped analyticsOn to determine if analytics should be shut off.
// It's always on for non-authed users, or set to the server default (GlobalAnalyticsOn).
// You can set devConsoleLogMessages to log if Segment if on or off.  Sometimes
// it's easier just to look at the console versus segment debugger.
//
// NOTE: Segment does not work in Brave, since the js files will be blocked.
//
const devConsoleLogMessages = false;


//
// Initialize Segment (segment.com) analytics.  This should only be called once, via
// initFirebase().  By default all analytics are disabled.  The server
// will tell us to enable/disable during getIdentity and/or pubsub messaging.
//
// Segment integration uses the segment plugin @analytics/segment:
//     https://www.npmjs.com/package/@analytics/segment#additional-examples
//
export async function initAnalytics() {
    if (!config.segmentAnalyticsWriteKey || config.segmentAnalyticsWriteKey === '') {
        logInvalidParams('initAnalytics', 'No Segment Write Key available');
        return;
    }

    const analytics = Analytics({
        app: 'Reki',
        plugins: [
            segmentPlugin({
                writeKey: config.segmentAnalyticsWriteKey,
            }),
        ],
    });

    store.commit('setSegmentAnalytics', analytics);
}

//
// Turn on analytics for this user.  The default for each user starts as off, set
// in initAnalytics.
//
export function turnOnUserAnalytics() {
    if (!store.getters.getSegmentAnalytics) {
        initAnalytics();
    }
}

//
// Turn off analytics for this user.
//
export function turnOffUserAnalytics() {
    store.commit('setSegmentAnalytics', undefined);
}

//
// Used for signin.  At the moment there's no reason to send any PII to Segment.
//
export function logSignin(userId: number, publicUrl: string) {
    if (store.getters.getSegmentAnalytics) {
        if (userId !== 0 && publicUrl !== '') {
            store.getters.getSegmentAnalytics.identify(userId.toString(), {
                username: '@' + publicUrl,
            });
        }
    }

    if (devConsoleLogMessages) {
        console.log('Analytics: IDENTIFY - ', userId, publicUrl);
    }
}

//
// Used for signout.  Just reset Segment, which will remove the userId
// and a newly created anonymous Segment id will be used (Segment stores
// this information in cookies).
//
export function logSignout() {
    if (store.getters.getSegmentAnalytics) {
        store.getters.getSegmentAnalytics.reset();
    }

    if (devConsoleLogMessages) {
        console.log('Analytics: Signout RESET');
    }
}


//
// Log the top-level route change (e.g., userhome). Segment will automatically
// add in the Path, so we don't need to worry about it here.
//
export function logAppView(routeName: string) {
    const paramsToPass = {} as any;

    if (store.getters.getSegmentAnalytics) {
        //
        // Put adChannel info into paramsToPass
        //
        const adChannel = store.getters.getAdChannel;
        if (adChannel.downloadSource && adChannel.downloadSource !== '') {
            paramsToPass[SharedConstants.AD_CHANNEL_DOWNLOAD_SOURCE] = adChannel.downloadSource;
        }
        if (adChannel.currentSource && adChannel.currentSource !== '') {
            paramsToPass[SharedConstants.AD_CHANNEL_CURRENT_SOURCE] = adChannel.currentSource;
        }
        if (adChannel.advertisingId && adChannel.advertisingId !== '') {
            paramsToPass[SharedConstants.AD_CHANNEL_AD_ID] = adChannel.advertisingId;
        }

        store.getters.getSegmentAnalytics.page({
            name: routeName,
            properties: paramsToPass,
        });
    }

    if (devConsoleLogMessages) {
        console.log('Analytics: PAGE - ', routeName, paramsToPass);
    }
}


//
// Log generic app interaction, such as the user clicking on tabs, external
// links, etc.  These should not be router 'view' changes, which come
// from router.afterEach().  If the user interaction takes the user to a
// top-level view inside mainroute.ts, do not call this function.
//
// The action would be something like 'select_tab', with
// the value being a string, such as 'members'.  The action is NOT prefixed with
// any text, as with logAppView(). We want any predefined analytics events
// to go through as is.
//
// ExtraParams allows the caller to specify a list of custom params
// if 'value' doesn't provide enough context.
//
export function logAppInteraction(action: string, givenValue?: string, extraParams?: any) {
    // Add in our default parameters.
    const paramsToPass = {
        action_src_view_name: router.currentRoute.name as string,
        action_src_view_path: router.currentRoute.path,
    } as any;

    if (givenValue) {
        paramsToPass.action_value = givenValue;
    }

    //
    // If extra parameters are supplied, add them in dynamically.
    //
    if (extraParams) {
        for (const prop in extraParams) {
            if (extraParams.hasOwnProperty(prop)) {
                paramsToPass[prop] = extraParams[prop];
            }
        }
    }

    //
    // Put adChannel info into paramsToPass
    //
    const adChannel = store.getters.getAdChannel;
    if (adChannel.downloadSource && adChannel.downloadSource !== '') {
        paramsToPass[SharedConstants.AD_CHANNEL_DOWNLOAD_SOURCE] = adChannel.downloadSource;
    }
    if (adChannel.currentSource && adChannel.currentSource !== '') {
        paramsToPass[SharedConstants.AD_CHANNEL_CURRENT_SOURCE] = adChannel.currentSource;
    }
    if (adChannel.advertisingId && adChannel.advertisingId !== '') {
        paramsToPass[SharedConstants.AD_CHANNEL_AD_ID] = adChannel.advertisingId;
    }

    if (store.getters.getSegmentAnalytics && router.currentRoute) {
        store.getters.getSegmentAnalytics.track({ event: action, properties: paramsToPass });
    }


    if (devConsoleLogMessages) {
        console.log('Analytics: TRACK - ', action, paramsToPass);
    }
}

//
// List of all action names we use for Segment TRACK
//
export const ANALYTICS_ACTION_SIGNUP = 'signup';
export const ANALYTICS_ACTION_SIGNOUT = 'signout';
export const ANALYTICS_ACTION_APP_DOWNLOAD = 'app_download';
export const ANALYTICS_ACTION_NEW_RECOMMENDATION = 'new_recommendation';
export const ANALYTICS_ACTION_UPDATE_RECOMMENDATION = 'update_recommendation';
export const ANALYTICS_ACTION_HIDE_RECOMMENDATION = 'hide_recommendation';
export const ANALYTICS_ACTION_UNHIDE_RECOMMENDATION = 'unhide_recommendation';
export const ANALYTICS_ACTION_DELETE_RECOMMENDATION = 'delete_recommendation';
export const ANALYTICS_ACTION_ADD_TO_QUEUE = 'add_to_queue';
export const ANALYTICS_ACTION_DELETE_FROM_QUEUE = 'delete_from_queue';
export const ANALYTICS_ACTION_MARK_AS_CONSUMED = 'mark_as_consumed';
export const ANALYTICS_ACTION_MARK_AS_NOT_CONSUMED = 'mark_as_not_consumed';
export const ANALYTICS_ACTION_COMMENT_REPLY = 'comment_reply';
export const ANALYTICS_ACTION_UPDATE_COMMENT_REPLY = 'update_comment_reply';
export const ANALYTICS_ACTION_DELETE_COMMENT_REPLY = 'delete_comment_reply';
export const ANALYTICS_ACTION_COMMENT_LIKE = 'comment_like';
export const ANALYTICS_ACTION_COMMENT_UNLIKE = 'comment_unlike';
export const ANALYTICS_ACTION_FOLLOW_FRIEND = 'follow_friend';
export const ANALYTICS_ACTION_UNFOLLOW_FRIEND = 'unfollow_friend';
export const ANALYTICS_ACTION_INVITE_FRIEND = 'invite_friend';
export const ANALYTICS_ACTION_INVITE_ACCEPTED = 'invite_accepted';
export const ANALYTICS_ACTION_UPDATE_PROFILE = 'update_profile';
export const ANALYTICS_ACTION_DELETE_USER = 'delete_user';
export const ANALYTICS_ACTION_SEARCH = 'search';
export const ANALYTICS_ACTION_EXTERNAL_CONTENT_CLICK = 'external_content_click';
export const ANALYTICS_ACTION_SELECT_TAB = 'select_tab';
