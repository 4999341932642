
    import mixins from 'vue-typed-mixins';
    import { NotificationCommentReply } from '@/tsfiles/interfaces';
    import VueConstants from '@/components/VueConstants';
    import * as constants from '@/tsfiles/constants';

    export default mixins(VueConstants).extend({
        name: 'GotoCommentReply',

        components: {},

        props: {},

        data() {
            return {};
        },

        mounted() {
            const query = this.$router.currentRoute.query;
            if (!query.id) {
                this.$router.replace({ name: constants.ROUTE_MARKETING });
                return;
            }

            // const contentId = query.id as string;
            // const recommendationId = query.rid as string;
            // const commentReplyId = query.crid as string;
            // const underFolloweeRecommendation = (query.uf && query.uf === 'true') as boolean;

            this.$store.commit('setCurrentMenu', constants.ROUTE_USER_TIMELINE);

            if (this.$router.currentRoute.name !== constants.ROUTE_USER_HOME) {
                this.$router.push({
                    name: constants.ROUTE_CONTENT_COMMENTS,
                    params: { publicUrl: this.$store.state.session.publicUrl },
                });
            }
        },

        computed: {},

        methods: {},
    });
