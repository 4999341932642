
    import Vue from 'vue';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { Utils } from '@/tsfiles/utils';
    import { ListService, Lists, List, ContentListRequest, SharedConstants } from 'api';

    export default Vue.extend({
        name: 'ListAddRemoveContentModal',

        components: {},

        props: {
            name: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                newName: this.name,
                goodNameFromServer: true,
            };
        },

        watch: {},

        computed: {
            isValidName(): boolean {
                return this.goodNameFromServer && this.newName !== '' && this.newName.trim() !== '';
            },

            invalidNameText(): string {
                if (this.newName === '' || this.newName.trim() === '') {
                    return 'Name required';
                }

                return 'Name already taken';
            },

            disableSave(): boolean {
                return !this.isValidName || this.newName === this.name;
            },
        },

        methods: {
            //
            // Ask the server if this list name is available for this user.
            //
            async nameChanged(newVal: string) {
                if (newVal.trim() === '') {
                    return;
                }

                try {
                    const ret = await ApiUtils.apiWrapper(ListService.isListNameAvailable, {
                        value: this.newName.trim(),
                    });

                    this.goodNameFromServer = ret && ret.value !== undefined;
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async submit() {
                try {
                    this.$emit('save-list', this.newName.trim());
                } catch (error: any) {
                    Utils.CommonErrorHandler(error);
                }
            },
        },
    });
