//
// Copyright 2019-2022 Reki - All rights reserved.
// File: apiutils.ts
// Project: rekitv
//

export class ApiUtils {
    //
    // apiWrapper calls the given server api.  If the DebugApiTiming cookie is
    // set, we log the time the api call took.  This function can replace any
    // await server-side api call, such as:
    //
    //     await UserService.getUserAndProfile();
    //
    // This wrapper is helpful when trying to determine if an api is taking
    // a long time or not.  The apiWrapper does not handle exceptions with
    // try/catch.  That's up to the caller, since the caller might have specific
    // tasks to perform inside the catch.
    //
    //
    public static async apiWrapper(myFunc: any, data?: any): Promise<any> {
        if (document.cookie.match(new RegExp('DebugApiTiming' + '=([^;]+)')) === null) {
            return await myFunc(data);
        }

        let funcPath = 'unknown';

        //
        // The dev version and Stage/Prod have different information in the function
        // string, since Stage/Prod are built and have obfuscated functions.  This
        // regex should handle both:
        //    Dev partial:         var reqPath = '/v1/user/getFollowersOfFollowers';
        //    Stage/Prod partial:  n="/v2/content/getContentDetails",
        //
        const re = /.*=.*['|"](\/v[^'|"]+)['|"].*/;
        const fields = re.exec(myFunc.toString());
        if (fields && fields.length === 2) {
            funcPath = fields[1];
        }

        const startTime = performance.now();

        const ret = await myFunc(data);
        const endTime = performance.now();

        console.log(funcPath + ': ' + Math.ceil(endTime - startTime) + 'ms');
        return ret;
    }
}


const apiutils = new ApiUtils();
export default apiutils;
