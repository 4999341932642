//
// Copyright 2019-2022 Reki LLC - All rights reserved.
// File: constants.ts
// Project: vue
//

//
// Constants that can be used anywhere inside the Vue code,
// but would not be accessible inside the Vue template.  If you need
// that type of feature, use a mixin (e.g., VueConstants.ts), or
// potentially try a plugin.  The mixin will pull values from this
// constants file, since the mixin data() values would not be initialized
// in time for use in the Vue data() section.
//
// The convention at the moment is to use 'constants.' inside the view code,
// even though they are accessible from 'this.'.
//
// If the value were to differ between dev and production, use config.ts.
//
// These are specific to the Vue code and are not synced with the server.  If
// you need the same constant the server uses, import SharedConstants into your
// vue code.
//

import { SocialMediaType } from '@/tsfiles/interfaces';
import { SharedConstants } from 'api';


export const COMPANY_NAME = 'Reki';
export const COMPANY_DOMAIN = 'reki.tv';
export const FULL_MARKETING_DOMAIN = 'https://reki.tv';
export const FAKE_NOTIFICATION_EMAIL_PHONE_VERIFICATION_REQUIRED = 'email-phone-verification-required';
export const RECOMMENDATIONS_PER_PAGE = 20;
export const FOLLOWEES_PER_PAGE = 20;
export const RECOMMENDATION_COMMENTS_PER_PAGE = 20;
export const HEADER_NUMBER_OF_NOTIFICATIONS = 2;
export const NOTIFICATIONS_PER_PAGE = 20;
export const HEADER_HEIGHT = 55;
export const FOOTER_HEIGHT = 35;
export const MORE_REPLIES_FETCHED_COUNT = 5;
export const TIMELINE_LIST_INSERT_FREQUENCY = 5;
export const TIMELINE_LIST_LOOKBACK_DAYS = 60;

// Most common routing names, needed by various components
export const ROUTE_MARKETING = 'marketing';
export const ROUTE_FIREBASE_SIGNIN = 'firebase_signin';
export const ROUTE_USER_HOME = 'user_home';
export const ROUTE_USER_TIMELINE = 'user_timeline';
export const ROUTE_USER_LISTS = 'user_lists';
export const ROUTE_USER_FOLLOWING = 'user_following';
export const ROUTE_USER_SETTINGS = 'user_settings';
export const ROUTE_USER_PROFILE = 'user_profile';
export const ROUTE_USER_NOTIFICATIONS = 'user_notifications';
export const ROUTE_USER_BLOCKED = 'user_blocked';
export const ROUTE_USER_INVITES = 'user_invites';
export const ROUTE_CONTENT_DETAILS = 'content_details';
export const ROUTE_LIST_CONTENTS = 'list_contents';
export const ROUTE_CONTENT_COMMENTS = 'content_comments';
export const ROUTE_CONTENT_CHAT = 'content_chat';
export const ROUTE_CREW_CONTENT = 'crew_content';
export const ROUTE_EXPLORE = 'explore';
export const ROUTE_SEARCH = 'search';
export const ROUTE_SEARCH_RESULTS = 'search_results';

//
// LocalStorage keys
//
export const LOCAL_STORAGE_KEY_AD_CHANNEL = COMPANY_DOMAIN + '-adChannel';


//
// Menu add item, which is not a specific route name.  This
// is used for EventBus message
//
export const MENU_ADD = 'menuadd';

export const RECOMMENDATION_UPDATE_EMIT_TYPE_EDIT = 'recommendationCommentEdit';
export const RECOMMENDATION_UPDATE_EMIT_TYPE_REPLY = 'commentReply';
export const RECOMMENDATION_UPDATE_EMIT_TYPE_REACTION = 'commentReaction';

//
// Social Media types, which match server constants.
// The hash is for quick access to the array(s).
// You can change the order in the UI by changing the location
// here, in SOCIAL_MEDIA_TYPES.
//
export const SOCIAL_MEDIA_TYPES = [
    {
        platform: SharedConstants.SOCIAL_PERSONAL,
        name: 'Personal', default: true, domain: '', fontIcon: 'fa-regular fa-file-user',
        imageUrl: '',
    } as SocialMediaType,

    {
        platform: SharedConstants.SOCIAL_LINKEDIN,
        name: 'Linkedin', default: true, domain: 'linkedin.com', fontIcon: 'fab fa-linkedin', imageUrl: '',
    } as SocialMediaType,

    {
        platform: SharedConstants.SOCIAL_TWITTER,
        name: 'Twitter', default: true, domain: 'twitter.com', fontIcon: 'fab fa-twitter', imageUrl: '',
    } as SocialMediaType,


    {
        platform: SharedConstants.SOCIAL_TIKTOK,
        name: 'TikTok', default: true, domain: 'tiktok.com', fontIcon: 'fab fa-tiktok', imageUrl: '',
    } as SocialMediaType,

    {
        platform: SharedConstants.SOCIAL_FACEBOOK,
        name: 'Facebook', default: true, domain: 'facebook.com', fontIcon: 'fab fa-facebook-square', imageUrl: '',
    } as SocialMediaType,

    {
        platform: SharedConstants.SOCIAL_INSTAGRAM,
        name: 'Instagram', default: true, domain: 'instagram.com', fontIcon: 'fab fa-instagram', imageUrl: '',
    } as SocialMediaType,

    {
        platform: SharedConstants.SOCIAL_SPOTIFY,
        name: 'Spotify', default: false, domain: 'spotify.com', fontIcon: 'fab fa-spotify', imageUrl: '',
    } as SocialMediaType,

    {
        platform: SharedConstants.SOCIAL_YOUTUBE,
        name: 'YouTube', default: false, domain: 'youtube.com', fontIcon: 'fab fa-youtube', imageUrl: '',
    } as SocialMediaType,

    {
        platform: SharedConstants.SOCIAL_PINTEREST,
        name: 'Pinterest', default: false, domain: 'pinterest.com', fontIcon: 'fab fa-pinterest', imageUrl: '',
    } as SocialMediaType,

    {
        platform: SharedConstants.SOCIAL_MEDIUM,
        name: 'Medium', default: false, domain: 'medium.com', fontIcon: 'fab fa-medium', imageUrl: '',
    } as SocialMediaType,

    {
        platform: SharedConstants.SOCIAL_GITHUB,
        name: 'Github', default: false, domain: 'github.com', fontIcon: 'fab fa-github', imageUrl: '',
    } as SocialMediaType,

    {
        platform: SharedConstants.SOCIAL_SOUNDCLOUD,
        name: 'SoundCloud', default: false, domain: 'soundcloud.com', fontIcon: 'fab fa-soundcloud', imageUrl: '',
    } as SocialMediaType,
];
export const SOCIAL_MEDIA_HASH = new Map();
for (const idx in SOCIAL_MEDIA_TYPES) {
    if (SOCIAL_MEDIA_TYPES.hasOwnProperty(idx)) {
        SOCIAL_MEDIA_HASH.set(SOCIAL_MEDIA_TYPES[idx].platform, idx);
    }
}




