
    import { PropOptions } from 'vue';
    import mixins from 'vue-typed-mixins';
    import VueConstants from '@/components/VueConstants';
    import UserNameDisplay from '@/components/user/UserNameDisplay.vue';
    import ListFansAndItems from '@/components/lists/ListFansAndItems.vue';
    import { List } from 'api';

    export default mixins(VueConstants).extend({
        name: 'ListRow',

        components: {
            'user-name-display': UserNameDisplay,
            'list-fans-and-items': ListFansAndItems,
        },

        props: {
            // List to display
            list: {
                type: Object,
                required: true,
            } as PropOptions<List>,

            hideOwner: {
                type: Boolean,
                required: false,
                default: false,
            },
        },

        data() {
            return {};
        },

        watch: {},

        computed: {},

        methods: {},
    });
