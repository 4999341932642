
    import Vue from 'vue';
    import { logInvalidParams, logInvalidResponse } from '@/tsfiles/errorlog';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { Utils } from '@/tsfiles/utils';
    import * as analytics from '@/tsfiles/analytics';
    import * as constants from '@/tsfiles/constants';
    import ContentDetailsV2 from '@/components/content/ContentDetailsV2.vue';
    import RecommendationEditModal from '@/components/content/RecommendationEditModal.vue';
    import ListAddRemoveContentModal from '@/components/lists/ListAddRemoveContentModal.vue';
    import AddRecommendationModal from '@/components/content/AddRecommendationModal.vue';
    import ModalAlert from '@/generic-modals/ModalAlert.vue';
    import { ContentService, Content, ContentDetailsRequest } from 'api';

    export default Vue.extend({
        name: 'ContentDetailsPage',

        components: {
            'content-details': ContentDetailsV2,
            'recommendation-edit': RecommendationEditModal,
            'list-add-remove-content': ListAddRemoveContentModal,
            'add-recommendation': AddRecommendationModal,
            'modal-alert': ModalAlert,
        },

        props: {},

        data() {
            return {
                content: undefined as Content | undefined,

                deleteRecommendation: false,
                addRecommendation: false,
                editRecommendation: false,
                listAddRemoveContent: false,
            };
        },

        watch: {
            //
            // Look for contentId and/or contentPublicUrl changes.  We want to get
            // the contentId for the content.  Originally there was no contentPublicUrl.  Instead
            // of changing all the code to use publicUrl, we are just making sure we have the
            // contentId. If coming from an internal route, both are probably set.  If a browser refresh
            // happens with the URL being the publicUrl, we won't yet have the contentId.  Ask the
            // server for it.
            //
            '$route.params': {
                immediate: true,
                deep: true,
                handler(newVal, oldVal) {
                    if (!newVal.contentId && newVal.contentPublicUrl) {
                        this.getContentIdFromPublicUrl(newVal.contentPublicUrl);
                    } else if (newVal.contentId) {
                        this.getContentDetails(parseInt(newVal.contentId, 10));
                    }
                },
            },
        },

        computed: {},

        methods: {
            async getContentIdFromPublicUrl(publicUrl: string) {
                //
                // If the publicUrl is all numbers, that's the contentId.  That could
                // happen if there's no publicUrl for a content object yet.  This shouldn't
                // happen very often, maybe with new content in smarty, since smarty won't
                // generate the publicUrl if it doesn't exist.  If the publicUrl is a contentId, ask
                // the server for the publicUrl, so we can change the URL in the browser.
                //
                // Try/Catch are done separately, since we want to intercept a 404 when looking
                // up the publicUrl by contentId.  There's a chance there is no publicUrl, but we
                // are ok since we can use the contentId only.
                //
                if (publicUrl.match(/^[0-9]+$/)) {
                    this.getContentDetails(parseInt(publicUrl, 10));
                } else {
                    try {
                        const ret = await ApiUtils.apiWrapper(ContentService.getContentIdByPublicUrl, publicUrl);
                        if (ret && ret.id) {
                            this.getContentDetails(ret.id);
                        }
                    } catch (error) {
                        Utils.CommonErrorHandler(error);
                    }
                }
            },

            async getContentDetails(contentId: number) {
                if (contentId <= 0) {
                    logInvalidParams(this.$options.name, 'fetchFullDetails');
                    return;
                }

                try {
                    this.content = await ApiUtils.apiWrapper(ContentService.getContentDetailsV2, {
                        contentId,
                        publicUrl: this.$router.currentRoute.params.publicUrl,
                    } as ContentDetailsRequest);
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            getDeleteRecommendationTitle(): string {
                if (!this.content) {
                    logInvalidParams(this.$options.name, 'getDeleteRecommendationTitle');
                    return '';
                }

                return 'Are you sure you want to delete your Reki: <br><br> <b>' + this.content.name + '</b></br><br>';
            },

            async okToDeleteRecommendation() {
                if (!this.content) {
                    logInvalidParams(this.$options.name, 'okToDeleteRecommendation');
                    return;
                }

                try {
                    const deletedPublicUrl = this.content.contentPublicUrl;
                    await ApiUtils.apiWrapper(ContentService.deleteRecommendation, this.content.contentId as number);

                    analytics.logAppInteraction(analytics.ANALYTICS_ACTION_DELETE_RECOMMENDATION, deletedPublicUrl);

                    //
                    // The content has been deleted.  What to do?  Just go back to previous page.
                    //
                    this.$router.back();
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async saveNewRecommendation(data: any) {
                if (!this.content) {
                    logInvalidParams(this.$options.name, 'saveNewRecommendation');
                    return;
                }

                const updatedText = data.comment;
                const updatedRating = data.rating;

                // Comment can be empty, but not rating
                if (!this.addRecommendation || updatedRating === 0) {
                    logInvalidParams(this.$options.name, 'saveNewRecommendation');
                    return;
                }

                try {
                    const ret = await ApiUtils.apiWrapper(ContentService.addRecommendation, {
                        contentId: this.content.contentId,
                        comment: updatedText,
                        rating: updatedRating,
                    });

                    if (!ret || !ret.recommendedBy || ret.recommendedBy.length !== 1) {
                        logInvalidResponse(this.$options.name, 'saveNewRecommendation');
                        return;
                    }

                    this.getContentDetails(this.content.contentId as number);

                    analytics.logAppInteraction(
                        analytics.ANALYTICS_ACTION_NEW_RECOMMENDATION,
                        this.content.contentPublicUrl,
                    );

                    this.addRecommendation = false;
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            //
            // The authenticated user is editing their own reki
            //
            async saveRecommendationUpdate(data: any) {
                if (!this.content) {
                    logInvalidParams(this.$options.name, 'saveRecommendationUpdate');
                    return;
                }

                const updatedText = data.comment;
                const updatedRating = data.rating;

                // Comment can be empty, but not rating
                if (!this.editRecommendation || updatedRating === 0) {
                    logInvalidParams(this.$options.name, 'saveRecommendationUpdate');
                    return;
                }

                try {
                    const ret = await ApiUtils.apiWrapper(ContentService.updateRecommendation, {
                        contentId: this.content.contentId,
                        comment: updatedText,
                        rating: updatedRating,
                    });

                    if (!ret || !ret.recommendedBy || ret.recommendedBy.length !== 1) {
                        logInvalidResponse(this.$options.name, 'saveRecommendationUpdate');
                        return;
                    }

                    this.getContentDetails(this.content.contentId as number);

                    analytics.logAppInteraction(
                        analytics.ANALYTICS_ACTION_UPDATE_RECOMMENDATION,
                        this.content.contentPublicUrl,
                    );

                    this.editRecommendation = false;
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            gotoComments() {
                if (!this.content || !this.content.contentPublicUrl) {
                    logInvalidParams(this.$options.name, 'gotoComments');
                    return;
                }

                this.$router.push({
                    name: constants.ROUTE_CONTENT_COMMENTS,
                    params: { contentPublicUrl: this.content?.contentPublicUrl as string },
                });
            },
        },
    });
