
    import { PropOptions } from 'vue';
    import mixins from 'vue-typed-mixins';
    import VueConstants from '@/components/VueConstants';
    import { RecommendationUpdateEmit } from '@/tsfiles/interfaces';
    import { logInvalidParams, logInvalidResponse } from '@/tsfiles/errorlog';
    import * as constants from '@/tsfiles/constants';
    import * as analytics from '@/tsfiles/analytics';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { Utils } from '@/tsfiles/utils';
    import UserNameDisplay from '@/components/user/UserNameDisplay.vue';
    import CommentReply from '@/components/uiutils/CommentReply.vue';
    import CommentLike from '@/components/uiutils/CommentLike.vue';
    import StarRating from '@/components/uiutils/StarRating.vue';
    import { UserService, ContentService, Content, UserRecommendation, CommentRequest } from 'api';

    export default mixins(VueConstants).extend({
        name: 'ContentRecommendationV2',

        components: {
            'user-name-display': UserNameDisplay,
            'comment-reply': CommentReply,
            'comment-like': CommentLike,
            'star-rating': StarRating,
        },

        props: {
            content: {
                type: Object,
                required: true,
            } as PropOptions<Content>,

            recommendation: {
                type: Object,
                required: true,
                default: () => undefined as UserRecommendation | undefined,
            } as PropOptions<UserRecommendation>,

            hideRecommenderName: {
                type: Boolean,
                required: false,
                default: false,
            },
        },

        data() {
            return {
                editComment: false,
            };
        },

        computed: {
            currentUsersRecommendation(): boolean {
                return (
                    this.recommendation &&
                    this.recommendation.user &&
                    this.$store.getters.isUsersPublicUrl(this.recommendation.user.publicUrl)
                );
            },

            validComment(): boolean {
                return (
                    this.recommendation !== undefined &&
                    this.recommendation.comment !== undefined &&
                    this.recommendation.comment.trim() !== ''
                );
            },
        },

        methods: {
            tellParentAboutChanges(
                type: string,
                recommendation: UserRecommendation,
                totalComments: number | undefined,
                totalFolloweeComments: number | undefined,
            ) {
                this.$emit('recommendation-updated', {
                    type,
                    recommendation,
                    totalComments,
                    totalFolloweeComments,
                } as RecommendationUpdateEmit);
            },

            async commentSubmitted(replyText: string) {
                if (replyText === '') {
                    logInvalidParams(this.$options.name, 'commentSubmitted');
                    return;
                }

                try {
                    const ret = await ApiUtils.apiWrapper(ContentService.replyToComment, {
                        contentId: this.content.contentId,
                        recommendationId: this.recommendation.recommendationId,
                        comment: replyText,
                    } as CommentRequest);

                    if (!ret || !ret.userRecommendation || !ret.userRecommendation.recommendationId) {
                        logInvalidResponse(this.$options.name, 'commentSubmitted');
                        return;
                    }

                    this.tellParentAboutChanges(
                        constants.RECOMMENDATION_UPDATE_EMIT_TYPE_REPLY,
                        ret.userRecommendation,
                        ret.totalComments,
                        ret.totalFolloweeComments,
                    );

                    analytics.logAppInteraction(
                        analytics.ANALYTICS_ACTION_COMMENT_REPLY,
                        this.content.contentPublicUrl,
                    );
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async likeComment() {
                if (!this.recommendation) {
                    logInvalidParams(this.$options.name, 'likeComment');
                    return;
                }

                try {
                    const ret = await ApiUtils.apiWrapper(ContentService.likeComment, {
                        contentId: this.content.contentId,
                        recommendationId: this.recommendation.recommendationId,
                    } as CommentRequest);

                    if (!ret || !ret.recommendationId) {
                        logInvalidResponse(this.$options.name, 'likeComment');
                        return;
                    }

                    this.tellParentAboutChanges(
                        constants.RECOMMENDATION_UPDATE_EMIT_TYPE_REACTION,
                        ret,
                        undefined,
                        undefined,
                    );

                    analytics.logAppInteraction(analytics.ANALYTICS_ACTION_COMMENT_LIKE, this.content.contentPublicUrl);
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async unlikeComment() {
                if (!this.recommendation) {
                    logInvalidParams(this.$options.name, 'unlikeComment');
                    return;
                }

                try {
                    const ret = await ApiUtils.apiWrapper(ContentService.unlikeComment, {
                        contentId: this.content.contentId,
                        recommendationId: this.recommendation.recommendationId,
                    } as CommentRequest);

                    if (!ret || !ret.recommendationId) {
                        logInvalidResponse(this.$options.name, 'unlikeComment');
                        return;
                    }

                    this.tellParentAboutChanges(
                        constants.RECOMMENDATION_UPDATE_EMIT_TYPE_REACTION,
                        ret,
                        undefined,
                        undefined,
                    );

                    analytics.logAppInteraction(
                        analytics.ANALYTICS_ACTION_COMMENT_UNLIKE,
                        this.content.contentPublicUrl,
                    );
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async reportAbuse() {
                try {
                    await ApiUtils.apiWrapper(UserService.reportAbuse, {
                        publicUrl: this.recommendation.user?.publicUrl,
                        recommendationId: this.recommendation.recommendationId,
                    });

                    alert('This recommendation comment has been reported as potential abuse');
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },
        },
    });
