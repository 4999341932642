
    import Vue, { PropOptions } from 'vue';
    import mixins from 'vue-typed-mixins';
    import VueConstants from '@/components/VueConstants';
    import * as analytics from '@/tsfiles/analytics';
    import { logInvalidParams, logInvalidResponse } from '@/tsfiles/errorlog';
    import { Utils } from '@/tsfiles/utils';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { PageNavigationData, RecommendationUpdateEmit } from '@/tsfiles/interfaces';
    import * as constants from '@/tsfiles/constants';
    import Avatar from '@/components/Avatar.vue';
    import SplitSearch from '@/components/explore/SplitSearch.vue';
    import ContentRowV2 from '@/components/content/ContentRowV2.vue';
    import SingleFriend from '@/components/user/SingleFriend.vue';
    import ListRow from '@/components/lists/ListRow.vue';
    import AddRecommendationModal from '@/components/content/AddRecommendationModal.vue';
    import RecommendationEdit from '@/components/content/RecommendationEditModal.vue';
    import ListAddRemoveContentModal from '@/components/lists/ListAddRemoveContentModal.vue';
    import ModalAlert from '@/generic-modals/ModalAlert.vue';
    import {
        GenericPageRetrieval,
        SmartyService,
        User,
        ContentService,
        ContentV2,
        List,
        SharedConstants,
        UserRecommendation,
        Crew,
    } from 'api';

    export default mixins(VueConstants).extend({
        name: 'Explore',

        components: {
            'url-avatar': Avatar,
            'split-search': SplitSearch,
            'content-row': ContentRowV2,
            'single-friend': SingleFriend,
            'list-row': ListRow,
            'add-recommendation': AddRecommendationModal,
            'recommendation-edit': RecommendationEdit,
            'list-add-remove-content': ListAddRemoveContentModal,
            'modal-alert': ModalAlert,
        },

        props: {},

        data() {
            return {
                movies: [] as ContentV2[],
                shows: [] as ContentV2[],
                people: [] as User[],
                cast: [] as Crew[],
                lists: [] as List[],
                currentTab: 0,
                currentNavigation: { page: constants.ROUTE_EXPLORE } as PageNavigationData,

                addRecommendation: undefined as ContentV2 | undefined,
                editRecommendation: undefined as ContentV2 | undefined,
                deleteRecommendation: undefined as ContentV2 | undefined,
                listAddRemoveContent: undefined as ContentV2 | undefined,
            };
        },

        mounted() {
            this.fetchPopularMovies();
            this.fetchPopularShows();
            this.fetchPopularPeople();
            this.fetchPopularLists();
            this.fetchPopularCast();

            //
            // If page navigation contains data, process.
            //
            const nav = this.$store.getters.getPageNavigation(constants.ROUTE_EXPLORE);
            if (nav && nav.tab) {
                this.$nextTick(() => {
                    this.currentTab = nav.tab;
                    this.currentNavigation.tab = nav.tab;
                });
            }
        },

        beforeDestroy() {
            this.$store.commit('setPageNavigation', this.currentNavigation);
        },

        computed: {},

        methods: {
            async fetchPopularMovies() {
                try {
                    const ret = await ApiUtils.apiWrapper(SmartyService.getPopularContent, {
                        pageNumber: 1,
                        numberOfItems: 20,
                        filterBy: [
                            {
                                type: SharedConstants.FILTER_BY_FORMAT,
                                data: SharedConstants.FILTER_FORMAT_MOVIES,
                            },
                        ],
                    } as GenericPageRetrieval);

                    if (ret.results) {
                        this.movies = ret.results;
                    } else {
                        this.movies = [] as ContentV2[];
                    }
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async fetchPopularShows() {
                try {
                    const ret = await ApiUtils.apiWrapper(SmartyService.getPopularContent, {
                        pageNumber: 1,
                        numberOfItems: 20,
                        filterBy: [
                            {
                                type: SharedConstants.FILTER_BY_FORMAT,
                                data: SharedConstants.FILTER_FORMAT_SHOWS,
                            },
                        ],
                    } as GenericPageRetrieval);

                    if (ret.results) {
                        this.shows = ret.results;
                    } else {
                        this.shows = [] as ContentV2[];
                    }
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async fetchPopularPeople() {
                try {
                    const ret = await ApiUtils.apiWrapper(SmartyService.getPopularPeople, {
                        pageNumber: 1,
                        numberOfItems: 20,
                    } as GenericPageRetrieval);

                    if (ret.results) {
                        this.people = ret.results;
                    } else {
                        this.people = [] as User[];
                    }
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async fetchPopularCast() {
                try {
                    const ret = await ApiUtils.apiWrapper(SmartyService.getPopularCrew, {
                        pageNumber: 1,
                        numberOfItems: 20,
                    } as GenericPageRetrieval);

                    if (ret.results) {
                        this.cast = ret.results;
                    } else {
                        this.cast = [] as Crew[];
                    }
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async fetchPopularLists() {
                try {
                    const ret = await ApiUtils.apiWrapper(SmartyService.getPopularLists, {
                        pageNumber: 1,
                        numberOfItems: 20,
                    } as GenericPageRetrieval);

                    if (ret.results) {
                        this.lists = ret.results;
                    } else {
                        this.lists = [] as List[];
                    }
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async saveNewRecommendation(data: any) {
                if (!this.addRecommendation) {
                    logInvalidParams(this.$options.name, 'saveNewRecommendation');
                    return;
                }

                const updatedText = data.comment;
                const updatedRating = data.rating;

                // Comment can be empty, but not rating
                if (!this.addRecommendation || updatedRating === 0) {
                    logInvalidParams(this.$options.name, 'saveNewRecommendation');
                    return;
                }

                try {
                    const ret = await ApiUtils.apiWrapper(ContentService.addRecommendation, {
                        contentId: this.addRecommendation.contentId,
                        comment: updatedText,
                        rating: updatedRating,
                    });

                    if (!ret || !ret.recommendedBy || ret.recommendedBy.length !== 1) {
                        logInvalidResponse(this.$options.name, 'saveNewRecommendation');
                        return;
                    }

                    if (this.addRecommendation.format === SharedConstants.CONTENT_FORMAT_MOVIE) {
                        this.fetchPopularMovies();
                    } else {
                        this.fetchPopularShows();
                    }

                    analytics.logAppInteraction(
                        analytics.ANALYTICS_ACTION_NEW_RECOMMENDATION,
                        this.addRecommendation.contentPublicUrl,
                    );

                    this.addRecommendation = undefined as ContentV2 | undefined;
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            //
            // The authenticated user is editing their own reki
            //
            async saveRecommendationUpdate(data: any) {
                const updatedText = data.comment;
                const updatedRating = data.rating;

                // Comment can be empty, but not rating
                if (!this.editRecommendation || updatedRating === 0) {
                    logInvalidParams(this.$options.name, 'saveRecommendationUpdate');
                    return;
                }

                try {
                    const ret = await ApiUtils.apiWrapper(ContentService.updateRecommendation, {
                        contentId: this.editRecommendation.contentId,
                        comment: updatedText,
                        rating: updatedRating,
                    });

                    if (!ret || !ret.recommendedBy || ret.recommendedBy.length !== 1) {
                        logInvalidResponse(this.$options.name, 'saveRecommendationUpdate');
                        return;
                    }

                    if (this.editRecommendation.format === SharedConstants.CONTENT_FORMAT_MOVIE) {
                        this.fetchPopularMovies();
                    } else {
                        this.fetchPopularShows();
                    }

                    analytics.logAppInteraction(
                        analytics.ANALYTICS_ACTION_UPDATE_RECOMMENDATION,
                        this.editRecommendation.contentPublicUrl,
                    );

                    this.editRecommendation = undefined as ContentV2 | undefined;
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            //
            // When the lower level components do like or unlike, or reply, we eventually get
            // the 'recommendation-updated' emit, with the data RecommendationUpdateEmit.  That contains the
            // new recommendation, and, if defined, the new comments totals.
            //
            movieRecommendationUpdated(idx: number, data: RecommendationUpdateEmit) {
                if (!this.movies || idx < 0 || idx >= this.movies.length || !data || !data.recommendation) {
                    logInvalidParams(this.$options.name, 'movieRecommendationUpdated');
                    return;
                }

                if (this.movies[idx].authedUserRecommendation) {
                    Vue.set(
                        this.movies[idx].authedUserRecommendation as UserRecommendation,
                        'totalCommentLikes',
                        data.recommendation.totalCommentLikes,
                    );

                    Vue.set(
                        this.movies[idx].authedUserRecommendation as UserRecommendation,
                        'authedUserLikedComment',
                        data.recommendation.authedUserLikedComment,
                    );
                } else {
                    if (this.movies[idx].latestRecommendation) {
                        Vue.set(
                            this.movies[idx].latestRecommendation as UserRecommendation,
                            'totalCommentLikes',
                            data.recommendation.totalCommentLikes,
                        );

                        Vue.set(
                            this.movies[idx].latestRecommendation as UserRecommendation,
                            'authedUserLikedComment',
                            data.recommendation.authedUserLikedComment,
                        );
                    }
                }

                if (data.totalComments) {
                    Vue.set(this.movies[idx], 'totalComments', data.totalComments);
                }

                if (data.totalFolloweeComments) {
                    Vue.set(this.movies[idx], 'totalFolloweeComments', data.totalFolloweeComments);
                }
            },

            //
            // When the lower level components do like or unlike, or reply, we eventually get
            // the 'recommendation-updated' emit, with the data RecommendationUpdateEmit.  That contains the
            // new recommendation, and, if defined, the new comments totals.
            //
            showRecommendationUpdated(idx: number, data: RecommendationUpdateEmit) {
                if (!this.shows || idx < 0 || idx >= this.shows.length || !data || !data.recommendation) {
                    logInvalidParams(this.$options.name, 'showRecommendationUpdated');
                    return;
                }

                if (this.shows[idx].authedUserRecommendation) {
                    Vue.set(
                        this.shows[idx].authedUserRecommendation as UserRecommendation,
                        'totalCommentLikes',
                        data.recommendation.totalCommentLikes,
                    );

                    Vue.set(
                        this.shows[idx].authedUserRecommendation as UserRecommendation,
                        'authedUserLikedComment',
                        data.recommendation.authedUserLikedComment,
                    );
                } else {
                    if (this.shows[idx].latestRecommendation) {
                        Vue.set(
                            this.shows[idx].latestRecommendation as UserRecommendation,
                            'totalCommentLikes',
                            data.recommendation.totalCommentLikes,
                        );

                        Vue.set(
                            this.shows[idx].latestRecommendation as UserRecommendation,
                            'authedUserLikedComment',
                            data.recommendation.authedUserLikedComment,
                        );
                    }
                }

                if (data.totalComments) {
                    Vue.set(this.shows[idx], 'totalComments', data.totalComments);
                }

                if (data.totalFolloweeComments) {
                    Vue.set(this.shows[idx], 'totalFolloweeComments', data.totalFolloweeComments);
                }
            },

            getDeleteRecommendationTitle(): string {
                if (!this.deleteRecommendation) {
                    logInvalidParams(this.$options.name, 'getDeleteRecommendationTitle');
                    return '';
                }

                return (
                    'Are you sure you want to delete your Reki: <br><br> <b>' +
                    this.deleteRecommendation.name +
                    '</b></br><br>'
                );
            },

            async okToDeleteRecommendation() {
                if (!this.deleteRecommendation) {
                    logInvalidParams(this.$options.name, 'okToDeleteRecommendation');
                    return;
                }

                try {
                    const deletedPublicUrl = this.deleteRecommendation.contentPublicUrl;
                    const deletedContentFormat = this.deleteRecommendation.format;

                    await ApiUtils.apiWrapper(
                        ContentService.deleteRecommendation,
                        this.deleteRecommendation.contentId as number,
                    );

                    if (deletedContentFormat === SharedConstants.CONTENT_FORMAT_MOVIE) {
                        this.fetchPopularMovies();
                    } else {
                        this.fetchPopularShows();
                    }

                    analytics.logAppInteraction(analytics.ANALYTICS_ACTION_DELETE_RECOMMENDATION, deletedPublicUrl);
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            gotoDetails(item: ContentV2) {
                this.$router.push({
                    name: constants.ROUTE_CONTENT_DETAILS,
                    params: { contentPublicUrl: item.contentPublicUrl as string },
                });
            },

            gotoPerson(person: User) {
                this.$router.push({
                    name: constants.ROUTE_USER_HOME,
                    params: { publicUrl: person.publicUrl as string },
                });
            },

            gotoList(list: List) {
                if (!list.owner || !list.listId) {
                    logInvalidParams(this.$options.name, 'gotoList');
                    return;
                }

                this.$router.push({
                    name: constants.ROUTE_LIST_CONTENTS,
                    params: { publicUrl: list.owner.publicUrl as string, listId: list.listId.toString() },
                });
            },

            gotoComments(item: ContentV2) {
                this.$router.push({
                    name: constants.ROUTE_CONTENT_COMMENTS,
                    params: { contentPublicUrl: item.contentPublicUrl as string },
                });
            },

            gotoCast(crew: Crew) {
                if (!crew.crewId) {
                    logInvalidParams(this.$options.name, 'gotoCast');
                    return;
                }

                this.$router.push({ name: constants.ROUTE_CREW_CONTENT, params: { crewId: crew.crewId.toString() } });
            },
        },
    });
