
    import mixins from 'vue-typed-mixins';
    import VueConstants from '@/components/VueConstants';
    import * as constants from '@/tsfiles/constants';

    export default mixins(VueConstants).extend({
        name: 'ChannelFlaggedAlert',

        components: {},

        props: {
            // What channel type?
            type: {
                type: String,
                required: true,
            },

            // What is the flagged value (user's email or phone)
            channelValue: {
                type: String,
                required: true,
            },

            // What's the reason for the channel being flagged?
            reason: {
                type: String,
                required: true,
            },
        },

        data() {
            return {};
        },

        watch: {},

        computed: {},

        methods: {},
    });
