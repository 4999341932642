
    import Vue from 'vue';
    import { logInvalidParams } from '@/tsfiles/errorlog';

    export default Vue.extend({
        name: 'AuthedUserPageRedirect',

        components: {},

        props: {},

        data() {
            return {};
        },

        //
        // Generic redirect to an authed user's page.  This was most likely
        // called during signin, trying to redirect to a page that requires
        // the publicUrl.
        //
        mounted() {
            if (!this.$store.getters.getPublicUrl || !this.$router.currentRoute.query.page) {
                logInvalidParams(this.$options.name, 'mounted');
            }

            this.$router.replace({
                name: this.$router.currentRoute.query.page as string,
                params: { publicUrl: this.$store.getters.getPublicUrl },
            });
        },

        computed: {},

        methods: {},
    });
