
    import { BNavItemDropdown, BvEvent } from 'bootstrap-vue';
    import Vue, { PropOptions } from 'vue';
    import { Utils } from '@/tsfiles/utils';

    export default Vue.extend({
        name: 'FilterMenu',

        components: {},

        props: {
            topItems: {
                type: Array,
                required: true,
            } as PropOptions<string[]>,

            selectedTopItems: {
                type: Array,
                required: true,
            } as PropOptions<any[]>,

            allOtherItems: {
                type: Array,
                required: false,
                default: () => [] as string[],
            } as PropOptions<string[]>,

            selectedAllOtherItems: {
                type: Array,
                required: false,
                default: () => [] as any[],
            } as PropOptions<any[]>,

            title: {
                type: String,
                required: false,
                default: 'Filter By',
            },
        },

        data() {
            return {
                seeMore: false,
                topSelected: [],
                allOtherSelected: [],
                topItemsHalfCount: 0,

                resetSelected: false,
            };
        },

        watch: {
            topItems: {
                immediate: true,
                handler(newVal: number, oldVal: number) {
                    this.topItemsHalfCount = Math.ceil(this.topItems.length / 2);
                },
            },

            selectedTopItems: {
                immediate: true,
                handler(newVal: any, oldVal: any) {
                    if (newVal && newVal.length > 0) {
                        this.topSelected = Utils.deepCopy(newVal);
                    } else {
                        this.topSelected = [];
                    }
                },
            },

            selectedAllOtherItems: {
                immediate: true,
                handler(newVal: any, oldVal: any) {
                    if (newVal && newVal.length > 0) {
                        this.allOtherSelected = Utils.deepCopy(newVal);
                    } else {
                        this.allOtherSelected = [];
                    }
                },
            },
        },

        computed: {
            firstColumn(): string[] {
                let count = 0;
                return this.topItems.filter(() => {
                    const curCount = count;
                    count++;
                    return curCount < this.topItemsHalfCount;
                });
            },

            secondColumn(): string[] {
                let count = 0;
                return this.topItems.filter(() => {
                    const curCount = count;
                    count++;
                    return curCount >= this.topItemsHalfCount;
                });
            },

            //
            // Return true if the Apply button should be disabled.  Use
            // the topItems and allOtherItems to loop through, since the
            // selected arrays can be empty.
            //
            disableSubmit(): boolean {
                for (let i = 0; i < this.topItems.length; i++) {
                    if (
                        (this.topSelected[i] && !this.selectedTopItems[i]) ||
                        (!this.topSelected[i] && this.selectedTopItems[i])
                    ) {
                        return false;
                    }
                }

                for (let i = 0; i < this.allOtherItems.length; i++) {
                    if (
                        (this.allOtherSelected[i] && !this.selectedAllOtherItems[i]) ||
                        (!this.allOtherSelected[i] && this.selectedAllOtherItems[i])
                    ) {
                        return false;
                    }
                }

                return true;
            },

            somethingSelected(): boolean {
                for (const val of this.topSelected) {
                    if (val) {
                        return true;
                    }
                }

                for (const val of this.allOtherSelected) {
                    if (val) {
                        return true;
                    }
                }

                return false;
            },
        },

        methods: {
            submit() {
                this.$emit('apply-filters', { top: this.topSelected, allOther: this.allOtherSelected });
                if (this.$refs.filterDropdown) {
                    const dropdown = this.$refs.filterDropdown as BNavItemDropdown;
                    dropdown.hide(true);
                }
            },

            reset() {
                this.resetSelected = true;
                this.topSelected = [];
                this.allOtherSelected = [];
            },

            closeDropdown(event: BvEvent) {
                if (this.resetSelected) {
                    // Reset button clicked.  Don't close dropdown
                    this.topSelected = [];
                    this.allOtherSelected = [];
                    this.resetSelected = false;
                    event.preventDefault();
                } else {
                    // Reset to the given selected items when closing window
                    this.topSelected = Utils.deepCopy(this.selectedTopItems);
                    this.allOtherSelected = Utils.deepCopy(this.selectedAllOtherItems);
                }
            },
        },
    });
