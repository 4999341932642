
    import Vue, { PropOptions } from 'vue';
    import * as analytics from '@/tsfiles/analytics';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { Utils } from '@/tsfiles/utils';
    import Smarty from '@/components/Smarty.vue';
    import StarRating from '@/components/uiutils/StarRating.vue';
    import { ContentService, Content, AddContentRequest } from 'api';

    export default Vue.extend({
        name: 'AddContent',

        components: {
            'ui-smarty': Smarty,
            'star-rating': StarRating,
        },

        props: {
            showCancel: {
                type: Boolean,
                required: false,
                default: true,
            },

            // If no smarty requested, what is the read-only content
            readonlyContent: {
                type: Object,
                required: false,
                default: () => undefined as Content | undefined,
            } as PropOptions<Content>,
        },

        data() {
            return {
                comment: '',
                rating: 0,
                content: {} as Content,
                submitting: false,
            };
        },

        watch: {
            readonlyContent: {
                immediate: true,
                deep: true,
                handler(newVal: Content, oldVal: Content) {
                    if (newVal && newVal !== oldVal) {
                        this.content = newVal as Content;
                    }
                },
            },
        },

        computed: {
            getNumCharactersDisplay(): string {
                return '(' + this.comment.length + ' of 2500)';
            },
        },

        methods: {
            //
            // All fields are required.
            //
            inputsValid(): boolean {
                return (
                    this.content.contentId !== undefined &&
                    this.content.contentId > 0 &&
                    this.content.name !== undefined &&
                    this.content.name !== '' &&
                    this.comment !== '' &&
                    this.rating !== 0
                );
            },

            smartyContentSelection(data: any) {
                const selection = data.newVal as Content;
                if (selection.name && selection.name !== '') {
                    this.content = selection;
                }
            },

            async handleSave(event: Event) {
                if (this.submitting) {
                    return;
                }

                this.submitting = true;

                try {
                    const ret = await ApiUtils.apiWrapper(ContentService.addRecommendation, {
                        contentId: this.content.contentId,
                        comment: this.comment,
                        rating: this.rating,
                    } as AddContentRequest);

                    if (ret) {
                        analytics.logAppInteraction(
                            analytics.ANALYTICS_ACTION_NEW_RECOMMENDATION,
                            this.content.contentPublicUrl,
                        );
                        this.$emit('content-added', ret);
                    }

                    this.submitting = false;
                } catch (error: any) {
                    Utils.CommonErrorHandler(error);
                }
            },
        },
    });
