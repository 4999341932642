
    import mixins from 'vue-typed-mixins';
    import * as constants from '@/tsfiles/constants';
    import * as analytics from '@/tsfiles/analytics';
    import { profileRefresh } from '@/tsfiles/profilerefresh';
    import { logInvalidParams } from '@/tsfiles/errorlog';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { Utils } from '@/tsfiles/utils';
    import VueConstants from '@/components/VueConstants';
    import { SharedConstants, InviteService, GenericInvite, ProcessInviteRequest } from 'api';

    export default mixins(VueConstants).extend({
        name: 'ProcessFriendInvite',

        props: {
            //
            // If coming from another component, the code can be passed in.  If coming
            // from an email link, the code is part of $route.
            //
            code: {
                type: String,
                required: false,
                default: '',
            },

            //
            // The client needs to tell the server where the processInvite initiated from.  If coming
            // from an email click, the server may have an opportunity to auto verify the email channel.
            // If coming from an in-app notification or push notification, the server cannot do any type
            // of auto email verification.  Why a string an not Boolean?  router.push doesn't allow
            // boolean params, and we do a push via Notification.
            //
            fromEmail: {
                type: String,
                required: false,
                default: 'true',
            },
        },

        data() {
            return {
                localCode: this.code,
                warningCode: '',
                data: undefined as GenericInvite | undefined,
            };
        },

        watch: {
            '$route.query.code': {
                immediate: true,
                handler(newVal, oldVal) {
                    let code = this.$router.currentRoute.query.code;

                    // If missing query and props data, ignore and go to front-door
                    if ((!code || code === '') && this.localCode === '') {
                        this.$router.replace({ name: constants.ROUTE_MARKETING });
                    } else if (!code || code === '') {
                        code = this.localCode;
                    }

                    // If the user is already signed in, process the request
                    if (this.$store.getters.isSignedIn) {
                        this.processInvite(code as string);
                    } else {
                        this.fetchInvite(code as string);
                    }
                },
            },
        },

        computed: {},

        methods: {
            //
            // Create bodyText for signin page
            //
            getSigninText(inviteType: string): string {
                if (inviteType === '' || !this.data || !this.data.sender) {
                    logInvalidParams(this.$options.name, 'getSigninText');
                    return '';
                }

                switch (inviteType) {
                    case SharedConstants.INVITE_FRIEND:
                        return (
                            this.data.sender.name +
                            ' (@' +
                            this.data.sender.publicUrl +
                            ')' +
                            ' invited you to join ' +
                            constants.COMPANY_DOMAIN +
                            ' to see their TV and movie recommendations. In order to view the ' +
                            'recommendations, you need to sign in or create an account.'
                        );
                        break;
                }

                return '';
            },

            async fetchInvite(code: string) {
                if (code === '') {
                    logInvalidParams(this.$options.name, 'fetchInvite');
                    this.$router.replace({ name: constants.ROUTE_MARKETING });

                    return;
                }

                try {
                    this.data = await ApiUtils.apiWrapper(InviteService.getInvite, code);
                    if (!this.data) {
                        this.$router.replace({ name: constants.ROUTE_MARKETING });
                    } else if (this.data.warningCode && this.data.warningCode !== '') {
                        this.warningCode = this.data.warningCode;
                    } else if (this.data.sender) {
                        const signinText = this.getSigninText(this.data.inviteType as string);

                        this.$router.replace({
                            name: constants.ROUTE_FIREBASE_SIGNIN,
                            params: { bodyText: signinText },
                            query: { redirect: this.$router.currentRoute.fullPath },
                        });
                    }
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            //
            // processInvite will only be called if signed in.  The server process
            // request will do all the auto-verification, etc., if that's possible.
            //
            async processInvite(code: string) {
                if (code === '') {
                    logInvalidParams(this.$options.name, 'processInvite');
                    return;
                }

                try {
                    const ret = await ApiUtils.apiWrapper(InviteService.processFriendInvite, {
                        code,
                        fromEmail: this.fromEmail === 'true',
                    } as ProcessInviteRequest);

                    if (!ret.warningCode || ret.warningCode === '') {
                        //
                        // The signing in user may get their email verified automatically
                        // if their email domain matched the domain where the invite went.  We
                        // want to get the user's profile to make sure the email and email_verified
                        // is set correctly.
                        //
                        if (ret.emailAutoVerified) {
                            profileRefresh();
                        }

                        // Set store tab to be Friends, before going home
                        this.$store.commit('setCurrentMenu', constants.ROUTE_USER_FOLLOWING);

                        analytics.logAppInteraction(analytics.ANALYTICS_ACTION_INVITE_ACCEPTED);

                        this.$router.replace({
                            name: constants.ROUTE_USER_HOME,
                            params: { publicUrl: this.$store.getters.getPublicUrl },
                        });
                        return;
                    }

                    //
                    // We are going to stay on this page since there was a warning code
                    // returned.  Fetch the invite data so we can show something to the user.
                    //
                    this.warningCode = ret.warningCode;
                    this.fetchInvite(code);
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },
        },
    });
