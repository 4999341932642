
    import Vue from 'vue';
    import * as constants from '@/tsfiles/constants';
    import UserSettings from '@/components/user/UserSettings.vue';
    import ModalAlert from '@/generic-modals/ModalAlert.vue';

    export default Vue.extend({
        name: 'Settings',

        components: {
            'settings-ui': UserSettings,
            'modal-alert': ModalAlert,
        },

        props: {},

        data() {
            return {
                settingsDirty: false,
                showUnsavedChangesModal: false,
                unsavedChangesAlertText: 'You have unsaved profile changes.  Are you sure you want to leave this page?',
                unsavedChangesNextRoute: undefined as any | undefined,
            };
        },

        //
        // If the user has made changes to the settings page, make sure
        // they are ok losing the changes, or do they want to stay on that
        // page.  With a semi-single page design, this is messy.  You cannot
        // do beforeRouteLeave on subcomponents, so we use Store to keep the
        // settings is-dirty flag.  Why not have real components for each page?
        // That would cause hits to the api when moving around on the menu, which
        // we don't want.
        //
        // The only settings change that causes big problems with this mechanism is
        // when the publicUrl changes.  Only when that happens does the settings page
        // do a router.replace to constants.ROUTE_USER_HOME to get the new publicUrl
        // into the URL.  We need to special case that so we don't show the unsaved
        // change modal if go to constants.ROUTE_USER_HOME with a different URL.
        //
        beforeRouteLeave(to, from, next) {
            const allowEvenIfDirty =
                to.name === constants.ROUTE_USER_HOME && to.params.publicUrl !== from.params.publicUrl;

            if (!this.settingsDirty || allowEvenIfDirty) {
                if (allowEvenIfDirty) {
                    this.okToChangePage();
                }
                next();
                return;
            }

            this.unsavedChangesNextRoute = next;
            this.showUnsavedChangesModal = true;
            return false;
        },

        watch: {},

        computed: {},

        methods: {
            //
            // The user doesn't want to stay on the page, even though there are unsaved changes.
            // We just route to the next page (we saved that during beforeRouteLeave).
            //
            okToChangePage() {
                this.settingsDirty = false;
                this.$store.commit('setSettingsDirty', false); // TODO: DELETE
                this.showUnsavedChangesModal = false;
                if (this.unsavedChangesNextRoute) {
                    this.unsavedChangesNextRoute();
                }
            },

            //
            // The user wants to stay on the settings page.  Make sure to turn off the
            // modal and set the current menu back to the settings.
            //
            stayOnSettingsPage() {
                this.showUnsavedChangesModal = false;
                this.$store.commit('setCurrentMenu', constants.ROUTE_USER_PROFILE);
            },
        },
    });
