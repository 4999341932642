// cSpell:disable
/*
 * Copyright 2019-2022 Reki LLC - All rights reserved.
 * File: config.ts
 * Project: rekitv
 */

import * as constants from '@/tsfiles/constants';

// Firebase configuration
interface FirebaseConfig {
    projectId: string;
    appId: string;
    apiKey: string;
    authDomain: string;
    readonly signInFlow: string;
}

// All configuration
interface ConfigData {
    readonly apiServer: string;
    runEnvironment: string;
    domainName: string;
    recaptchaSiteKey: string;
    branchioLink: string;
    segmentAnalyticsWriteKey: string;
    firebase: FirebaseConfig;
}

//
// Default configuration for dev.  Certain configs, especially firebase,
// are different for each environment.  We used to look at
// process.env.NODE_ENV, but that was either production or debug (dev).
// Production was the setting for docker built images.  How
// to have stage use dev firebase, but different domainName?  We
// also have the issue of ssl certs for stage and prod.  Trying to
// combine the nginx configs is too messy.
//
// The solution was to create a separate stage container registry image, which is almost
// identical to prod.  Stage and prod docker files will copy in the
// correct config ts file from ./config-overrides, putting it into
// config-env.ts.  This has an init function, which is called from main.ts.
// Having separate docker files and registry images, along with this config
// copying, solved the firebase and ssl issue.  I'm sure there's a better
// solution.
//
// tslint:disable-next-line
let config = {
    apiServer: '/',
    domainName: 'localhost',
    runEnvironment: 'dev',
    recaptchaSiteKey: '6LcoZ_wUAAAAAEWFsprm0JYISToqY9XFagoKemsy',
    // branchioLink: 'https://stagelink.reki.tv/a/key_live_ch5Mf9oJBCMuHzNyPa4axmnhqypfpCWX',
    branchioLink: 'https://stagelink.reki.tv/gen',
    segmentAnalyticsWriteKey: 'Vri5UF2g5DZrD85YUQ4qtewMVel2apGn',
    firebase: {
        projectId: 'rekitv-dev',
        appId: '1:1096114826324:web:1a59f8aac945f222ef11c1',
        apiKey: 'AIzaSyBlkzdMHRcGPHkFaUszg_TDwdQyHIHkx6M',
        authDomain: 'devauth.' + constants.COMPANY_DOMAIN,
        signInFlow: 'redirect',
    },
} as ConfigData;


export default config;


