
    import { PropOptions } from 'vue';
    import mixins from 'vue-typed-mixins';
    import VueConstants from '@/components/VueConstants';
    import * as constants from '@/tsfiles/constants';
    import { logInvalidParams } from '@/tsfiles/errorlog';
    import UserNameDisplay from '@/components/user/UserNameDisplay.vue';
    import Avatar from '@/components/Avatar.vue';
    import { TimelineList } from 'api';

    export default mixins(VueConstants).extend({
        name: 'TimelineListRow',

        components: {
            'url-avatar': Avatar,
            'user-name-display': UserNameDisplay,
        },

        props: {
            // List to display
            timelineList: {
                type: Object,
                required: true,
            } as PropOptions<TimelineList>,
        },

        data() {
            return {};
        },

        watch: {},

        computed: {
            getImageUrl(): string {
                if (!this.timelineList.list || !this.timelineList.list.owner) {
                    logInvalidParams(this.$options.name, 'getImageUrl');
                    return '';
                }

                if (this.timelineList.list.owner.cachedImageUrl && this.timelineList.list.owner.cachedImageUrl !== '') {
                    return this.timelineList.list.owner.cachedImageUrl;
                } else if (this.timelineList.list.owner.imageUrl && this.timelineList.list.owner.imageUrl !== '') {
                    return this.timelineList.list.owner.imageUrl;
                }

                return '';
            },
        },

        methods: {
            gotoList() {
                if (!this.timelineList.list || !this.timelineList.list.owner || !this.timelineList.list.listId) {
                    logInvalidParams(this.$options.name, 'gotoList');
                    return '';
                }

                this.$router.push({
                    name: constants.ROUTE_LIST_CONTENTS,
                    params: {
                        publicUrl: this.timelineList.list.owner.publicUrl as string,
                        listId: this.timelineList.list.listId.toString(),
                    },
                });
            },
        },
    });
