
    import Vue from 'vue';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import * as constants from '@/tsfiles/constants';
    import { Utils } from '@/tsfiles/utils';
    import * as analytics from '@/tsfiles/analytics';
    import { logInvalidParams, logInvalidResponse } from '@/tsfiles/errorlog';
    import { RecommendationUpdateEmit } from '@/tsfiles/interfaces';
    import ContentRowV2 from '@/components/content/ContentRowV2.vue';
    import UserNameDisplay from '@/components/user/UserNameDisplay.vue';
    import ListFansAndItems from '@/components/lists/ListFansAndItems.vue';
    import AddRecommendationModal from '@/components/content/AddRecommendationModal.vue';
    import RecommendationEdit from '@/components/content/RecommendationEditModal.vue';
    import ListAddRemoveContentModal from '@/components/lists/ListAddRemoveContentModal.vue';
    import RenameListModal from '@/components/lists/RenameListModal.vue';
    import ModalAlert from '@/generic-modals/ModalAlert.vue';
    import { ListService, List, GenericPageRetrieval, ContentService, ContentV2, UserRecommendation } from 'api';

    export default Vue.extend({
        name: 'ListContents',

        components: {
            'content-row': ContentRowV2,
            'user-name-display': UserNameDisplay,
            'list-fans-and-items': ListFansAndItems,
            'add-recommendation': AddRecommendationModal,
            'recommendation-edit': RecommendationEdit,
            'list-add-remove-content': ListAddRemoveContentModal,
            'rename-list': RenameListModal,
            'modal-alert': ModalAlert,
        },

        props: {
            publicUrl: {
                type: String,
                required: true,
            },

            listId: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                list: undefined as List | undefined,
                listContents: [] as ContentV2[],

                renameList: false,
                deleteList: false,

                addRecommendation: undefined as ContentV2 | undefined,
                editRecommendation: undefined as ContentV2 | undefined,
                deleteRecommendation: undefined as ContentV2 | undefined,
                listAddRemoveContent: undefined as ContentV2 | undefined,

                totalItems: 0,
                currentPage: 1,
                perPage: constants.RECOMMENDATIONS_PER_PAGE,
            };
        },

        watch: {},

        mounted() {
            this.fetchList();
            this.fetchListContents();
        },

        computed: {
            isMyList(): boolean {
                return this.$store.getters.isUsersPublicUrl(this.publicUrl);
            },
        },

        methods: {
            async fetchList() {
                try {
                    this.list = await ApiUtils.apiWrapper(ListService.getListById, parseInt(this.listId, 10));
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async fetchListContents() {
                try {
                    const ret = await ApiUtils.apiWrapper(ListService.getListContents, {
                        listId: parseInt(this.listId, 10),
                        paging: {
                            publicUrl: this.publicUrl,
                            pageNumber: this.currentPage,
                            numberOfItems: this.perPage,
                        } as GenericPageRetrieval,
                    });

                    if (ret.list) {
                        this.listContents = ret.list;
                        this.totalItems = ret.totalItemsIrregardlessOfPaging as number;
                    } else {
                        this.listContents = [] as ContentV2[];
                        this.totalItems = 0;
                    }
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            pageChanged(newPage: number) {
                this.currentPage = newPage;
                this.fetchListContents();
            },

            async followList() {
                if (!this.list) {
                    logInvalidParams(this.$options.name, 'followList');
                    return;
                }

                try {
                    await ApiUtils.apiWrapper(ListService.followList, this.list.listId);
                    this.fetchList();
                } catch (error: any) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async unfollowList() {
                if (!this.list) {
                    logInvalidParams(this.$options.name, 'unfollowList');
                    return;
                }

                try {
                    await ApiUtils.apiWrapper(ListService.unfollowList, this.list.listId);
                    this.fetchList();
                } catch (error: any) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async duplicateList() {
                if (!this.list) {
                    logInvalidParams(this.$options.name, 'duplicateList');
                    return;
                }

                try {
                    await ApiUtils.apiWrapper(ListService.duplicateList, this.list.listId);

                    alert('Your Lists now contains a copy of:\r\n\r\n ' + this.list.name);
                } catch (error: any) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async saveListRename(newName: string) {
                if (!this.list || !newName || newName.trim() === '') {
                    logInvalidParams(this.$options.name, 'saveListRename');
                    return;
                }

                //
                // Only update if name is different
                //
                if (this.list.name !== newName.trim()) {
                    try {
                        const ret = await ApiUtils.apiWrapper(ListService.updateList, {
                            listId: this.list.listId,
                            name: newName.trim(),
                        });

                        if (ret && ret.listId === this.list.listId) {
                            this.list.name = ret.name;
                        }

                        this.renameList = false;
                    } catch (error: any) {
                        Utils.CommonErrorHandler(error);
                    }
                }
            },

            async saveNewRecommendation(data: any) {
                if (!this.addRecommendation) {
                    logInvalidParams(this.$options.name, 'saveNewRecommendation');
                    return;
                }

                const updatedText = data.comment;
                const updatedRating = data.rating;

                // Comment can be empty, but not rating
                if (!this.addRecommendation || updatedRating === 0) {
                    logInvalidParams(this.$options.name, 'saveNewRecommendation');
                    return;
                }

                try {
                    const ret = await ApiUtils.apiWrapper(ContentService.addRecommendation, {
                        contentId: this.addRecommendation.contentId,
                        comment: updatedText,
                        rating: updatedRating,
                    });

                    if (!ret || !ret.recommendedBy || ret.recommendedBy.length !== 1) {
                        logInvalidResponse(this.$options.name, 'saveNewRecommendation');
                        return;
                    }

                    this.fetchListContents();

                    analytics.logAppInteraction(
                        analytics.ANALYTICS_ACTION_NEW_RECOMMENDATION,
                        this.addRecommendation.contentPublicUrl,
                    );

                    this.addRecommendation = undefined as ContentV2 | undefined;
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            //
            // The authenticated user is editing their own reki
            //
            async saveRecommendationUpdate(data: any) {
                const updatedText = data.comment;
                const updatedRating = data.rating;

                // Comment can be empty, but not rating
                if (!this.editRecommendation || updatedRating === 0) {
                    logInvalidParams(this.$options.name, 'saveRecommendationUpdate');
                    return;
                }

                try {
                    const ret = await ApiUtils.apiWrapper(ContentService.updateRecommendation, {
                        contentId: this.editRecommendation.contentId,
                        comment: updatedText,
                        rating: updatedRating,
                    });

                    if (!ret || !ret.recommendedBy || ret.recommendedBy.length !== 1) {
                        logInvalidResponse(this.$options.name, 'saveRecommendationUpdate');
                        return;
                    }

                    this.fetchListContents();

                    analytics.logAppInteraction(
                        analytics.ANALYTICS_ACTION_UPDATE_RECOMMENDATION,
                        this.editRecommendation.contentPublicUrl,
                    );

                    this.editRecommendation = undefined as ContentV2 | undefined;
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            //
            // When the lower level components do like or unlike, or reply, we eventually get
            // the 'recommendation-updated' emit, with the data RecommendationUpdateEmit.  That contains the
            // new recommendation, and, if defined, the new comments totals.
            //
            recommendationUpdated(idx: number, data: RecommendationUpdateEmit) {
                if (!this.listContents || idx < 0 || idx >= this.listContents.length || !data || !data.recommendation) {
                    logInvalidParams(this.$options.name, 'recommendationUpdated');
                    return;
                }

                if (this.listContents[idx].authedUserRecommendation) {
                    Vue.set(
                        this.listContents[idx].authedUserRecommendation as UserRecommendation,
                        'totalCommentLikes',
                        data.recommendation.totalCommentLikes,
                    );

                    Vue.set(
                        this.listContents[idx].authedUserRecommendation as UserRecommendation,
                        'authedUserLikedComment',
                        data.recommendation.authedUserLikedComment,
                    );
                } else {
                    if (this.listContents[idx].latestRecommendation) {
                        Vue.set(
                            this.listContents[idx].latestRecommendation as UserRecommendation,
                            'totalCommentLikes',
                            data.recommendation.totalCommentLikes,
                        );

                        Vue.set(
                            this.listContents[idx].latestRecommendation as UserRecommendation,
                            'authedUserLikedComment',
                            data.recommendation.authedUserLikedComment,
                        );
                    }
                }

                if (data.totalComments) {
                    Vue.set(this.listContents[idx], 'totalComments', data.totalComments);
                }

                if (data.totalFolloweeComments) {
                    Vue.set(this.listContents[idx], 'totalFolloweeComments', data.totalFolloweeComments);
                }
            },

            getDeleteListTitle(): string {
                if (!this.list) {
                    logInvalidParams(this.$options.name, 'getDeleteListTitle');
                    return '';
                }

                return 'Are you sure you want to delete list: <br><br> <b>' + this.list.name + '</b></br><br>';
            },

            async okToDeleteList() {
                if (!this.list) {
                    logInvalidParams(this.$options.name, 'okToDeleteList');
                    return;
                }

                try {
                    await ApiUtils.apiWrapper(ListService.deleteList, this.list.listId);

                    // Go back
                    this.$router.go(-1);
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            getDeleteRecommendationTitle(): string {
                if (!this.deleteRecommendation) {
                    logInvalidParams(this.$options.name, 'getDeleteRecommendationTitle');
                    return '';
                }

                return (
                    'Are you sure you want to delete your Reki: <br><br> <b>' +
                    this.deleteRecommendation.name +
                    '</b></br><br>'
                );
            },

            async okToDeleteRecommendation() {
                if (!this.deleteRecommendation) {
                    logInvalidParams(this.$options.name, 'okToDeleteRecommendation');
                    return;
                }

                try {
                    const deletedPublicUrl = this.deleteRecommendation.contentPublicUrl;

                    await ApiUtils.apiWrapper(
                        ContentService.deleteRecommendation,
                        this.deleteRecommendation.contentId as number,
                    );

                    this.fetchListContents();

                    analytics.logAppInteraction(analytics.ANALYTICS_ACTION_DELETE_RECOMMENDATION, deletedPublicUrl);
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            gotoDetails(item: ContentV2) {
                this.$router.push({
                    name: constants.ROUTE_CONTENT_DETAILS,
                    params: { contentPublicUrl: item.contentPublicUrl as string },
                });
            },

            gotoComments(item: ContentV2) {
                this.$router.push({
                    name: constants.ROUTE_CONTENT_COMMENTS,
                    params: { contentPublicUrl: item.contentPublicUrl as string },
                });
            },
        },
    });
