
import Vue, { PropOptions } from 'vue';
import { logInvalidParams } from '@/tsfiles/errorlog';
import * as constants from '@/tsfiles/constants';
import * as analytics from '@/tsfiles/analytics';
import { ApiUtils } from '@/tsfiles/apiutils';
import { Utils } from '@/tsfiles/utils';
import { PageNavigationData } from '@/tsfiles/interfaces';
import Avatar from '@/components/Avatar.vue';
import DotAnimation from '@/components/uiutils/DotAnimation.vue';
import { SmartyService, List, User, SmartyFilterRequest, Content, SharedConstants, Crew } from 'api';

export default Vue.extend({
    name: 'SplitSearch',

    components: {
        'url-avatar': Avatar,
        'dot-animation': DotAnimation,
    },

    props: {},

    data() {
        return {
            inputData: '',
            currentTab: 0,
            currentNavigation: { page: constants.ROUTE_SEARCH } as PageNavigationData,

            //
            // Server calls are async, and they are not guaranteed to come back in order.
            //
            sendSequence: 0,
            lastProcessed: 0,

            active: false,

            movies: [] as Content[],
            moviesBold: [] as string[],
            shows: [] as Content[],
            showsBold: [] as string[],
            lists: [] as List[],
            listsBold: [] as string[],
            people: [] as User[],
            peopleBold: [] as string[],
            cast: [] as User[],
            castBold: [] as string[],

            cachedAiQueryString: '',
            aiContent: [] as Content[],
            waitingForAiApi: false,
        };
    },

    watch: {},

    mounted() {
        const nav = this.$store.getters.getPageNavigation(constants.ROUTE_SEARCH);
        if (nav && (nav.tab || nav.customData)) {
            this.$nextTick(() => {
                // Set saved data first, before setting tab
                if (nav.customData) {
                    if (nav.customData.value) { // ai data
                        this.inputData = nav.customData.value;
                        this.cachedAiQueryString = this.inputData;
                        this.aiContent = nav.customData.list;
                    } else {
                        this.inputData = nav.customData;
                    }
                }

                if (nav.tab) {
                    this.currentTab = nav.tab;
                    this.currentNavigation.tab = nav.tab;
                }

                this.inputChanging();
            });
        }
    },

    beforeDestroy() {
        this.$store.commit('setPageNavigation', this.currentNavigation);
    },

    computed: {
        uniqueId(): string {
            return Math.random().toString(36).substring(2, 15);
        },

        inputValid(): boolean {
            return this.inputData !== '';
        },
    },

    methods: {
        //
        // Handle changes to the input.  We separate input change and keypress, since keypress on
        // mobile safari wouldn't set the inputData properly, even with a nextTick.
        //
        inputChanging() {
            const val = this.inputData.trim();
            switch (this.currentTab) {
                case 0:
                    this.getContent(val, SharedConstants.SMARTY_FILTER_CONTENT_DATA_MOVIES);
                    break;
                case 1:
                    this.getContent(val, SharedConstants.SMARTY_FILTER_CONTENT_DATA_SHOWS);
                    break;
                case 2:
                    this.getPeople(val);
                    break;
                case 3:
                    this.getLists(val);
                    break;
                case 4:
                    this.getCast(val);
                    break;
                case 5:
                    // We cannot call getAiContent here.  It takes
                    // a long time to process.  The user will need
                    // to hit enter to get the process started.
                    break;
            }

            // Only do this if not on the ai beta tab
            if (this.currentTab !== 5) {
                this.currentNavigation.customData = val;
            }
        },

        //
        // When a tab is activated, do a smarty search for the current inputData, if there is any.
        //
        activateTab(event: any) {
            this.currentNavigation.tab = event;

            switch (event) {
                case 0:
                    this.getContent(this.inputData, SharedConstants.SMARTY_FILTER_CONTENT_DATA_MOVIES);
                    break;
                case 1:
                    this.getContent(this.inputData, SharedConstants.SMARTY_FILTER_CONTENT_DATA_SHOWS);
                    break;
                case 2:
                    this.getPeople(this.inputData);
                    break;
                case 3:
                    this.getLists(this.inputData);
                    break;
                case 4:
                    this.getCast(this.inputData);
                    break;
                case 5:
                    this.getAiContent(this.inputData);
                    break;
            }
        },

        //
        // Vetur was complaining when in the template even when checking for an
        // undefined owner or imageUrl.
        //
        getListOwnerImage(idx: number): string {
            if (idx < 0 || idx >= this.lists.length) {
                logInvalidParams(this.$options.name, 'getListOwnerImage');
                return '';
            }

            const owner = this.lists[idx].owner;

            if (owner) {
                if (owner.cachedImageUrl) {
                    return owner.cachedImageUrl;
                } else if (owner.imageUrl) {
                    return owner.imageUrl;
                }
            }

            return '';
        },

        clearContent() {
            this.moviesBold = [] as string[];
            this.showsBold = [] as string[];
            this.listsBold = [] as string[];
            this.peopleBold = [] as string[];
            this.castBold = [] as string[];

            // Don't reset aiContent here, since it gets cached to
            // reduce calls to the server when the search string is the same.
        },

        //
        // Do not reset processingClickOnDropdown, since that would screw up the
        // mousedown/blur handling.
        //
        clearAllData(resetItemId: boolean) {
            this.clearContent();
            // this.active = false;
            // this.keyboardActiveIndex = -1;

            // if (resetItemId) {
            //    this.uniqueItemId = undefined as number | undefined;
            // }
        },

        // TODO: RegExp will error out if user types in parens, etc.
        boldText(input: string, fullStr: string): string {
            const boldRes = fullStr;
            const re = new RegExp('(' + input + ')', 'gi');
            const boldText = boldRes.replace(re, '<b>$&</b>');
            return '<span class="d-inline-block text-truncate">' + boldText + '</span>';
        },

        //
        // We put in a form, even though it's not needed, because of Chrome autocomplete.
        // If the form stays, we need to capture onSubmit, otherwise the entire page
        // will refresh when 'enter' is hit.
        //
        onSubmit(evt: any) {
            if (this.currentTab === 5) {
                this.getAiContent(this.inputData);
            }

            evt.preventDefault();
        },

        async getContent(inputStr: string, type: string) {
            const localSequence = this.sendSequence;
            this.sendSequence++;
            try {
                // V3 is elastic search.  It hasn't been maintained, so going
                // back to V2, which is just the database
                const data = await ApiUtils.apiWrapper(SmartyService.getContentV2, {
                    chars: inputStr,
                    filterType: SharedConstants.SMARTY_FILTER_CONTENT_TYPE,
                    filterData: type,
                } as SmartyFilterRequest);

                // Prevent old queries from overwriting newer ones.
                if (localSequence < this.lastProcessed) {
                    return; // Do nothing
                }
                this.lastProcessed = localSequence;

                const list = data.results;
                if (list !== null && list !== undefined && list.length > 0) {
                    this.clearContent();

                    if (type === 'movies') {
                        this.movies = Utils.deepCopy(list);
                    } else {
                        this.shows = Utils.deepCopy(list);
                    }

                    for (const res of list) {
                        let displayName = '';
                        if (res.name) {
                            displayName = res.name;
                        }

                        let addOn = '';
                        if (res.year) {
                            addOn += ' (' + res.year + ')';
                        }

                        if (displayName !== '') {
                            if (type === 'movies') {
                                this.moviesBold.push(this.boldText(inputStr, displayName + addOn));
                            } else {
                                this.showsBold.push(this.boldText(inputStr, displayName + addOn));
                            }
                        } else {
                            // ERROR
                        }
                    }
                    this.active = true;
                } else {
                    this.clearAllData(true);
                }
            } catch (error) {
                Utils.CommonErrorHandler(error);
            }
            return;
        },

        async getLists(inputStr: string) {
            const localSequence = this.sendSequence;
            this.sendSequence++;
            try {
                // V2 is elastic search.  It hasn't been maintained, so going
                // back to V1, which is just the database
                const data = await ApiUtils.apiWrapper(SmartyService.getList, {
                    chars: inputStr,
                } as SmartyFilterRequest);

                // Prevent old queries from overwriting newer ones.
                if (localSequence < this.lastProcessed) {
                    return; // Do nothing
                }
                this.lastProcessed = localSequence;

                const list = data.results;
                if (list !== null && list !== undefined && list.length > 0) {
                    this.clearContent();

                    this.lists = Utils.deepCopy(list);

                    for (const res of list) {
                        let displayName = '';
                        if (res.name) {
                            displayName = res.name;
                        }

                        let addOn = '';
                        if (res.publicUrl) {
                            addOn += ' (@' + res.publicUrl + ')';
                        }

                        if (displayName !== '') {
                            this.listsBold.push(this.boldText(inputStr, displayName + addOn));
                        } else {
                            // ERROR
                        }
                    }
                    this.active = true;
                } else {
                    this.clearAllData(true);
                }
            } catch (error) {
                Utils.CommonErrorHandler(error);
            }
            return;
        },

        async getPeople(inputStr: string) {
            const localSequence = this.sendSequence;
            this.sendSequence++;
            try {
                // V3 is elastic search.  It hasn't been maintained, so going
                // back to V2, which is just the database
                const data = await ApiUtils.apiWrapper(SmartyService.getPeopleV2, {
                    chars: inputStr,
                } as SmartyFilterRequest);

                // Prevent old queries from overwriting newer ones.
                if (localSequence < this.lastProcessed) {
                    return; // Do nothing
                }
                this.lastProcessed = localSequence;

                const list = data.results;
                if (list !== null && list !== undefined && list.length > 0) {
                    this.clearContent();

                    this.people = Utils.deepCopy(list);

                    for (const res of list) {
                        let displayName = '';
                        if (res.name) {
                            displayName = res.name;
                        }

                        let addOn = '';
                        if (displayName === '') {
                            displayName = '@' + res.publicUrl;
                        } else if (res.publicUrl) {
                            addOn += ' (@' + res.publicUrl + ')';
                        }

                        if (displayName !== '') {
                            this.peopleBold.push(this.boldText(inputStr, displayName + addOn));
                        } else {
                            // ERROR
                        }
                    }
                    this.active = true;
                } else {
                    this.clearAllData(true);
                }
            } catch (error) {
                Utils.CommonErrorHandler(error);
            }
            return;
        },

        async getCast(inputStr: string) {
            const localSequence = this.sendSequence;
            this.sendSequence++;
            try {
                const data = await ApiUtils.apiWrapper(SmartyService.getCrew, {
                    chars: inputStr,
                } as SmartyFilterRequest);

                // Prevent old queries from overwriting newer ones.
                if (localSequence < this.lastProcessed) {
                    return; // Do nothing
                }
                this.lastProcessed = localSequence;

                const list = data.results;
                if (list !== null && list !== undefined && list.length > 0) {
                    this.clearContent();

                    this.cast = Utils.deepCopy(list);

                    for (const res of list) {
                        if (res.name !== '') {
                            this.castBold.push(this.boldText(inputStr, res.name));
                        } else {
                            // ERROR
                        }
                    }
                    this.active = true;
                } else {
                    this.clearAllData(true);
                }
            } catch (error) {
                Utils.CommonErrorHandler(error);
            }
            return;
        },

        async getAiContent(inputStr: string) {

            // Only signed in users
            if (!this.$store.getters.isSignedIn) {
                return;
            }

            //
            // Don't do anything if the query string is the same
            //
            if (inputStr === this.cachedAiQueryString) {
                return;
            }

            // If still processing, don't try another call.
            if (this.waitingForAiApi) {
                return;
            }

            try {
                this.aiContent = [] as Content[];
                this.cachedAiQueryString = inputStr;
                this.waitingForAiApi = true;

                const data = await ApiUtils.apiWrapper(SmartyService.getUserQueryRecommendations, {
                    value: inputStr,
                });

                const list = data.results;
                let listSize = 0;
                if (list !== null && list !== undefined && list.length > 0) {
                    this.clearContent();
                    this.aiContent = Utils.deepCopy(list);
                    this.active = true;
                    listSize = list.length;
                }

                const analyticsData = {
                    list_size: listSize,
                };

                analytics.logAppInteraction(
                    analytics.ANALYTICS_ACTION_SEARCH,
                    this.inputData,
                    analyticsData
                );

                // Save pageNavigation custom data, which is used if the user
                // leaves this page then comes back
                this.currentNavigation.customData = {
                    value: this.cachedAiQueryString,
                    list: Utils.deepCopy(this.aiContent),
                };


                this.waitingForAiApi = false;
            } catch (error) {
                this.waitingForAiApi = false;
                Utils.CommonErrorHandler(error);
            }
        },


        gotoContentDetails(item: Content) {
            if (!item.contentId) {
                logInvalidParams(this.$options.name, 'gotoContentDetails');
                return;
            }

            // If no contentPublicUrl, use contentId.
            let publicUrl = item.contentPublicUrl;
            if (!publicUrl) {
                publicUrl = item.contentId.toString();
            }

            this.$router.push({
                name: constants.ROUTE_CONTENT_DETAILS,
                params: { contentId: item.contentId.toString(), contentPublicUrl: publicUrl as string },
            });
        },

        gotoList(list: List) {
            if (!list.listId || !list.owner || !list.owner.publicUrl) {
                logInvalidParams(this.$options.name, 'gotoList');
                return;
            }

            this.$router.push({
                name: constants.ROUTE_LIST_CONTENTS,
                params: { publicUrl: list.owner.publicUrl as string, listId: list.listId.toString() },
            });
        },

        gotoUser(user: User) {
            if (!user.id) {
                logInvalidParams(this.$options.name, 'gotoUser');
                return;
            }

            this.$router.push({
                name: constants.ROUTE_USER_HOME,
                params: { publicUrl: user.publicUrl as string },
            });
        },

        gotoCast(crew: Crew) {
            if (!crew.crewId) {
                logInvalidParams(this.$options.name, 'gotoCast');
                return;
            }

            this.$router.push({ name: constants.ROUTE_CREW_CONTENT, params: { crewId: crew.crewId.toString() } });
        },
    },
});
