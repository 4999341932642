import { render, staticRenderFns } from "./ChannelFlaggedAlert.vue?vue&type=template&id=2b24f2b7&scoped=true&"
import script from "./ChannelFlaggedAlert.vue?vue&type=script&lang=ts&"
export * from "./ChannelFlaggedAlert.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b24f2b7",
  null
  
)

export default component.exports