
    import mixins from 'vue-typed-mixins';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { Utils } from '@/tsfiles/utils';
    import * as constants from '@/tsfiles/constants';
    import { RecommendationUpdateEmit, PageNavigationData } from '@/tsfiles/interfaces';
    import { logInvalidParams } from '@/tsfiles/errorlog';
    import VueConstants from '@/components/VueConstants';
    import CommentsPage from '@/components/content/CommentsPage.vue';
    import Avatar from '@/components/Avatar.vue';
    import StarRating from '@/components/uiutils/StarRating.vue';
    import ContentRecommendation from '@/components/content/ContentRecommendationV2.vue';
    import ContentImage from '@/components/content/ContentImage.vue';
    import { ContentService, ContentV2, UserRecommendation } from 'api';

    export default mixins(VueConstants).extend({
        name: 'ContentComments',

        components: {
            'comments-page': CommentsPage,
            'url-avatar': Avatar,
            'star-rating': StarRating,
            'content-recommendation': ContentRecommendation,
            'content-image': ContentImage,
        },

        props: {
            // Display comments for this content
            contentPublicUrl: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                currentTab: 0,
                totalWorldComments: 0,
                totalFolloweeComments: 0,

                content: undefined as ContentV2 | undefined,

                recommendationChangedTellFolloweesTab: undefined as UserRecommendation | undefined,
                recommendationChangedTellWorldTab: undefined as UserRecommendation | undefined,

                currentNavigation: { page: constants.ROUTE_CONTENT_COMMENTS } as PageNavigationData,
            };
        },

        watch: {},

        mounted() {
            this.getContentId();

            //
            // If page navigation contains data, process.
            //
            const nav = this.$store.getters.getPageNavigation(constants.ROUTE_CONTENT_COMMENTS);
            if (nav && nav.tab) {
                this.$nextTick(() => {
                    this.currentTab = nav.tab;
                    this.currentNavigation.tab = nav.tab;
                });
            }
        },

        beforeDestroy() {
            this.$store.commit('setPageNavigation', this.currentNavigation);
        },

        computed: {
            getGenreStr(): string {
                if (!this.content || !this.content.genreJson) {
                    return '';
                }

                const data = JSON.parse(this.content.genreJson);
                if (!data.genres || data.genres.length === 0) {
                    return '';
                }

                return data.genres.join(', ');
            },
        },

        methods: {
            async getContentId() {
                try {
                    const ret = await ApiUtils.apiWrapper(
                        ContentService.getContentIdByPublicUrl,
                        this.contentPublicUrl,
                    );

                    if (ret && ret.id) {
                        this.content = await ApiUtils.apiWrapper(ContentService.getContentDetailsV2, {
                            contentId: ret.id,
                            publicUrl: this.$router.currentRoute.params.publicUrl,
                        });
                    }
                } catch (error: any) {
                    if (error.response.status !== 404) {
                        Utils.CommonErrorHandler(error);
                    }
                }
            },

            //
            //
            //
            recommendationUpdated(from: string, data: RecommendationUpdateEmit) {
                if (from === '' || !data.type || !data.recommendation) {
                    logInvalidParams(this.$options.name, 'recommendationUpdated');
                    return;
                }

                if (data.totalFolloweeComments) {
                    this.totalFolloweeComments = data.totalFolloweeComments;
                }

                if (data.totalComments) {
                    this.totalWorldComments = data.totalComments;
                }

                //
                // The followees or world tab has a change to a recommendation.  Tell the other
                // component to look and see if it needs to replace the recommendation if it's
                // in the component's list.  We send back the ReplyToCommentResponse, since it
                // contains the new values for followee comments and world comments.
                //
                if (from === 'fromFollowees') {
                    this.recommendationChangedTellWorldTab = data.recommendation;
                } else if (from === 'fromWorld') {
                    this.recommendationChangedTellFolloweesTab = data.recommendation;
                }

                this.$emit('recommendation-updated', data);
            },

            gotoDetails() {
                if (!this.content) {
                    logInvalidParams(this.$options.name, 'gotoDetails');
                    return;
                }

                this.$router.push({
                    name: constants.ROUTE_CONTENT_DETAILS,
                    params: { contentPublicUrl: this.content.contentPublicUrl as string },
                });
            },
        },
    });
