
    import Vue from 'vue';
    import * as constants from '@/tsfiles/constants';
    import UserProfile from '@/components/user/UserProfile.vue';

    export default Vue.extend({
        name: 'PublicProfile',

        components: {
            'user-profile': UserProfile,
        },

        props: {},

        data() {
            return {
                saveDS: false,
            };
        },

        watch: {
            '$route.params.publicUrl': {
                immediate: true,
                handler(newVal, oldVal) {
                    if (newVal && newVal !== oldVal) {
                        this.checkRoute(this.$router.currentRoute.name as string, newVal);
                    }
                },
            },
        },

        //
        // If we are getting to the profile page from outside, such as a different site,
        // We will save the DS part of adChannel with this user's ID.  We only have the
        // publicUrl, so we need to pass along a variable telling the child component
        // to set the adChannel value.
        //
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                // access to component public instance via `vm`

                if (!from.name || from.name === '') {
                    // @ts-ignore
                    vm.saveDS = true;
                }
            });
        },

        computed: {},

        methods: {
            checkRoute(routeName: string, publicUrl: string) {
                //
                // Only change the menu if it is the authed user's own page; otherwise,
                // no menu item is selected.
                //
                const isAuthedUser = this.$store.getters.isSignedIn && this.$store.getters.isUsersPublicUrl(publicUrl);
                let replaceRoute = false;

                if (isAuthedUser) {
                    //
                    // If ROUTE_USER_HOME, meaning there is nothing after @{{publicUrl}}, send to the
                    // current menu route (could be profile, etc.).  If the current menu is
                    // empty, default to ROUTE_USER_TIMELINE
                    //
                    if (routeName === constants.ROUTE_USER_HOME) {
                        if (this.$store.getters.getCurrentMenu === '') {
                            this.$store.commit('setCurrentMenu', constants.ROUTE_USER_TIMELINE);
                        }
                        replaceRoute = true;
                    } else {
                        this.$store.commit('setCurrentMenu', routeName);
                    }

                    // Push to desired route based on menu, unless already there.
                    if (replaceRoute) {
                        this.$router.replace({
                            name: this.$store.getters.getCurrentMenu,
                            params: { publicUrl },
                        });
                    }
                } // else {
                //
                // If not the authed user, set the DS adChannel info if not already set.
                //
                // const adChannel = this.$store.getters.getAdChannel;
                // if (!adChannel.downloadSource || adChannel.downloadSource === '') {
                //   this.$store.commit('setAdChannel', {
                //        downloadSource: publicUrl,
                //    });
                // }
                // }
            },
        },
    });
