
    import Vue from 'vue';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { Utils } from '@/tsfiles/utils';
    import { ListService, Lists, List, ContentListRequest, SharedConstants } from 'api';

    export default Vue.extend({
        name: 'ListAddRemoveContentModal',

        components: {},

        props: {
            contentName: {
                type: String,
                required: true,
            },

            contentId: {
                type: Number,
                required: true,
            },
        },

        data() {
            return {
                lists: [] as List[],
                origSelected: [] as any[],
                selectedLists: [] as any[],
            };
        },

        watch: {
            contentId: {
                immediate: true,
                handler(newVal: number, oldVal: number) {
                    if (newVal && newVal !== oldVal) {
                        this.fetchLists();
                    }
                },
            },
        },

        computed: {
            getModalTitle(): string {
                return 'List Add/Remove: ' + this.contentName;
            },

            //
            // Since consumed and consumption lists are mutual exclusive for a specific content
            // item, those 2 lists are displayed at the top, the all others below a line.  The might
            // help to reduce confusion on why selecting one checkbox can deselect another.  Could
            // probably use radios, but then how does the user deselect the currently selected one?  It
            // would need to be done manually maybe...
            //
            getSpecialPredefined(): List[] {
                if (this.lists.length === 0) {
                    return this.lists;
                }

                return this.lists.filter((list) => {
                    return (
                        list.predefinedName &&
                        (list.predefinedName === SharedConstants.PREDEFINED_CONSUMED_LIST ||
                            list.predefinedName === SharedConstants.PREDEFINED_CONSUMPTION_LIST)
                    );
                });
            },

            //
            // Return all lists that are not consumed or consumption.
            //
            getAllOtherLists(): List[] {
                if (this.lists.length === 0) {
                    return this.lists;
                }

                return this.lists.filter((list) => {
                    return (
                        !list.predefinedName ||
                        (list.predefinedName !== SharedConstants.PREDEFINED_CONSUMED_LIST &&
                            list.predefinedName !== SharedConstants.PREDEFINED_CONSUMPTION_LIST)
                    );
                });
            },

            //
            // Return true if the Save button should be disabled.
            //
            disableSubmit(): boolean {
                let idx = 0;
                for (const list of this.lists) {
                    if (this.selectedLists[idx]) {
                        if (!this.origSelected[idx]) {
                            return false;
                        }
                    } else {
                        if (this.origSelected[idx]) {
                            return false;
                        }
                    }

                    idx++;
                }

                return true;
            },
        },

        methods: {
            disableTop10(list: List, idx: number): boolean {
                return (
                    list.predefinedName !== undefined &&
                    list.predefinedName === SharedConstants.PREDEFINED_TOP10_LIST &&
                    list.numContentItems !== undefined &&
                    list.numContentItems >= 10 &&
                    !this.selectedLists[idx]
                );
            },

            async fetchLists() {
                try {
                    const lists = await ApiUtils.apiWrapper(ListService.getUserLists, this.$store.getters.getPublicUrl);

                    this.lists = [] as List[];
                    this.origSelected = [] as any[];
                    this.selectedLists = [] as any[];

                    if (!lists) {
                        return;
                    }

                    if (lists.predefinedList) {
                        for (const list of lists.predefinedList) {
                            this.lists.push(list);
                        }
                    }

                    if (lists.userList) {
                        for (const list of lists.userList) {
                            this.lists.push(list);
                        }
                    }

                    //
                    // We need to figure out which items in 'lists' should have
                    // their checkbox selected by default.  See which lists
                    // contain the content.
                    //
                    const containsContent = await ApiUtils.apiWrapper(
                        ListService.getListsContainingContent,
                        this.contentId,
                    );

                    if (containsContent.listIds && containsContent.listIds.length > 0) {
                        let idx = 0;
                        for (const list of this.lists) {
                            for (const selected of containsContent.listIds) {
                                if (list.listId === selected) {
                                    Vue.set(this.selectedLists, idx, true);
                                    break;
                                }
                            }

                            idx++;
                        }
                    }

                    this.origSelected = [...this.selectedLists];
                } catch (error: any) {
                    Utils.CommonErrorHandler(error);
                }
            },

            //
            // See which lists are newly selected to add content to that list.
            // See which lists that had the content are no longer selected.
            //
            async submit() {
                try {
                    let idx = 0;
                    for (const list of this.lists) {
                        if (this.selectedLists[idx]) {
                            if (!this.origSelected[idx]) {
                                await ApiUtils.apiWrapper(ListService.addContentToList, {
                                    listId: list.listId,
                                    contentId: this.contentId,
                                } as ContentListRequest);
                            }
                        } else {
                            if (this.origSelected[idx]) {
                                await ApiUtils.apiWrapper(ListService.deleteContentFromList, {
                                    listId: list.listId,
                                    contentId: this.contentId,
                                } as ContentListRequest);
                            }
                        }

                        idx++;
                    }

                    this.$emit('closed');
                } catch (error: any) {
                    Utils.CommonErrorHandler(error);
                }
            },

            //
            // A specific piece of content can be in Queue or Watched, but not both.
            // If the Watched checkbox is selected, unselect Queue.  If the Queue checkbox
            // is selected, unselect Watched.
            //
            checkboxChanged(item: List) {
                // Skip if not consumption or consumed list
                if (
                    item.predefinedName !== SharedConstants.PREDEFINED_CONSUMED_LIST &&
                    item.predefinedName !== SharedConstants.PREDEFINED_CONSUMPTION_LIST
                ) {
                    return;
                }

                // Get idx for consumed and consumption lists
                let consumedId = 0;
                let consumptionId = 0;
                let idx = 0;
                for (const list of this.lists) {
                    if (list.predefinedName === SharedConstants.PREDEFINED_CONSUMED_LIST) {
                        consumedId = idx;
                    } else if (list.predefinedName === SharedConstants.PREDEFINED_CONSUMPTION_LIST) {
                        consumptionId = idx;
                    }

                    idx++;
                }

                if (item.predefinedName === SharedConstants.PREDEFINED_CONSUMED_LIST) {
                    Vue.set(this.selectedLists, consumptionId, false);
                } else {
                    Vue.set(this.selectedLists, consumedId, false);
                }
            },
        },
    });
