
    import Vue, { PropOptions } from 'vue';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { Utils } from '@/tsfiles/utils';
    import { logInvalidParams } from '@/tsfiles/errorlog';
    import * as constants from '@/tsfiles/constants';
    import UserNameDisplay from '@/components/user/UserNameDisplay.vue';
    import SingleFriend from '@/components/user/SingleFriend.vue';
    import { User, UserService, GenericPageRetrieval } from 'api';

    export default Vue.extend({
        name: 'ListOfFollowers',

        components: {
            'user-name-display': UserNameDisplay,
            'single-friend': SingleFriend,
        },

        props: {
            user: {
                type: Object,
                required: true,
            } as PropOptions<User>,
        },

        data() {
            return {
                authedUser: false,
                users: [] as User[],
                totalItems: 0,
                currentPage: 1,
                perPage: constants.FOLLOWEES_PER_PAGE,
            };
        },

        watch: {},

        mounted() {
            this.fetchFollowers();
            this.authedUser = this.$store.getters.isUsersPublicUrl(this.user.publicUrl);
        },

        computed: {},

        methods: {
            async fetchFollowers() {
                const ret = await ApiUtils.apiWrapper(UserService.getFollowers, {
                    publicUrl: this.user.publicUrl,
                    pageNumber: this.currentPage,
                    numberOfItems: this.perPage,
                } as GenericPageRetrieval);

                if (ret && ret.list) {
                    this.users = ret.list;
                    this.totalItems = ret.totalItemsIrregardlessOfPaging as number;
                }
            },

            pageChanged(newPage: number) {
                this.currentPage = newPage;
                this.fetchFollowers();
            },

            gotoPerson(person: User) {
                this.$router.push({
                    name: constants.ROUTE_USER_HOME,
                    params: { publicUrl: person.publicUrl as string },
                });
            },
        },
    });
