import { render, staticRenderFns } from "./UserNameDisplay.vue?vue&type=template&id=652a5aa7&scoped=true&"
import script from "./UserNameDisplay.vue?vue&type=script&lang=ts&"
export * from "./UserNameDisplay.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "652a5aa7",
  null
  
)

export default component.exports