
    import mixins from 'vue-typed-mixins';
    import { mapGetters } from 'vuex';
    import { log } from '@/tsfiles/errorlog';
    import firebase from 'firebase/app';
    import * as firebaseui from 'firebaseui';
    import * as analytics from '@/tsfiles/analytics';
    import { getAdChannelQueryParams } from '@/tsfiles/adchannel';
    import { SigninStatus } from '@/tsfiles/enums';
    import * as constants from '@/tsfiles/constants';
    import store from '@/tsfiles/store';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { Utils } from '@/tsfiles/utils';
    import config from '@/config';
    import { AuthService } from 'api';
    import { afterIdentityKnown } from '@/tsfiles/router';
    import VueConstants from '@/components/VueConstants';

    export default mixins(VueConstants).extend({
        name: 'FirebaseSignin',

        props: {
            // What should header text be?
            headerText: {
                type: String,
                required: false,
                default: 'SIGN IN',
            },

            // What should body text be?
            bodyText: {
                type: String,
                required: false,
                default: '',
            },

            // What is the starting page?
            accountCreation: {
                type: Boolean,
                required: false,
                default: false,
            },
        },

        data() {
            return {
                showCreateAccount: this.accountCreation,
                signingIn: false,

                // set in beforeRouteEnter, below
                referringUrl: '',
            };
        },

        watch: {
            //
            // signinState changed.  Go home ONLY if signed in and not in the
            // middle of signin. Theoretically we would never get here if
            // signin completed, since the user is routed to their home
            // page (or the desired redirect), but might as well check anyway.
            //
            // See more comments at mapGetters computed function.
            //
            signinState: {
                immediate: true,
                handler(newVal, oldVal) {
                    if (newVal && !this.signingIn) {
                        this.$router.replace({
                            name: constants.ROUTE_USER_HOME,
                            params: { publicUrl: this.$store.getters.getPublicUrl },
                        });
                    }
                },
            },
        },

        mounted() {
            if (this.showCreateAccount) {
                this.initAccountCreationFirebaseContainer();
            } else {
                this.initDefaultFirebaseContainer();
            }
        },

        computed: {
            //
            // Map store isSignedIn so we can watch for completion of signin.  How could
            // this happen with when the user is on the signin page, and signin completion
            // would take the user home?  Well:
            //   1. A signed-out user opens a tab and goes to the signin page
            //   2. The user opens another tab and goes to the signin page
            //   3. User signs in on second tab
            //   4. User goes back to the first tab and hits refresh.
            // We want to take the user to their home page on this first tab, instead
            // of leaving them on the signin page.  We cannot detect this situation in
            // mounted(), since firebase and our server are asyncrhonous signins.
            //
            ...mapGetters({
                signinState: 'isSignedIn',
            }),

            mailTo(): string {
                return 'mailto:contact@' + constants.COMPANY_DOMAIN;
            },

            getHeaderText(): string {
                if (!this.showCreateAccount) {
                    return this.headerText;
                }

                return 'REGISTER';
            },
        },

        methods: {
            firebaseSignInSuccessful(authResult: firebase.auth.UserCredential): boolean {
                if (authResult.user) {
                    this.signingIn = true;

                    // We have a user.  Pull out the jwt, then tell the server.
                    authResult.user
                        .getIdToken(false)
                        .then((idToken) => {
                            store.commit('setFirebaseSigninState', SigninStatus.SIGNEDIN);

                            //
                            // Find out if there's a redirect query param, which is the vue router
                            // path to a specific page.  If no redirect query param, go to the
                            // referrer or front door.  Redirect will only happen if the
                            // server signin worked.  This could happen during invite redemption.
                            // If the referrer is the signin page, just put the user on the front door.
                            // We delete the redirect param from query, since it's no longer needed once
                            // we do the router.push().
                            //
                            let redirect = this.$route.query.redirect;
                            const query = this.$route.query;
                            delete query.redirect;

                            if (typeof redirect === 'undefined' || redirect === null || redirect === '') {
                                redirect = '/';
                                const referringUrl = this.referringUrl;
                                if (referringUrl !== '' && referringUrl !== this.$route.name) {
                                    redirect = referringUrl;
                                }
                            }

                            //
                            // See if there's a referral in the redirect URL.  Pull that referral out,
                            // since it will go as part of the API signin, and won't be used as
                            // part of the redirect.  All other query parameters should be kept and
                            // passed along with the redirect to the new page.
                            //
                            // The domain host means nothing here; it's just a way to get new URL() to work.
                            //
                            let referral = '';
                            if (redirect.includes('referral')) {
                                const fullURL = 'http://' + constants.COMPANY_DOMAIN + redirect;
                                const parsedUrl = new URL(fullURL);
                                const referralParam = parsedUrl.searchParams.get('referral');
                                if (referralParam !== '') {
                                    referral = referralParam as string | '';
                                    const params = new URLSearchParams(parsedUrl.search.slice(1));
                                    if (params) {
                                        redirect = parsedUrl.pathname;

                                        params.delete('referral');
                                        if (params.toString() !== '') {
                                            redirect += '?' + params.toString();
                                        }
                                    }
                                }
                            }

                            // tell server that signin happened
                            this.signinComplete(idToken, redirect, query, referral);
                        })
                        .catch((error) => {
                            store.commit('setFirebaseSigninState', SigninStatus.UNKNOWN);
                            this.signingIn = false;
                            Utils.CommonErrorHandler(error);
                        });
                }

                return false; // do not redirect.
            },

            async signinComplete(idToken: any, givenRedirect: any, query: any, givenReferral: string) {
                try {
                    const identity = await ApiUtils.apiWrapper(AuthService.firebaseSignInComplete, {
                        jwt: idToken,
                        redirect: givenRedirect,
                        referral: givenReferral,
                    });

                    //
                    // The main app should watch for store changes with isSignedIn if it needs
                    // to fetch user sites, etc.  The afterIdentityKnown() call will set the
                    // identity timer stuff.
                    //
                    afterIdentityKnown(identity);

                    //
                    // Redirect as needed.  The redirect value is the the path, not the component.
                    // This is needed to ensure public user pages redirect correctly
                    // under the ROUTE_USER_HOME component.
                    //
                    if (givenRedirect === '/' && this.$store.getters.getPublicUrl !== '') {
                        this.$router.push({
                            name: constants.ROUTE_USER_HOME,
                            params: { publicUrl: this.$store.getters.getPublicUrl },
                        });
                    } else {
                        this.$router.push({ path: givenRedirect, query });
                    }
                } catch (error) {
                    this.signingIn = false;
                    Utils.CommonErrorHandler(error);
                }
            },

            //
            // configuration for firebase UI, default sign in.  Even though signin with
            // email is the same flow for creating an account, it's confusing to users.
            // For this reason, we treat everything as "sign in", but we have a create
            // account link at the bottom of the card that will replace the default
            // firebase config with an email only one.
            //
            defaultFirebaseUIConfig(): firebaseui.auth.Config {
                const vm = this;

                //
                // The default is to leave out Email provider, since the app doesn't have that
                // yet; however, for debugging it's sometimes easier.  If the cooke is set (see
                // showWebsiteEmailSignin() in the store getter), then put in the Email provider.
                // I tried inserting into the signinOptions but was having typescript issues, so
                // I just duplicated the return info.
                //
                if (this.$store.getters.showWebsiteEmailSignin) {
                    return {
                        callbacks: {
                            signInSuccessWithAuthResult: (
                                authResult: firebase.auth.UserCredential,
                                redirectURL: string,
                            ) => {
                                return vm.firebaseSignInSuccessful(authResult);
                            },

                            signInFailure: (error: firebaseui.auth.AuthUIError): Promise<void> => {
                                log('Error with firebase login: ' + error.message);
                                return Promise.resolve();
                            },
                        },
                        credentialHelper: firebaseui.auth.CredentialHelper.NONE,
                        signInFlow: config.firebase.signInFlow,
                        signInOptions: [
                            {
                                provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                                customParameters: {
                                    prompt: 'select_account', // Forces selection even when only one account is available
                                },
                            },
                            firebase.auth.FacebookAuthProvider.PROVIDER_ID,
                            {
                                provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                                requireDisplayName: false,
                            },
                            firebase.auth.PhoneAuthProvider.PROVIDER_ID,
                            'apple.com',
                        ],
                    };
                }

                // Same but don't include EmailAuthProvider
                return {
                    callbacks: {
                        signInSuccessWithAuthResult: (
                            authResult: firebase.auth.UserCredential,
                            redirectURL: string,
                        ) => {
                            return vm.firebaseSignInSuccessful(authResult);
                        },

                        signInFailure: (error: firebaseui.auth.AuthUIError): Promise<void> => {
                            log('Error with firebase login: ' + error.message);
                            return Promise.resolve();
                        },
                    },
                    credentialHelper: firebaseui.auth.CredentialHelper.NONE,
                    signInFlow: config.firebase.signInFlow,
                    signInOptions: [
                        {
                            provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                            customParameters: {
                                prompt: 'select_account', // Forces selection even when only one account is available
                            },
                        },
                        firebase.auth.FacebookAuthProvider.PROVIDER_ID,
                        firebase.auth.PhoneAuthProvider.PROVIDER_ID,
                        'apple.com',
                    ],
                };
            },

            createAccountFirebaseUIConfig(): firebaseui.auth.Config {
                const vm = this;
                return {
                    callbacks: {
                        signInSuccessWithAuthResult: (
                            authResult: firebase.auth.UserCredential,
                            redirectURL: string,
                        ) => {
                            return vm.firebaseSignInSuccessful(authResult);
                        },

                        signInFailure: (error: firebaseui.auth.AuthUIError): Promise<void> => {
                            log('Error with firebase login: ' + error.message);
                            return Promise.resolve();
                        },

                        uiShown: () => {
                            const elements = document.getElementsByClassName('firebaseui-card-header');
                            if (elements && elements[0]) {
                                elements[0].textContent = 'Create a new account';
                            }
                        },
                    },
                    credentialHelper: firebaseui.auth.CredentialHelper.NONE,
                    signInFlow: config.firebase.signInFlow,
                    signInOptions: [
                        {
                            provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                            requireDisplayName: false,
                        },
                    ],
                };
            },

            initDefaultFirebaseContainer() {
                const firebaseUiContainer = this.$refs.firebaseUiContainer as Element;

                //
                // only one instance of auth UI can exist, so get previous
                // instance if it's already been initialized.
                //
                let authUi = firebaseui.auth.AuthUI.getInstance();
                if (authUi) {
                    authUi.reset();
                } else {
                    authUi = new firebaseui.auth.AuthUI(firebase.auth());
                }

                this.showCreateAccount = false;

                //
                // From the beginning, we used 'popup' as the firebase signInFlow.  However,
                // once we tried on a mobile app with web views, it didn't work at all.  The
                // popup doesn't share state with the original webview instance, so firebase
                // would be stuck in the 'pending' state.  Firebase recommends using 'redirect'
                // for mobile.  To make that work with Vue, which will get initialized from
                // scratch again on the redirect, we need to tell firebase to start(), which
                // will complete the signin (if in pending state).  If not in pending state,
                // the firebase UI will display the provider login buttons.  If using 'popup',
                // we don't want to start() if in pending.  signInFlow is a config, defaulting
                // to 'redirect' now, just in case we need to go back or use 'popup' in
                // non-mobile.
                //
                if (config.firebase.signInFlow === 'redirect') {
                    authUi.disableAutoSignIn();
                    authUi.start(firebaseUiContainer, this.defaultFirebaseUIConfig());
                } else if (config.firebase.signInFlow === 'popup') {
                    if (!authUi.isPendingRedirect()) {
                        authUi.disableAutoSignIn();
                        authUi.start(firebaseUiContainer, this.defaultFirebaseUIConfig());
                    }
                }
            },

            initAccountCreationFirebaseContainer() {
                const firebaseUiContainer = this.$refs.firebaseUiContainer as Element;

                let authUi = firebaseui.auth.AuthUI.getInstance();
                if (authUi) {
                    authUi.reset();
                } else {
                    authUi = new firebaseui.auth.AuthUI(firebase.auth());
                }

                this.showCreateAccount = true;

                if (config.firebase.signInFlow === 'redirect') {
                    authUi.disableAutoSignIn();
                    authUi.start(firebaseUiContainer, this.createAccountFirebaseUIConfig());
                } else if (config.firebase.signInFlow === 'popup') {
                    if (!authUi.isPendingRedirect()) {
                        authUi.disableAutoSignIn();
                        authUi.start(firebaseUiContainer, this.createAccountFirebaseUIConfig());
                    }
                }
            },

            //
            // getAppLink returns a properly formated Branch.io link.
            // 'gen' is the Branch.io generic alias we use.
            //
            getAppLink(): string {
                return config.branchioLink + getAdChannelQueryParams(true);
            },

                        //
            // When a user clicks on the download button, we log the analytics event.  The
            // click is usually an "@click" without ".prevent", so the href will be triggered.
            //
            analyticsExternalClick(type: string) {
                analytics.logAppInteraction(analytics.ANALYTICS_ACTION_APP_DOWNLOAD, type);
            },
        },

        //
        // See betpuppy beforeRouteEnter() if you need to save the referringUrl.
        // For this project, we are just going to the user's home page for now.
        //
    });
