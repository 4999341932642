/*
 * Copyright 2019-2022 Reki LLC - All rights reserved.
 * File: router.ts
 * Project: rekitv
 */
import Vue from 'vue';
import Router, { Route, RawLocation, Location } from 'vue-router';
import axios from 'axios';
import { logAppView } from '@/tsfiles/analytics';
import * as analytics from '@/tsfiles/analytics';
import * as constants from '@/tsfiles/constants';
import { ApiUtils } from '@/tsfiles/apiutils';
import { axios as apiAxios } from 'api';
import { AuthService, UserIdentity } from 'api';
import store from './store';
import { log } from '@/tsfiles/errorlog';
import { Utils } from './utils';
import { initFirebase, signout } from './firebase';
import { SigninStatus } from '@/tsfiles/enums';


Vue.use(Router);


const ret = Utils.GetTopLevelVueAndRoutes();  // Ignoring ret.renderApp (see main.ts)
const router = new Router({
    mode: 'history',
    routes: ret.routes,
});



// this type is ugly, but matches what vue-router defines for the next() function
type NextFunction = (to?: RawLocation | false | ((vm: Vue) => any) | void) => void;


//
// Parse out the redirect and dedupe against the query parameters.
// Normally when we do a router.push, it's using the component name and specific
// query parameters.  If this happens the fullPath contains the query parameters,
// as does to.query.
//
// If we do a router.push with a path, the to.fullPath AND to.query will both
// contain the same query parameters.  The to.fullPath may contain them as
// double-encoding, if that's what the caller wants, and the to.query will
// not have them as double-encoded.  We cannot simply compare strings.
//
// If you see a case where some to.query parameters are not in fullPath, use
// this function.  As of Nov 2019, this is not called.  If needed, it would
// be called inside checkRouteAuthentication(), if the user is not signed in.
//
function dedupeRedirectAndQuery(to: Route) {
    //
    // Try to minimize impact.  If to.fullPath does not contain any
    // parameters, just append like we originally had it.  If there
    // are params in fullPath, we need to loop.
    //
    if (Object.keys(to.query).length > 0 && to.fullPath.includes('?')) {
        // Host need to get new URL to work, but host name doesn't matter here.
        const fullURL = 'http://' + constants.COMPANY_DOMAIN + to.fullPath;

        const parsedUrl = new URL(fullURL);
        const params = new URLSearchParams(parsedUrl.search.slice(1));

        params.forEach((value, key) => {
            if (to.query[key] !== undefined) {
                delete to.query[key];
            }
        });
    }

    return {
        redirect: to.fullPath as string,
        ...to.query,
    };
}

//
// See if the user needs to be signed in before going to the desired route.
// If we need to be signed in, set up the query and go to firebasesignin.
//
// NOTE: Originally this code added 'redirect' as fullPath to the query AND
// '...to.query' to add in any query parameters that may have existed outside
// the fullPath.  When implementing 'referral', it appears fullPath always
// contains all the parameters.  Based on some testing, router.push with a name
// and query parameters sets the fullPath to contain all the query params, AND
// router.push with a path always has the query parameters in the fullPath.
// If you ever run into an instance where this is not true (to.fullPath may not
// contain all the to.query parameters), you will need to use the
// dedupeRedirectAndQuery() first, and pass that returned value into the 'loc'
// as the query.
//
function checkRouteAuthentication(to: Route, from: Route, next: NextFunction) {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        //
        // this route requires auth, check if logged in.
        // If not, redirect to login page.
        //
        if (!store.getters.isSignedIn) {
            const query = {
                redirect: to.fullPath as string,
            };

            const loc: Location = {
                name: constants.ROUTE_FIREBASE_SIGNIN,
                query,
            };

            next(loc);
            return;
        }
    }

    next(); // make sure to always call next()!
}

//
// Identity api can be called whenever the first page is loaded, or when
// our identity checking timer goes off.  We look at the identity to see
// if the user is signed in on the server, or not.  Set the store session
// appropriately.
//
export function afterIdentityKnown(ident: UserIdentity) {
    //
    // Set CSRF in axios, given to us through identity.
    //
    const csrf = ident.csrfToken;
    axios.defaults.headers.common = {
        'X-CSRF-Token': csrf,
    };
    apiAxios.defaults.headers.common = {
        'X-CSRF-Token': csrf,
    };

    if (ident.profileData !== undefined) {
        // Why was this json done?  Was the thought to deep copy?  Shouldn't be needed?
        // const profileData = JSON.parse(JSON.stringify(ident.profileData));
        store.commit('setSession', ident.profileData);

        store.commit('setServerSigninState', SigninStatus.SIGNEDIN);

        // Set the identity timer to the returned expiration given to us in seconds.
        setIdentityTimer(ident.secondsUntilSessionExpiration);
    } else {
        //
        // Tell the store the user signed out, even though there may already
        // be no one signed in.  This just makes sure the session variables
        // in the store are all reset.  Do not use 'setSession', since that
        // would cause us to lose the referral tracking.
        //
        store.commit('setServerSigninState', SigninStatus.SIGNEDOUT);
        store.commit('clearSession');

        clearIdentityTimer();
    }

    //
    // Check to see if we are told to turn off user analytics.  The
    // server may have this set for everyone, or a specific user based
    // on their settings.  We do it here, since getIdentity will be
    // called on a page refresh whether the user is signed in or not.
    // The boolean value will only come back through GRPC if true, so
    // turn off if not provided.
    //
    // If analytics is on, log the specific Segment identify event.
    //
    if (ident.analyticsOn) {
        analytics.turnOnUserAnalytics();

        if (ident.profileData && ident.profileData.userId && ident.profileData.publicUrl) {
            //
            // If brand new user, log signup as a app interaction event.  We still need to do
            // the signin identify event in Segment, which sets their user cookie.
            //
            if (ident.newUser) {
                analytics.logAppInteraction(
                    analytics.ANALYTICS_ACTION_SIGNUP,
                    ident.profileData.publicUrl,
                );
            }

            analytics.logSignin(ident.profileData.userId, ident.profileData.publicUrl);
        }
    } else {
        analytics.turnOffUserAnalytics();
    }
}

// Store the identityTimer value so we can clear the timer.
let identityTimer: NodeJS.Timeout | undefined;

//
// Check identity when the timer goes off.  This is used to catch invalid server
// sessions pretty soon after they expire.  The UI will go to the login screen
// if this is detected.
//
async function checkIdentityTimeout() {
    try {
        if (store.getters.isSignedIn) {
            const identity = await ApiUtils.apiWrapper(AuthService.getIdentity) as UserIdentity;
            afterIdentityKnown(identity);
            if (!store.getters.isSignedIn) {  // User is no longer signed in.
                router.push({ name: constants.ROUTE_FIREBASE_SIGNIN });
            }
        }
    } catch (error) {
        Utils.CommonErrorHandler(error);
    }
}

//
// If the user is signed in, set the timeout timer to the given
// number of seconds, plus a bit of fudge factor.
//
function setIdentityTimer(seconds: number | undefined) {
    clearIdentityTimer();  // Make sure not already set.
    if (store.getters.isSignedIn && seconds !== undefined) {
        //
        // 15 seconds of leeway, which should be after the session expires.
        // For the case where we haven't yet called getIdentity to find out
        // the expiration, we'll be called with 0 seconds. The first getIdentity will
        // happen 15 seconds later to set the session expiration properly.
        //
        seconds += 15;

        // console.log('setting timer to: ', seconds, new Date(Date.now()).toLocaleString());

        //
        // Use setInterval, since that will trigger immediately upon computer
        // wake if the timer should have gone off while asleep.
        //
        identityTimer = setInterval(checkIdentityTimeout, seconds * 1000);
    }
}

//
// Clear the identity timer is not undefined.
//
function clearIdentityTimer() {
    if (identityTimer !== undefined) {
        clearTimeout(identityTimer);
    }
}


//
// Called by the router before each Vue route.
//
router.beforeEach(async (to: Route, from: Route, next: NextFunction) => {
    let signinStatesAreInSync = store.getters.getFirebaseSigninState === store.getters.getServerSigninState;

    //
    // If we detect a signin sync issues between firebase and the server, try to get them
    // back in sync using signout.  We do not try to redirect somewhere else, or refresh.
    // We need to be careful not to get into an infinite loop.
    // This was made to be syncrhonous, so getIndentity down below will not
    // get called until the signout completed, theoretically.
    //
    if (!signinStatesAreInSync) {
        // For now log this so we can see how often it's happening
        log('Firebase/Server signin out of sync (firebase=' +
            store.getters.getFirebaseSigninState +
            ', server=' + store.getters.getServerSigninState + ')');
        await signout(store.getters.getPublicUrl, true, false);

        // Refetch signin state
        signinStatesAreInSync = store.getters.getFirebaseSigninState === store.getters.getServerSigninState;
    }

    if (signinStatesAreInSync && store.getters.isSigninStateKnown) {
        //
        // If signed in, and there's no timer, set one to a small number of
        // seconds to prime the pump.
        //
        if (store.getters.isSignedIn && identityTimer === undefined) {
            setIdentityTimer(0);
        }

        checkRouteAuthentication(to, from, next);
    } else if (to.name === 'error') {
        //
        // Else do not getIdentity if going to the error page; otherwise, we
        // end up in an infinite loop calling getIdentity forever.  This can be simulated
        // by killing the api server.
        //
        next();
    } else {
        // initialize firebase once
        initFirebase();

        // credentials must be enabled before
        // the identity request otherwise
        // the Set-Cookie returned from it is ignored
        axios.defaults.withCredentials = true;
        apiAxios.defaults.withCredentials = true;

        //
        // If no "from", it's a page refresh, which can come from copy/pasting
        // a link from one of our emails.  If it's a refresh, we need to
        // try and make sure firebase has time to get its current signin state
        // before we check identity and whether the page needs auth or not.
        //
        if (!from.name && store.getters.getFirebaseSigninState === SigninStatus.UNKNOWN) {
            await letsWaitForFirebaseBeforeGettingIdentity();
            getIdentity(to, from, next);
        } else {
            getIdentity(to, from, next);
        }
    }
});

//
// During a full refresh of the page, it's hard to see if/when both
// the server and firebase are in sync, before calling getIdentity().
// If router beforeEach() detects a full refresh and firebase is still
// in limbo, we will wait up to 1 second to let it figure out
// what state the user is in.  We should break out much quicker than
// one second though.
//
const letsWait = (ms: number) => new Promise((r, j) => setTimeout(r, ms));
async function letsWaitForFirebaseBeforeGettingIdentity() {
    for (let i = 0; i < 20; i++) {  // Max is 1 second
        if (store.getters.getFirebaseSigninState !== SigninStatus.UNKNOWN) {
            break;
        }

        await letsWait(50);  // 50 ms for each wait.
    }
}



//
// NOTE: getIdentity is an async promise.  App.vue will get loaded
// before this completes.  Even though we don't route to 'next' until
// checkRouteAuthentication, App.vue 'mounted' will be called.  The
// rendering seems to hold off, but fetching data on mounted could be
// an issue.  See App.vue.  It needs to watch for signin changes.
//
async function getIdentity(to: Route, from: Route, next: NextFunction) {
    try {
        const ident = await ApiUtils.apiWrapper(AuthService.getIdentity);
        afterIdentityKnown(ident);

        checkRouteAuthentication(to, from, next);
    } catch (error) {
        Utils.CommonErrorHandler(error);
    }
}

//
// Log the top-level routes to Segment analytics.  We don't log where
// the user came from, just where they landed.  It might be nice to
// know the origin, but that would require more integration into beforeEach().
//
router.afterEach((to) => {
    //
    // If there are any known adChannel query parameters, override existing ones,
    // then save back into Store.  Doing this inside afterEach means no other
    // views need to deal with adChannel.  Store commit is synchronous.
    //
    const query = router.currentRoute.query;
    if (query && (query.ds || query.cs)) {
        const adChannel = store.getters.getAdChannel;

        if (query.ds && query.ds !== '') {
            adChannel.downloadSource = query.ds as string;
        }
        if (query.cs && query.cs !== '') {
            adChannel.currentSource = query.cs as string;

            // Only set aid if currentSource was set.
            if (query.aid && query.aid !== '') {
                adChannel.advertisingId = query.aid as string;
            }
        }

        store.commit('setAdChannel', adChannel);
    }

    logAppView(to.name as string);
});

export default router;
