
    import Vue, { PropOptions } from 'vue';
    import mixins from 'vue-typed-mixins';
    import VueConstants from '@/components/VueConstants';
    import Avatar from '@/components/Avatar.vue';
    import StarRating from '@/components/uiutils/StarRating.vue';
    import ContentRecommendationV2 from '@/components/content/ContentRecommendationV2.vue';
    import ViewAllComments from '@/components/uiutils/ViewAllComments.vue';
    import { ContentV2 } from 'api';
    import { logInvalidParams } from '@/tsfiles/errorlog';

    export default mixins(VueConstants).extend({
        name: 'ContentRowV2',

        components: {
            'star-rating': StarRating,
            'url-avatar': Avatar,
            'content-recommendation': ContentRecommendationV2,
            'view-all-comments': ViewAllComments,
        },

        props: {
            // Content to display
            content: {
                type: Object,
                required: true,
            } as PropOptions<ContentV2>,
        },

        data() {
            return {};
        },

        watch: {},

        computed: {
            getGenreStr(): string {
                if (!this.content.genreJson) {
                    return '';
                }

                const data = JSON.parse(this.content.genreJson);
                if (!data.genres || data.genres.length === 0) {
                    return '';
                }

                return data.genres.join(', ');
            },

            uniqueSources(): number {
                if (!this.content || !this.content.sources || this.content.sources.length === 0) {
                    logInvalidParams(this.$options.name, 'uniqueSources');
                    return 0;
                }

                const foundSource = new Map();
                for (const source of this.content.sources) {
                    foundSource.set(source.name, 1);
                }

                return foundSource.size;
            },
        },

        methods: {},
    });
