
    import Vue from 'vue';
    import { Utils } from '@/tsfiles/utils';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import Avatar from '@/components/Avatar.vue';
    import UserNameDisplay from '@/components/user/UserNameDisplay.vue';
    import { UserService, User } from 'api';

    export default Vue.extend({
        name: 'BlockedUsers',

        components: {
            'url-avatar': Avatar,
            'user-name-display': UserNameDisplay,
        },

        props: {},

        data() {
            return {
                users: [] as User[],
            };
        },

        watch: {},

        mounted() {
            this.fetchBlockedUsers();
        },

        computed: {},

        methods: {
            async fetchBlockedUsers() {
                try {
                    const ret = await ApiUtils.apiWrapper(UserService.getBlockedUsers);
                    if (ret && ret.list) {
                        this.users = ret.list;
                    } else {
                        this.users = [] as User[];
                    }
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async unblockUser(publicUrl: string) {
                try {
                    await ApiUtils.apiWrapper(UserService.unblockUser, publicUrl);
                    this.fetchBlockedUsers();
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },
        },
    });
