
    import mixins from 'vue-typed-mixins';
    import * as constants from '@/tsfiles/constants';
    import VueConstants from '@/components/VueConstants';
    import EventBus from '@/eventbus';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { Utils } from '@/tsfiles/utils';
    import Avatar from '@/components/Avatar.vue';
    import { PageMessage } from '@/tsfiles/interfaces';
    import { SharedConstants, NotificationService, Notification, GenericPageRetrieval, FilterOption } from 'api';
    import { logInvalidParams } from '@/tsfiles/errorlog';

    export default mixins(VueConstants).extend({
        name: 'UserNotifications',

        components: {
            'url-avatar': Avatar,
        },

        props: {},

        data() {
            return {
                notifications: [] as Notification[],
                totalItemsNoPaging: 0,
                totalUnread: 0,
                unreadCheckbox: true,

                currentPage: 1,
                perPage: constants.NOTIFICATIONS_PER_PAGE,
            };
        },

        mounted() {
            this.loadNotifications();
            EventBus.$on(SharedConstants.PUBSUB_REFRESH_NOTIFICATIONS, this.loadNotifications);
        },

        beforeDestroy() {
            EventBus.$off(SharedConstants.PUBSUB_REFRESH_NOTIFICATIONS, this.loadNotifications);
        },

        computed: {},

        methods: {
            async loadNotifications() {
                //
                // If unreadCheckbox is true, we set the filter to unread_only items
                //
                const filterOptions = [] as FilterOption[];
                if (this.unreadCheckbox) {
                    filterOptions.push({ type: SharedConstants.FILTER_NOTIFICATIONS_UNREAD_ONLY });
                }

                try {
                    const ret = await ApiUtils.apiWrapper(NotificationService.getUserNotifications, {
                        pageNumber: this.currentPage,
                        numberOfItems: this.perPage,
                        filterBy: filterOptions,
                    } as GenericPageRetrieval);

                    if (ret.list) {
                        this.notifications = ret.list;
                        this.totalItemsNoPaging = ret.totalItemsIrregardlessOfPaging as number;
                        this.totalUnread = ret.totalUnreadItems;
                    } else {
                        this.notifications = [] as Notification[];
                        this.totalItemsNoPaging = 0;
                        this.totalUnread = 0;
                    }
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            getNotificationAvatarUrl(msg: Notification): string {
                if (msg.isMe) {
                    return this.$store.state.session.avatarUrl;
                } else if (msg.creator && msg.creator.imageUrl) {
                    return msg.creator.imageUrl;
                }

                return '/favicon-32x32.png';
            },

            getNotificationString(msg: Notification): string {
                // Get the json data from the message
                let data = undefined as any | undefined;
                if (msg.jsonData) {
                    data = JSON.parse(msg.jsonData);
                }

                if (data.displayText) {
                    return data.displayText;
                } else {
                    logInvalidParams(this.$options.name, 'getNotificationString ' + msg.event);
                }

                return '';
            },

            pageChanged(newPage: number) {
                this.currentPage = newPage;
                this.loadNotifications();
            },

            //
            // When a user clicks on a notification message, we go to different places
            // depending on what the notification is. The MainApp gets the
            // closeSingleNotification and will handle deleting it.
            //
            notificationClick(msg: Notification) {
                // Get the json data from the message
                let data = undefined as any | undefined;
                if (msg.jsonData) {
                    data = JSON.parse(msg.jsonData);
                }

                switch (msg.event) {
                    case SharedConstants.NOTIFICATION_VERIFY_EMAIL:
                    case SharedConstants.NOTIFICATION_EMAIL_FLAGGED:
                        //
                        // Save page message in case user leaves settings page, which we
                        // will send them to if they are not already there.
                        //
                        this.$store.commit('setPageMessage', {
                            notification: msg,
                            targetPages: [constants.ROUTE_USER_HOME],
                        } as PageMessage);

                        if (this.$router.currentRoute.name !== constants.ROUTE_USER_SETTINGS) {
                            this.$router.push({
                                name: constants.ROUTE_USER_SETTINGS,
                                params: { publicUrl: this.$store.state.session.publicUrl },
                            });
                        }
                        break;
                    case SharedConstants.NOTIFICATION_VERIFY_PHONE:
                    case SharedConstants.NOTIFICATION_PHONE_FLAGGED:
                        this.$store.commit('setPageMessage', {
                            notification: msg,
                        } as PageMessage);

                        if (this.$router.currentRoute.name !== constants.ROUTE_USER_SETTINGS) {
                            this.$router.push({
                                name: constants.ROUTE_USER_SETTINGS,
                                params: { publicUrl: this.$store.state.session.publicUrl },
                            });
                        }
                        break;
                    case SharedConstants.NOTIFICATION_FRIEND_INVITE:
                        this.$router.push({
                            name: 'friend_invite',
                            params: { code: data.code, fromEmail: 'false' },
                        });
                        break;
                    case SharedConstants.NOTIFICATION_FRIEND_RECOMMENDATION:
                        // Go to friend recommendations, highlighting given content id
                        this.$store.commit('setCurrentMenu', constants.ROUTE_USER_TIMELINE);

                        //
                        // The user may not be on their own private page.  If not, go there.
                        //
                        if (
                            this.$router.currentRoute.name !== constants.ROUTE_USER_HOME ||
                            this.$router.currentRoute.params.publicUrl !== this.$store.state.session.publicUrl
                        ) {
                            this.$router.push({
                                name: constants.ROUTE_USER_HOME,
                                params: { publicUrl: this.$store.state.session.publicUrl },
                            });
                        }
                        break;

                    case SharedConstants.NOTIFICATION_RECOMMENDATION_COMMENT:
                    case SharedConstants.NOTIFICATION_COMMENT_REPLY:
                    case SharedConstants.NOTIFICATION_RECOMMENDATION_LIKE:
                    case SharedConstants.NOTIFICATION_COMMENT_LIKE:
                        if (data.contentId && data.recommendationId) {
                            this.$store.commit('setCurrentMenu', constants.ROUTE_USER_TIMELINE);

                            if (this.$router.currentRoute.name !== constants.ROUTE_CONTENT_COMMENTS) {
                                this.$router.push({
                                    name: constants.ROUTE_CONTENT_COMMENTS,
                                    params: { publicUrl: this.$store.state.session.publicUrl },
                                });
                            }
                        }
                        break;
                    case SharedConstants.NOTIFICATION_NEW_FOLLOWER:
                        this.$router.push({
                            name: constants.ROUTE_USER_HOME,
                            params: { publicUrl: data.followerPublicUrl },
                        });
                        break;
                    case SharedConstants.NOTIFICATION_ADD_TO_LIST:
                        // If no contentPublicUrl, use contentId.
                        let publicUrl = data.contentPublicUrl;
                        if (!publicUrl) {
                            publicUrl = data.contentId.toString();
                        }

                        this.$router.push({
                            name: constants.ROUTE_CONTENT_DETAILS,
                            params: { contentId: data.contentId.toString(), contentPublicUrl: publicUrl as string },
                        });
                        break;
                }

                this.markNotificationAsRead(msg);
            },

            async markNotificationAsRead(msg: Notification) {
                try {
                    await ApiUtils.apiWrapper(NotificationService.markAsReadNotification, msg.id as number);
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },
        },
    });
