//
// Copyright 2019-2022 Reki LLC - All rights reserved.
// File: roleutils.ts
// Project: rekitv
//

//
// NOTE: Role determination needs to be kept in sync with the server.  If you
// make changes here, they should be made in roleutils.go as well.  If roles
// start getting too complex, it might be hard to keep in sync.  Maybe the server
// could be the primary determination of role usage?
// Also, do not use functions in store.ts for determining if the user
// has access to the features.  Keep all that logic here.
//
// Keep api/doc/roles.adoc up to date with any changes
//

import store from '@/tsfiles/store';
import * as constants from '@/tsfiles/constants';
import { AdminRole } from 'api';


export class RoleUtils {
    /////////////////////////////////////////////////////////////////////////
    // Super admin functions, which hit the store.
    /////////////////////////////////////////////////////////////////////////


    //
    // IsCorpDataSuperAdmin returns true if the user has the super admin access
    // that allows editing of company data.
    //
    public static IsCorpDataSuperAdmin(): boolean {
        return store.getters.isCorpDataSuperAdmin;
    }

    //
    // CanSupportUsers returns true if the user is allowed to support other users
    //
    public static CanSupportUsers(): boolean {
        return store.getters.isSupportSuperAdmin;
    }

    //
    // IsServerAdmin returns true if the user is allowed to mess with the server settings
    //
    public static IsServerSuperAdmin(): boolean {
        return store.getters.isServerSuperAdmin;
    }

    //
    // IsModuleAdmin returns true if the user is allowed to mess with modules.  These are all modules,
    // not just company specific ones.
    //
    public static IsModuleSuperAdmin(): boolean {
        return store.getters.isModuleSuperAdmin;
    }

    //
    // IsStatsAdmin returns true if the user is allowed to see server stats
    //
    public static IsStatsSuperAdmin(): boolean {
        return store.getters.isStatsSuperAdmin;
    }
}

const roleutils = new RoleUtils();
export default roleutils;
