
    import { Vue } from 'vue-property-decorator';
    import { ToggleButton } from 'vue-js-toggle-button';
    import config from '@/config';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { Utils } from '@/tsfiles/utils';
    import { RoleUtils } from '@/tsfiles/roleutils';
    import { AdminService, ApiMetric, SharedConstants } from 'api';
    import SuperAdminRoles from '@/components/SuperAdminRoles.vue';

    export default Vue.extend({
        name: 'ServerAdmin',

        components: {
            'super-admin-roles': SuperAdminRoles,
            'toggle-button': ToggleButton,
        },

        data() {
            return {
                analyticsLoggingOn: false,
                debugLoggingOn: false,
                debugThisFunction: '',
                apiSleepMs: 0,
                apiMetricsOn: false,
                apiMetricsFilter: '',
                apiMetrics: [] as ApiMetric[],

                showAdminRoleModal: false,

                iosVersion: '1',
                iosEditVersion: false,
                iosNewVersion: '',

                androidVersion: '1',
                androidEditVersion: false,
                androidNewVersion: '',
            };
        },

        mounted() {
            this.fetchCurrentValues();
        },

        computed: {
            hasAccess(): boolean {
                return this.$store.getters.isSignedIn && RoleUtils.IsServerSuperAdmin();
            },

            isProduction(): boolean {
                return config.runEnvironment === 'prod';
            },
        },

        methods: {
            //
            // Fetch the values we display on this page.
            // If production, ApiMetrics are not allowed.
            //
            // Anonymity is fetched in router.ts/getIdentity, since that's
            // used in the header and signed out users can see the info.
            //
            async fetchCurrentValues() {
                if (this.hasAccess) {
                    try {
                        let appVersion = await ApiUtils.apiWrapper(
                            AdminService.feGetMinimumAppVersion,
                            SharedConstants.DEVICE_TYPE_IOS,
                        );
                        if (appVersion && appVersion.value) {
                            this.iosVersion = appVersion.value;
                        }

                        appVersion = await ApiUtils.apiWrapper(
                            AdminService.feGetMinimumAppVersion,
                            SharedConstants.DEVICE_TYPE_ANDROID,
                        );
                        if (appVersion && appVersion.value) {
                            this.androidVersion = appVersion.value;
                        }

                        let response = await ApiUtils.apiWrapper(AdminService.feGetAnalyticsOn);
                        if (response && response.value) {
                            this.analyticsLoggingOn = true;
                        } else {
                            this.analyticsLoggingOn = false;
                        }

                        response = await ApiUtils.apiWrapper(AdminService.feGetDebugOn);
                        if (response && response.value) {
                            this.debugLoggingOn = true;
                        } else {
                            this.debugLoggingOn = false;
                        }

                        const ret = await ApiUtils.apiWrapper(AdminService.feGetDebugFunction);
                        if (ret && ret.value) {
                            this.debugThisFunction = ret.value;
                        } else {
                            this.debugThisFunction = '';
                        }

                        // Some features are only available outside production.
                        if (!this.isProduction) {
                            response = await ApiUtils.apiWrapper(AdminService.feGetApiMetricsOn);
                            if (response && response.value) {
                                this.apiMetricsOn = true;
                            } else {
                                this.apiMetricsOn = false;
                            }

                            const msVal = await ApiUtils.apiWrapper(AdminService.feGetApiSleepMs);
                            if (msVal && msVal.num) {
                                this.apiSleepMs = msVal.num;
                            } else {
                                this.apiSleepMs = 0;
                            }
                        }
                    } catch (error) {
                        Utils.CommonErrorHandler(error);
                    }
                }
            },

            //
            // Check to see if the current value looks reasonable for a app version.  Right
            // now it can only contain numbers and dots.
            //
            validAppVersion(val: string): boolean {
                if (val === '') {
                    return false;
                }

                return /^[0-9.]*$/.test(val);
            },

            async iosSaveNewVersion() {
                try {
                    await ApiUtils.apiWrapper(AdminService.feSetMinimumAppVersion, {
                        deviceType: 'ios',
                        value: this.iosNewVersion,
                    });

                    this.fetchCurrentValues();
                    this.iosNewVersion = '';
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async androidSaveNewVersion() {
                try {
                    await ApiUtils.apiWrapper(AdminService.feSetMinimumAppVersion, {
                        deviceType: 'android',
                        value: this.androidNewVersion,
                    });

                    this.fetchCurrentValues();
                    this.androidNewVersion = '';
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },
        },
    });
