
    import Vue from 'vue';
    import ModalAlert from '@/generic-modals/ModalAlert.vue';

    export default Vue.extend({
        name: 'CommentReply',

        components: {
            'modal-alert': ModalAlert,
        },

        props: {},

        data() {
            return {
                showReplyForm: false,
                commentReply: '',
            };
        },

        watch: {},

        computed: {
            commentReplyValid(): boolean {
                return this.commentReply.trim() !== '';
            },
        },

        methods: {
            saveCommentReply() {
                if (!this.commentReplyValid) {
                    return;
                }

                this.$emit('comment-reply-submitted', this.commentReply);
                this.showReplyForm = false;
            },
        },
    });
