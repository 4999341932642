
    import Vue, { PropOptions } from 'vue';
    import mixins from 'vue-typed-mixins';
    import { mapGetters } from 'vuex';
    import { RecommendationUpdateEmit, NotificationCommentReply } from '@/tsfiles/interfaces';
    import * as constants from '@/tsfiles/constants';
    import * as analytics from '@/tsfiles/analytics';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import VueConstants from '@/components/VueConstants';
    import { logInvalidParams, logInvalidResponse } from '@/tsfiles/errorlog';
    import { Utils } from '@/tsfiles/utils';
    import { TimeUtils } from '@/tsfiles/timeutils';
    import Avatar from '@/components/Avatar.vue';
    import CommentRow from '@/components/content/CommentRow.vue';
    import CommentReply from '@/components/uiutils/CommentReply.vue';
    import {
        ContentService,
        CommentRequest,
        ContentComments,
        UserRecommendation,
        GenericPageRetrieval,
        CommentRepliesRequest,
    } from 'api';

    export default mixins(VueConstants).extend({
        name: 'CommentsPage',

        components: {
            'comment-row': CommentRow,
            'url-avatar': Avatar,
            'comment-reply': CommentReply,
        },

        props: {
            // Are these world comments?  If not, default is followee
            worldComments: {
                type: Boolean,
                required: false,
                default: false,
            },

            // Content Id to display.
            contentId: {
                type: Number,
                required: true,
            },

            contentName: {
                type: String,
                required: true,
            },

            recommendationChanged: {
                type: Object,
                required: false,
            } as PropOptions<UserRecommendation>,
        },

        data() {
            return {
                recommendations: [] as UserRecommendation[],
                contentPublicUrl: '',

                totalItems: 0,
                currentPage: 1,
                perPage: constants.RECOMMENDATION_COMMENTS_PER_PAGE,
            };
        },

        //
        // NOTE: If debugging with console.log, remember that this component is used
        // for the Friends tab and the Everyone tab.  You'll get double logging.  Use
        // this.worldComments to help distinguish.
        //
        watch: {
            contentId: {
                immediate: true,
                handler(newVal: number, oldVal: number) {
                    if (newVal && newVal !== oldVal) {
                        this.fetchData(undefined);
                    }
                },
            },

            recommendationChanged: {
                immediate: true,
                deep: true,
                handler(newVal: UserRecommendation, oldVal: UserRecommendation) {
                    if (newVal && newVal !== oldVal) {
                        this.replaceExistingRecommendationIfItExists(newVal);
                    }
                },
            },
        },

        computed: {},

        methods: {
            //
            // Create date string to display how long ago the creation date was
            //
            getDateStr(dateStr: Date | undefined): string {
                return TimeUtils.pastTimeDistanceStringWithoutHelperWords(dateStr);
            },

            //
            // Are there any more replies to be shown for this recommendation?
            // All of them may not have been loaded yet.
            //
            hasRemainingReplies(item: UserRecommendation): boolean {
                if (
                    !item.limitedInitialReplies ||
                    !item.totalRecommendationCommentReplies ||
                    item.totalRecommendationCommentReplies === 0
                ) {
                    return false;
                }

                return item.totalRecommendationCommentReplies > item.limitedInitialReplies.length;
            },

            //
            // What string to display for "Get N more..." replies.
            //
            getMoreRepliesStr(item: UserRecommendation): string {
                if (
                    !item.limitedInitialReplies ||
                    !item.totalRecommendationCommentReplies ||
                    item.totalRecommendationCommentReplies === 0
                ) {
                    logInvalidParams(this.$options.name, 'getMoreRepliesStr');
                    return '';
                }

                const numLeft = item.totalRecommendationCommentReplies - item.limitedInitialReplies.length;
                if (numLeft <= constants.MORE_REPLIES_FETCHED_COUNT) {
                    return 'Get ' + numLeft + ' more...';
                }

                return 'Get ' + constants.MORE_REPLIES_FETCHED_COUNT + ' more (' + numLeft + ' remaining)...';
            },

            async fetchData(notification: NotificationCommentReply | undefined) {
                if (this.contentId <= 0) {
                    logInvalidParams(this.$options.name, 'fetchData');
                    return;
                }

                //
                // If we are trying to get to a specific comment reply, get the page we want to start
                // with.
                //
                if (notification) {
                    try {
                        const ret = await ApiUtils.apiWrapper(ContentService.whatCommentPageIsRecommendationOn, {
                            contentId: this.contentId,
                            recommendationId: notification.recommendationId,
                            numberOfItems: constants.RECOMMENDATION_COMMENTS_PER_PAGE,
                            underFollowees: notification.underFolloweeRecommendation,
                        });

                        if (!ret || !ret.num || ret.num === 0) {
                            logInvalidResponse(this.$options.name, 'fetchData');
                            return;
                        }

                        //
                        // fetchData will get the correct page.  We need to tell it to make
                        // the correct comment visible, after the fetch completes
                        //
                        this.currentPage = ret.num;
                    } catch (error) {
                        Utils.CommonErrorHandler(error);
                    }
                }

                try {
                    let ret = undefined as ContentComments | undefined;
                    if (this.worldComments) {
                        ret = await ApiUtils.apiWrapper(ContentService.getWorldContentCommentsV2, {
                            contentId: this.contentId,
                            pageData: {
                                pageNumber: this.currentPage,
                                numberOfItems: this.perPage,
                            } as GenericPageRetrieval,
                        });

                        if (ret && ret.totalCommentsIrregardlessOfPaging) {
                            this.$emit('total-world-comments', ret.totalCommentsIrregardlessOfPaging);
                        } else {
                            this.$emit('total-world-comments', 0);
                        }
                    } else {
                        ret = await ApiUtils.apiWrapper(ContentService.getFolloweeContentCommentsV2, {
                            contentId: this.contentId,
                            pageData: {
                                pageNumber: this.currentPage,
                                numberOfItems: this.perPage,
                            } as GenericPageRetrieval,
                        });

                        if (ret && ret.totalCommentsIrregardlessOfPaging) {
                            this.$emit('total-followee-comments', ret.totalCommentsIrregardlessOfPaging);
                        } else {
                            this.$emit('total-followee-comments', 0);
                        }
                    }

                    if (ret) {
                        if (ret.recommendations) {
                            this.recommendations = ret.recommendations;
                        } else {
                            this.recommendations = [] as UserRecommendation[];
                        }

                        this.totalItems = ret.totalRecommendationsIrregardlessOfPaging as number;

                        // Move to top of page.  Paging from bottom will not scroll to top unless forced
                        window.scrollTo(0, 0);
                    }

                    //
                    // If notificationCommentReply is set, open up the comment replies for the
                    // correct recommendation, then put it in view.  We cannot reset
                    // this.notificationCommentReply until the reply is shown.
                    //
                    if (notification) {
                        this.showCommentReply(notification);
                    }

                    //
                    // Get the contentPublicUrl, used for analytics in CommentRow.
                    //
                    const retPublicUrl = await ApiUtils.apiWrapper(
                        ContentService.getContentPublicUrlById,
                        this.contentId,
                    );
                    if (retPublicUrl && retPublicUrl.value) {
                        this.contentPublicUrl = retPublicUrl.value;
                    }
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            //
            // This function is called by the watcher of recommendationChanged.  It means a parent
            // told us to refresh a recommendation if it's in our list.  We don't actually call the server
            // to refresh, we just update our existing entry to the one given.
            //
            replaceExistingRecommendationIfItExists(recommendation: UserRecommendation) {
                for (let idx = 0; idx < this.recommendations.length; idx++) {
                    if (this.recommendations[idx].recommendationId === recommendation.recommendationId) {
                        Vue.set(this.recommendations, idx, recommendation);
                        break;
                    }
                }
            },

            pageChanged(newPage: number) {
                this.currentPage = newPage;
                this.fetchData(undefined);
            },

            getEditableComment(item: UserRecommendation): string {
                if (item && item.user && this.$store.getters.isUsersPublicUrl(item.user.publicUrl) && item.comment) {
                    return item.comment;
                }

                return '';
            },

            //
            //
            //
            recommendationUpdated(idx: number, data: RecommendationUpdateEmit) {
                if (
                    idx < 0 ||
                    idx >= this.recommendations.length ||
                    !this.recommendations[idx] ||
                    !data.type ||
                    !data.recommendation
                ) {
                    logInvalidParams(this.$options.name, 'recommendationUpdated');
                    return;
                }

                Vue.set(this.recommendations, idx, data.recommendation);

                this.$emit('recommendation-updated', data);
            },

            async deleteCommentReply(idx: number, replyIdx: number) {
                if (
                    idx < 0 ||
                    idx >= this.recommendations.length ||
                    !this.recommendations[idx] ||
                    !this.recommendations[idx].limitedInitialReplies
                ) {
                    logInvalidParams(this.$options.name, 'deleteCommentReply');
                    return;
                }

                const replies = this.recommendations[idx].limitedInitialReplies;
                if (!replies || replyIdx < 0 || replyIdx >= replies.length) {
                    logInvalidParams(this.$options.name, 'deleteCommentReply');
                    return;
                }

                try {
                    const ret = await ApiUtils.apiWrapper(ContentService.deleteCommentReply, {
                        contentId: this.contentId,
                        recommendationId: replies[replyIdx].recommendationId,
                        commentReplyId: replies[replyIdx].commentReplyId,
                    } as CommentRequest);

                    if (!ret || !ret.userRecommendation || !ret.userRecommendation.recommendationId) {
                        logInvalidResponse(this.$options.name, 'deleteCommentReply');
                        return;
                    }

                    Vue.set(this.recommendations, idx, ret.userRecommendation);

                    this.$emit('recommendation-updated', {
                        type: constants.RECOMMENDATION_UPDATE_EMIT_TYPE_REPLY,
                        recommendation: ret.userRecommendation,
                        totalComments: ret.totalComments,
                        totalFolloweeComments: ret.totalFolloweeComments,
                    } as RecommendationUpdateEmit);

                    analytics.logAppInteraction(analytics.ANALYTICS_ACTION_DELETE_COMMENT_REPLY, this.contentPublicUrl);
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async getMoreReplies(idx: number) {
                if (
                    idx < 0 ||
                    idx >= this.recommendations.length ||
                    !this.recommendations[idx] ||
                    !this.recommendations[idx].limitedInitialReplies
                ) {
                    logInvalidParams(this.$options.name, 'getMoreReplies');
                    return;
                }

                const replies = this.recommendations[idx].limitedInitialReplies;
                if (!replies || replies.length === 0) {
                    logInvalidParams(this.$options.name, 'getMoreReplies');
                    return;
                }

                // Get last commentReplyId from list.
                const lastCommentReplyId = replies[replies.length - 1].commentReplyId;
                if (!lastCommentReplyId) {
                    logInvalidParams(this.$options.name, 'getMoreReplies');
                    return;
                }

                try {
                    const ret = await ApiUtils.apiWrapper(ContentService.getSubsetOfCommentReplies, {
                        lastCommentReplyId,
                        recommendationId: this.recommendations[idx].recommendationId,
                        numberOfReplies: constants.MORE_REPLIES_FETCHED_COUNT,
                    } as CommentRepliesRequest);

                    if (!ret || !ret.replies || ret.replies.length === 0) {
                        logInvalidResponse(this.$options.name, 'getMoreReplies');
                        return;
                    }

                    const fullReplies = replies.concat(ret.replies);
                    Vue.set(this.recommendations[idx], 'limitedInitialReplies', fullReplies);
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            //
            // Make sure the comment reply in the given notification is shown.  If not
            // already loaded, ask the server for more comment replies.
            //
            async showCommentReply(notification: NotificationCommentReply) {
                if (!notification) {
                    logInvalidParams(this.$options.name, 'showCommentReply');
                    return;
                }

                //
                // Find the recommendation, which should be loaded.  fetchData() should have
                // loaded the correct page for the recommendationId.  We need the idx so we
                // can reset the limitedInitialReplies array.
                //
                let idx = 0;
                for (; idx < this.recommendations.length; idx++) {
                    if (this.recommendations[idx].recommendationId === notification.recommendationId) {
                        break;
                    }
                }

                if (idx === this.recommendations.length) {
                    logInvalidParams(this.$options.name, 'showCommentReply - no recommendation found');
                    return;
                }

                // See if reply is already available
                const replies = this.recommendations[idx].limitedInitialReplies;
                if (!replies) {
                    logInvalidParams(this.$options.name, 'showCommentReply - no replies found');
                    return;
                }

                let foundReplyInList = false;
                for (const reply of replies) {
                    if (reply.commentReplyId === notification.newCommentReplyId) {
                        foundReplyInList = true;
                        break;
                    }
                }

                if (!foundReplyInList) {
                    // The desired reply is not loaded yet.  Get it
                    try {
                        const ret = await ApiUtils.apiWrapper(ContentService.getSubsetOfCommentReplies, {
                            recommendationId: this.recommendations[idx].recommendationId,
                            forceCommentReplyIdVisible: notification.newCommentReplyId,
                            numberOfReplies: constants.MORE_REPLIES_FETCHED_COUNT,
                        } as CommentRepliesRequest);

                        if (!ret || !ret.replies || ret.replies.length === 0) {
                            logInvalidResponse(this.$options.name, 'showCommentReply');
                            return;
                        }

                        Vue.set(this.recommendations[idx], 'limitedInitialReplies', ret.replies);
                    } catch (error) {
                        Utils.CommonErrorHandler(error);
                    }
                }

                this.makeCommentReplyVisible(notification.newCommentReplyId);
            },

            //
            // makeCommentVisible needs to bring the given commentReplyId into view.  Originally
            // this was using nextTick, but the timing wouldn't work.  We need the page
            // rendered before getting the div's position.  To accomplish this, use
            // setTimeout, which will make sure the DOM changes are completed AND the
            // page is rendered.
            //
            makeCommentReplyVisible(commentReplyId: number) {
                setTimeout(() => {
                    const div = document.getElementById('commentReplyBlockDiv-' + commentReplyId);
                    if (div) {
                        const y = div.getBoundingClientRect().top + window.scrollY - constants.HEADER_HEIGHT;
                        window.scroll({ top: y, behavior: 'smooth' });
                    }
                }, 0);
            },
        },
    });
