
    import Vue, { PropOptions } from 'vue';
    import mixins from 'vue-typed-mixins';
    import VueConstants from '@/components/VueConstants';
    import { Utils } from '@/tsfiles/utils';
    import { DateTime as LuxonDateTime } from 'luxon';
    import Avatar from '@/components/Avatar.vue';
    import { User, Post } from 'api';

    export default mixins(VueConstants).extend({
        name: 'NewsPost',

        components: {
            'url-avatar': Avatar,
        },

        props: {
            item: {
                // Post to display
                type: Object,
                required: true,
            } as PropOptions<Post>,

            // Creator of this post item
            user: {
                type: Object,
                required: true,
            } as PropOptions<User>,
        },

        data() {
            return {
                json: undefined as any | undefined,
            };
        },

        watch: {
            item: {
                immediate: true,
                handler(newVal, oldVal) {
                    if (newVal && newVal.message) {
                        this.json = JSON.parse(this.item.message as string);
                    } else {
                        this.json = undefined as any | undefined;
                    }
                },
            },
        },

        computed: {
            postTime(): string {
                if (!this.item.timestamp) {
                    return '';
                }

                return LuxonDateTime.fromISO(this.item.timestamp).toLocaleString(LuxonDateTime.TIME_SIMPLE);
            },

            // TODO: Is this needed?  Are there different classes?
            getMessageClass(): string {
                const ret = 'font-weight-bold px-2';
                if (!this.json.type) {
                    return ret;
                }

                return ret;
            },

            getPostMessage(): string {
                if (!this.json) {
                    return '';
                }

                let ret = this.json.message;
                if (this.json.date && this.json.message.includes('{{DATE_EXPAND}}')) {
                    const localDate = LuxonDateTime.fromISO(this.json.date).toLocaleString({
                        weekday: 'short',
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: '2-digit',
                        timeZoneName: 'short',
                    });
                    ret = ret.replace('{{DATE_EXPAND}}', localDate);
                }

                return ret;
            },

            serverMessage(): boolean {
                return this.json && this.json.serverGenerated;
            },

            // If sitting on the user's page already, no reason to allow click to home page
            alreadyOnUsersPage(): boolean {
                return this.$router.currentRoute.params.publicUrl === this.user.publicUrl;
            },
        },

        methods: {
            getServerMessage(): string {
                if (!this.json) {
                    return '';
                }

                return this.json.message;
            },
        },
    });
