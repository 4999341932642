
    import Vue, { PropOptions } from 'vue';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { Utils } from '@/tsfiles/utils';
    import { logInvalidParams, logInvalidResponse } from '@/tsfiles/errorlog';
    import * as analytics from '@/tsfiles/analytics';
    import * as constants from '@/tsfiles/constants';
    import { RecommendationUpdateEmit } from '@/tsfiles/interfaces';
    import ContentRowV2 from '@/components/content/ContentRowV2.vue';
    import AddRecommendationModal from '@/components/content/AddRecommendationModal.vue';
    import RecommendationEdit from '@/components/content/RecommendationEditModal.vue';
    import ListAddRemoveContentModal from '@/components/lists/ListAddRemoveContentModal.vue';
    import UserNameDisplay from '@/components/user/UserNameDisplay.vue';
    import ModalAlert from '@/generic-modals/ModalAlert.vue';
    import { User, ContentService, ContentV2, GenericPageRetrieval, UserRecommendation } from 'api';

    export default Vue.extend({
        name: 'UserProfileRekis',

        components: {
            'content-row': ContentRowV2,
            'add-recommendation': AddRecommendationModal,
            'recommendation-edit': RecommendationEdit,
            'list-add-remove-content': ListAddRemoveContentModal,
            'user-name-display': UserNameDisplay,
            'modal-alert': ModalAlert,
        },

        props: {
            user: {
                type: Object,
                required: true,
                default: () => undefined as User | undefined,
            } as PropOptions<User>,
        },

        data() {
            return {
                recommendations: [] as ContentV2[],
                recommendationsFetchComplete: false,
                authedUser: false,

                addRecommendation: undefined as ContentV2 | undefined,
                editRecommendation: undefined as ContentV2 | undefined,
                deleteRecommendation: undefined as ContentV2 | undefined,
                listAddRemoveContent: undefined as ContentV2 | undefined,

                totalItems: 0,
                currentPage: 1,
                perPage: constants.RECOMMENDATIONS_PER_PAGE,
            };
        },

        watch: {},

        mounted() {
            this.fetchRecommendations();
            this.authedUser = this.$store.getters.isUsersPublicUrl(this.user.publicUrl);
        },

        computed: {},

        methods: {
            async fetchRecommendations() {
                try {
                    const ret = await ApiUtils.apiWrapper(ContentService.getRecommendationsV2, {
                        publicUrl: this.user.publicUrl,
                        pageNumber: this.currentPage,
                        numberOfItems: this.perPage,
                    } as GenericPageRetrieval);

                    if (ret.list) {
                        this.recommendations = ret.list;
                        this.totalItems = ret.totalItemsIrregardlessOfPaging as number;
                    } else {
                        this.recommendations = [] as ContentV2[];
                        this.totalItems = 0;
                    }

                    this.$emit('total-recommendations', this.totalItems);
                    this.recommendationsFetchComplete = true;
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            pageChanged(newPage: number) {
                this.currentPage = newPage;
                this.fetchRecommendations();
            },

            async saveNewRecommendation(data: any) {
                if (!this.addRecommendation) {
                    logInvalidParams(this.$options.name, 'saveNewRecommendation');
                    return;
                }

                const updatedText = data.comment;
                const updatedRating = data.rating;

                // Comment can be empty, but not rating
                if (!this.addRecommendation || updatedRating === 0) {
                    logInvalidParams(this.$options.name, 'saveNewRecommendation');
                    return;
                }

                try {
                    const ret = await ApiUtils.apiWrapper(ContentService.addRecommendation, {
                        contentId: this.addRecommendation.contentId,
                        comment: updatedText,
                        rating: updatedRating,
                    });

                    if (!ret || !ret.recommendedBy || ret.recommendedBy.length !== 1) {
                        logInvalidResponse(this.$options.name, 'saveNewRecommendation');
                        return;
                    }

                    this.fetchRecommendations();

                    analytics.logAppInteraction(
                        analytics.ANALYTICS_ACTION_NEW_RECOMMENDATION,
                        this.addRecommendation.contentPublicUrl,
                    );

                    this.addRecommendation = undefined as ContentV2 | undefined;
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            //
            // The authenticated user is editing their own reki
            //
            async saveRecommendationUpdate(data: any) {
                const updatedText = data.comment;
                const updatedRating = data.rating;

                // Comment can be empty, but not rating
                if (!this.editRecommendation || updatedRating === 0) {
                    logInvalidParams(this.$options.name, 'saveRecommendationUpdate');
                    return;
                }

                try {
                    const ret = await ApiUtils.apiWrapper(ContentService.updateRecommendation, {
                        contentId: this.editRecommendation.contentId,
                        comment: updatedText,
                        rating: updatedRating,
                    });

                    if (!ret || !ret.recommendedBy || ret.recommendedBy.length !== 1) {
                        logInvalidResponse(this.$options.name, 'saveRecommendationUpdate');
                        return;
                    }

                    this.fetchRecommendations();

                    analytics.logAppInteraction(
                        analytics.ANALYTICS_ACTION_UPDATE_RECOMMENDATION,
                        this.editRecommendation.contentPublicUrl,
                    );

                    this.editRecommendation = undefined as ContentV2 | undefined;
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            //
            // When the lower level components do like or unlike, or reply, we eventually get
            // the 'recommendation-updated' emit, with the data RecommendationUpdateEmit.  That contains the
            // new recommendation, and, if defined, the new comments totals.
            //
            recommendationUpdated(idx: number, data: RecommendationUpdateEmit) {
                if (
                    !this.recommendations ||
                    idx < 0 ||
                    idx >= this.recommendations.length ||
                    !data ||
                    !data.recommendation
                ) {
                    logInvalidParams(this.$options.name, 'recommendationUpdated');
                    return;
                }

                if (this.authedUser) {
                    if (this.recommendations[idx].authedUserRecommendation) {
                        Vue.set(
                            this.recommendations[idx].authedUserRecommendation as UserRecommendation,
                            'totalCommentLikes',
                            data.recommendation.totalCommentLikes,
                        );

                        Vue.set(
                            this.recommendations[idx].authedUserRecommendation as UserRecommendation,
                            'authedUserLikedComment',
                            data.recommendation.authedUserLikedComment,
                        );
                    }
                } else {
                    if (this.recommendations[idx].latestRecommendation) {
                        Vue.set(
                            this.recommendations[idx].latestRecommendation as UserRecommendation,
                            'totalCommentLikes',
                            data.recommendation.totalCommentLikes,
                        );

                        Vue.set(
                            this.recommendations[idx].latestRecommendation as UserRecommendation,
                            'authedUserLikedComment',
                            data.recommendation.authedUserLikedComment,
                        );
                    }
                }

                if (data.totalComments) {
                    Vue.set(this.recommendations[idx], 'totalComments', data.totalComments);
                }

                if (data.totalFolloweeComments) {
                    Vue.set(this.recommendations[idx], 'totalFolloweeComments', data.totalFolloweeComments);
                }
            },

            getDeleteRecommendationTitle(): string {
                if (!this.deleteRecommendation) {
                    logInvalidParams(this.$options.name, 'getDeleteRecommendationTitle');
                    return '';
                }

                return (
                    'Are you sure you want to delete your Reki: <br><br> <b>' +
                    this.deleteRecommendation.name +
                    '</b></br><br>'
                );
            },

            async okToDeleteRecommendation() {
                if (!this.deleteRecommendation) {
                    logInvalidParams(this.$options.name, 'okToDeleteRecommendation');
                    return;
                }

                try {
                    const deletedPublicUrl = this.deleteRecommendation.contentPublicUrl;

                    await ApiUtils.apiWrapper(
                        ContentService.deleteRecommendation,
                        this.deleteRecommendation.contentId as number,
                    );

                    this.fetchRecommendations();

                    analytics.logAppInteraction(analytics.ANALYTICS_ACTION_DELETE_RECOMMENDATION, deletedPublicUrl);
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            gotoDetails(item: ContentV2) {
                this.$router.push({
                    name: constants.ROUTE_CONTENT_DETAILS,
                    params: { contentPublicUrl: item.contentPublicUrl as string },
                });
            },

            gotoComments(item: ContentV2) {
                this.$router.push({
                    name: constants.ROUTE_CONTENT_COMMENTS,
                    params: { contentPublicUrl: item.contentPublicUrl as string },
                });
            },
        },
    });
