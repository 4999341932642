
    import Vue, { PropOptions } from 'vue';
    import StarRating from '@/components/uiutils/StarRating.vue';
    import { ContentV2 } from 'api';

    export default Vue.extend({
        name: 'AddRecommendationModal',

        components: {
            'star-rating': StarRating,
        },

        props: {
            // If no smarty requested, what is the read-only content
            readonlyContent: {
                type: Object,
                required: false,
                default: () => undefined as ContentV2 | undefined,
            } as PropOptions<ContentV2>,
        },

        data() {
            return {
                newComment: '',
                newRating: 0,
            };
        },

        watch: {},

        computed: {
            getModalTitle(): string {
                if (this.readonlyContent) {
                    return 'Add Reki: ' + this.readonlyContent.name;
                }

                return 'Add Reki';
            },

            newCommentValid(): boolean {
                return this.newComment === '' || this.newComment.trim() !== '';
            },

            inputValid(): boolean {
                // Only rating is required
                if (this.newRating === 0 || !this.newCommentValid) {
                    return false;
                }

                return true;
            },
        },

        methods: {
            smartyContentSelection(data: any) {
                const selection = data.newVal as ContentV2;
            },

            submit() {
                this.$emit('save-recommendation', {
                    comment: this.newComment,
                    rating: this.newRating,
                });
            },
        },
    });
