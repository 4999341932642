
    import Vue, { PropOptions } from 'vue';
    import mixins from 'vue-typed-mixins';
    import store from '@/tsfiles/store';
    import { log, logInvalidParams } from '@/tsfiles/errorlog';
    import { signout } from '@/tsfiles/firebase';
    import validator from 'validator';
    import { Utils } from '@/tsfiles/utils';
    import * as analytics from '@/tsfiles/analytics';
    import * as constants from '@/tsfiles/constants';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { SocialMediaType } from '@/tsfiles/interfaces';
    import VueConstants from '@/components/VueConstants';
    import Avatar from '@/components/Avatar.vue';
    import ChannelFlaggedAlert from '@/components/user/ChannelFlaggedAlert.vue';
    import ModalAlert from '@/generic-modals/ModalAlert.vue';
    import VerifyEmail from '@/generic-modals/VerifyEmail.vue';
    import VerifyPhone from '@/generic-modals/VerifyPhone.vue';
    import CountryCodeMenu from '@/components/uiutils/CountryCodeMenu.vue';
    import ImageUploader from 'vue-image-upload-resize';
    import FilterMenu from '@/components/uiutils/FilterMenu.vue';
    import {
        SharedConstants,
        ContentService,
        MediaService,
        UserService,
        User,
        SocialMediaItem,
        GenericImage,
    } from 'api';

    Vue.use(ImageUploader);

    export default mixins(VueConstants).extend({
        name: 'UserSettings',

        components: {
            'url-avatar': Avatar,
            'channel-flagged-alert': ChannelFlaggedAlert,
            'modal-alert': ModalAlert,
            'verify-email': VerifyEmail,
            'verify-phone': VerifyPhone,
            'country-code-menu': CountryCodeMenu,
            'filter-menu': FilterMenu,
        },

        data() {
            return {
                profile: {} as User,
                origProfile: {} as User,
                newPhone: '',
                origPhone: '',

                alertMessage: null as string | null,
                dismissCountDown: 0,
                permanent: false,
                alertType: 'success',
                profileRetrieved: false,
                submitting: false,
                saveComplete: false,
                availablePublicUrl: true,

                popularGenres: [] as string[],
                selectedGenres: [] as any[],

                showDeleteAccountModal: false,
                deleteInputStr: '',

                showEmailVerifyModal: false,
                showPhoneVerifyModal: false,
                showPhoneVerificationCodeOnly: false,
                showFlaggedChannelAlert: '', // email or phone string
                flaggedEmailAlertText: '',

                countryCodes: ['au', 'ca', 'gb', 'in', 'nz', 'us'] as string[],

                //
                // The currentImage can be the profile imageUrl or a base64 string
                // containing the image the user wants to upload and save as their
                /// new profile avatar.
                //
                currentImage: '',
                currentImageName: '',
                currentImageType: '',
                saveNewAvatar: false,

                //
                // Hack for backdrop testing
                //
                currentBackdropImage: '',
                currentBackdropImageName: '',
                currentBackdropImageType: '',
                saveNewBackdrop: false,
                deleteBackdrop: false,

                // Social Media v-model storage
                socialMediaData: [] as SocialMediaItem[],
                showAllSocialMedia: false,
            };
        },

        mounted() {
            this.fetchProfile();
        },

        computed: {
            isDirty(): boolean {
                const hasChanges =
                    this.saveNewAvatar ||
                    this.saveNewBackdrop ||
                    this.deleteBackdrop ||
                    this.origProfile.name !== this.profile.name ||
                    this.origProfile.email !== this.profile.email ||
                    this.origPhone !== this.newPhone ||
                    this.origProfile.publicUrl !== this.profile.publicUrl ||
                    this.origProfile.tagline !== this.profile.tagline ||
                    this.socialMediaDirty ||
                    this.origProfile.countryCode !== this.profile.countryCode ||
                    this.origProfile.streamingCountryCode !== this.profile.streamingCountryCode ||
                    !Utils.deepEqual(this.origProfile.favoriteGenres, this.profile.favoriteGenres) ||
                    this.allowAnalyticsIsDifferent;

                this.$store.commit('setSettingsDirty', hasChanges); // TODO: DELETE all this in store, etc.

                this.$emit('set-settings-dirty', hasChanges);
                return hasChanges;
            },

            // orig value would be undefined if not set, so we cannot just compare the two values
            allowAnalyticsIsDifferent(): boolean {
                if (this.origProfile.allowAnalytics === undefined) {
                    return this.profile.allowAnalytics === true;
                }

                return this.origProfile.allowAnalytics !== this.profile.allowAnalytics;
            },

            getSocialMediaList(): SocialMediaType[] {
                return constants.SOCIAL_MEDIA_TYPES;
            },

            //
            //
            //
            socialMediaDirty(): boolean {
                for (const item of this.socialMediaData) {
                    //
                    // Empty value and missing from socialAccounts is the same value
                    //
                    if (item.value === '' && (!this.origProfile || !this.origProfile.socialAccounts)) {
                        continue;
                    }

                    if (!this.origProfile || !this.origProfile.socialAccounts) {
                        return true; // We have an item, but nothing in origProfile
                    } else {
                        let foundMatch = false;
                        for (const profileItem of this.origProfile.socialAccounts) {
                            if (item.platform === profileItem.platform) {
                                if (item.value !== profileItem.value) {
                                    return true;
                                }

                                foundMatch = true;
                            }
                        }
                        if (!foundMatch && item.value !== '') {
                            return true;
                        }
                    }
                }

                return false;
            },

            moreSocialMediaOptions(): boolean {
                if (
                    !this.showAllSocialMedia &&
                    (!this.profile ||
                        !this.profile.socialAccounts ||
                        this.profile.socialAccounts.length !== this.socialMediaData.length)
                ) {
                    return true;
                }

                return false;
            },

            // orig value would be undefined if not set, so we cannot just compare the two values
            allowEmailIsDifferent(): boolean {
                if (this.origProfile.allowEmail === undefined) {
                    return this.profile.allowEmail === true;
                }

                return this.origProfile.allowEmail !== this.profile.allowEmail;
            },

            // orig value would be undefined if not set, so we cannot just compare the two values
            allowPushIsDifferent(): boolean {
                if (this.origProfile.allowPush === undefined) {
                    return this.profile.allowPush === true;
                }

                return this.origProfile.allowPush !== this.profile.allowPush;
            },

            nameValid(): boolean {
                return this.profile.name !== undefined && !validator.isEmpty(this.profile.name);
            },
            publicUrlValid(): boolean {
                return (
                    this.profile.publicUrl !== undefined &&
                    !validator.isEmpty(this.profile.publicUrl) &&
                    this.profile.publicUrl.length >= SharedConstants.MIN_USER_PUBLIC_URL_LENGTH &&
                    Utils.validUrlName(this.profile.publicUrl) &&
                    this.availablePublicUrl
                );
            },
            emailValid(): boolean {
                // Empty email is valid if there's a phone
                if ((this.profile.email === undefined || this.profile.email === '') && this.newPhone !== '') {
                    return true;
                }

                return this.profile.email !== undefined && validator.isEmail(this.profile.email);
            },

            phoneValid(): boolean {
                // Empty phone is valid if there's an email
                if (this.newPhone === '' && this.profile.email !== undefined && this.profile.email !== '') {
                    return true;
                }

                const val = this.newPhone.replace(/[\s\/]/g, ''); // Trim isn't good enough for validator.
                return validator.isMobilePhone(val, 'any');
            },

            publicUrlStr(): string {
                return (
                    'Unique Url name for yourself (min ' +
                    SharedConstants.MIN_USER_PUBLIC_URL_LENGTH +
                    ' alphanumeric, no spaces)'
                );
            },

            //
            // Display the invalid-feedback for publicUrl.  We could add more
            // error strings, but for now we only display an error if the publicUrl
            // is already in use by another user.  The other errors (min length, bad
            // characters) will turn the input red, and there's a bit of descriptive
            // text that will help the user.
            //
            publicUrlInvalidText(): string {
                const val = this.profile.publicUrl;
                if (!this.availablePublicUrl) {
                    return '"' + val + '" has already been taken...';
                }

                return '';
            },

            // TODO: implement phone
            getFlaggedChannelType(): string {
                return SharedConstants.CHANNEL_EMAIL;
            },

            getFlaggedChannelReason(): string {
                return this.profile.emailFlagged as string;
            },

            // Get a comma separate list of the user's favorite genres.
            getFavoriteGenres(): string {
                if (!this.profile.favoriteGenres) {
                    return '';
                }

                let ret = '';
                for (const genre of this.profile.favoriteGenres) {
                    if (ret !== '') {
                        ret += ', ';
                    }

                    ret += genre;
                }

                return ret;
            },
        },

        methods: {
            showSocialMediaType(platform: string): boolean {
                const idx = constants.SOCIAL_MEDIA_HASH.get(platform);
                if (this.socialMediaData[idx].value !== '') {
                    return true;
                }

                if (this.profile && this.profile.socialAccounts) {
                    for (const item of this.profile.socialAccounts) {
                        if (item.platform === platform && item.value !== '') {
                            return true;
                        }
                    }
                }

                return false;
            },

            mediaLinkValid(idx: number): boolean {
                if (idx < 0 || idx >= this.socialMediaData.length) {
                    logInvalidParams(this.$options.name, 'mediaLinkValid');
                    return false;
                }

                const userValue = this.socialMediaData[idx].value;
                if (userValue === undefined || userValue === '') {
                    return true; // undefined or blank is fine.
                }

                const platform = this.socialMediaData[idx].platform;
                return Utils.socialMediaInputValid(platform as string, userValue);
            },

            socialMediaInvalidText(idx: number): string {
                if (idx < 0 || idx >= this.socialMediaData.length) {
                    logInvalidParams(this.$options.name, 'socialMediaInvalidText');
                    return '';
                }

                // If linkedin or personal, we want a full URL (take what's given)
                const platform = this.socialMediaData[idx].platform;
                if (platform === SharedConstants.SOCIAL_LINKEDIN || platform === SharedConstants.SOCIAL_PERSONAL) {
                    return 'Please enter a valid URL';
                }

                return 'Please enter your valid username';
            },

            //
            // Called when the image loader is done loading, resizing, and reorienting
            // a new image from the user.  We only need to set currentImage, which will
            // be the base64 string, in its entirety.
            //
            // We also store the the image name and type from fileData, since we need some
            // of that info for uploading the image to the server.
            //
            setImage(fileData: any) {
                if (
                    !fileData ||
                    !fileData.dataUrl ||
                    fileData.dataUrl === '' ||
                    !fileData.info ||
                    !fileData.info.name ||
                    fileData.info.name === '' ||
                    fileData.info.type === ''
                ) {
                    log('Invalid data from image uploader, in setImage()');
                    return;
                }

                this.currentImage = fileData.dataUrl; // Keep 'data:' at the front, so img can display
                this.currentImageName = fileData.info.name;
                this.currentImageType = fileData.info.type;
                this.saveNewAvatar = true;
            },

            setBackdropImage(fileData: any) {
                if (
                    !fileData ||
                    !fileData.dataUrl ||
                    fileData.dataUrl === '' ||
                    !fileData.info ||
                    !fileData.info.name ||
                    fileData.info.name === '' ||
                    fileData.info.type === ''
                ) {
                    log('Invalid data from backdrop uploader, in setBackdropImage()');
                    return;
                }

                this.currentBackdropImage = fileData.dataUrl; // Keep 'data:' at the front, so img can display
                this.currentBackdropImageName = fileData.info.name;
                this.currentBackdropImageType = fileData.info.type;
                this.saveNewBackdrop = true;
                this.deleteBackdrop = false;
            },

            removeBackdropImage() {
                this.deleteBackdrop = true;
                this.currentBackdropImage = '';
                this.currentBackdropImageName = '';
                this.currentBackdropImageType = '';
            },

            async fetchProfile() {
                try {
                    this.profile = await ApiUtils.apiWrapper(UserService.getUserAndProfile);
                    this.origProfile = { ...this.profile }; // shallow copy

                    // Copy in social media, or create empty one
                    this.socialMediaData = [] as SocialMediaItem[];
                    for (const item of constants.SOCIAL_MEDIA_TYPES) {
                        this.socialMediaData.push({ platform: item.platform, value: '' });
                    }

                    if (this.profile.socialAccounts) {
                        this.origProfile.socialAccounts = [...this.profile.socialAccounts];
                        for (const profileItem of this.profile.socialAccounts) {
                            const idx = constants.SOCIAL_MEDIA_HASH.get(profileItem.platform);
                            if (this.socialMediaData[idx].platform === profileItem.platform) {
                                this.socialMediaData[idx].value = profileItem.value;
                            }
                        }
                    }

                    this.updateStoreWithCurrentProfile();

                    this.newPhone = Utils.FormatPhoneNumberForDisplay(
                        this.profile.phone,
                        this.$store.getters.getDeviceRegionCode,
                    );
                    this.origPhone = this.newPhone;

                    //
                    // Set the currentImage to the profile imageUrl just retrieved.
                    // The currentImage can be the profile URL or a base64 string
                    // containing the image the user wants to upload and save as their
                    /// new profile avatar.
                    //
                    if (this.profile.cachedImageUrl) {
                        this.currentImage = this.profile.cachedImageUrl;
                    } else if (this.profile.imageUrl) {
                        this.currentImage = this.profile.imageUrl;
                    }

                    // Backdrop hack
                    if (this.profile.profileBackdrop) {
                        if (this.profile.profileBackdrop.cachedImageUrl) {
                            this.currentBackdropImage = this.profile.profileBackdrop.cachedImageUrl;
                        } else if (this.profile.profileBackdrop.imageUrl) {
                            this.currentBackdropImage = this.profile.profileBackdrop.imageUrl;
                        }
                    }

                    const ret = await ApiUtils.apiWrapper(ContentService.getPopularMoveAndShowGenres);
                    if (ret.list) {
                        this.popularGenres = ret.list.sort();

                        //
                        // this.origProfile.favoriteGenres is not sort, but we want
                        // it to be, for comparison (isDirty).
                        //
                        if (this.origProfile.favoriteGenres) {
                            this.origProfile.favoriteGenres.sort();
                        }
                    } else {
                        this.popularGenres = [] as string[];
                    }

                    // Set default (checked) genres
                    if (this.popularGenres && this.profile.favoriteGenres) {
                        for (let i = 0; i < this.popularGenres.length; i++) {
                            this.selectedGenres[i] = this.profile.favoriteGenres.includes(this.popularGenres[i]);
                        }
                    }

                    this.profileRetrieved = true;
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            inputsValid(): boolean {
                let idx = 0;
                for (const item of this.socialMediaData) {
                    if (!this.mediaLinkValid(idx)) {
                        return false;
                    }

                    idx++;
                }

                //
                // At least one channel (email/phone) needs to be valid.  If both set, they must both be valid
                //
                let oneChannelValid = false;
                if (this.profile.email !== '' && this.newPhone !== '') {
                    oneChannelValid = this.emailValid && this.phoneValid;
                } else if (this.profile.email !== '') {
                    oneChannelValid = this.emailValid;
                } else if (this.newPhone !== '') {
                    oneChannelValid = this.phoneValid;
                }

                return this.isDirty && this.nameValid && this.publicUrlValid && oneChannelValid;
            },

            alertCountDownChanged(dismissCountDown: number) {
                this.dismissCountDown = dismissCountDown;
                if (dismissCountDown === 0) {
                    this.submitting = false;
                }
            },

            showAlert(message: string, alertType: string, perm: boolean) {
                this.permanent = perm;
                if (!perm) {
                    this.dismissCountDown = 5;
                }
                this.alertMessage = message;
                this.alertType = alertType;
            },

            closeAlert() {
                this.dismissCountDown = 0;
                this.permanent = false;
                this.submitting = false;
            },

            //
            // Send the new profile to the server.  The server is not doing the updates
            // in one transaction, so pieces could change, even when something fails.
            // If there's a failure, retrieve the profile again, so we update the
            // fields that did work.
            //
            async handleSubmit(event: Event) {
                if (this.submitting) {
                    return;
                }

                this.closeAlert();
                this.saveComplete = false;
                this.submitting = true;

                const newEmailInCaseOfConflict = this.profile.email;
                const newPhoneInCaseOfConflict = this.newPhone;
                const originalAllowAnalytics = this.origProfile.allowAnalytics;

                try {
                    if (this.saveNewAvatar) {
                        const res = await ApiUtils.apiWrapper(MediaService.uploadMedia, {
                            fileName: this.currentImageName,
                            fileType: this.currentImageType,
                            contents: this.currentImage.split(',')[1],
                        });

                        if (res.warningCode === SharedConstants.MEDIA_INVALID_EXTENSION) {
                            const ext = this.getFileExtension(this.currentImageName);
                            this.showAlert('Invalid file extension (' + ext + ')', 'danger', true);
                            return;
                        } else if (res.media) {
                            // Requires setting so Save below actually save the new media Id, etc.
                            this.profile.mediaId = res.media.id;
                            this.profile.imageUrl = res.media.url;
                        }
                    }

                    // Backdrop hack
                    if (!this.deleteBackdrop && this.saveNewBackdrop) {
                        const res = await ApiUtils.apiWrapper(MediaService.uploadMedia, {
                            fileName: this.currentBackdropImageName,
                            fileType: this.currentBackdropImageType,
                            contents: this.currentBackdropImage.split(',')[1],
                        });

                        if (res.warningCode === SharedConstants.MEDIA_INVALID_EXTENSION) {
                            const ext = this.getFileExtension(this.currentImageName);
                            this.showAlert('Invalid backdrop file extension (' + ext + ')', 'danger', true);
                            return;
                        } else if (res.media) {
                            // Requires setting so Save below actually save the new media Id, etc.
                            if (!this.profile.profileBackdrop) {
                                this.profile.profileBackdrop = {} as GenericImage;
                            }
                            this.profile.profileBackdrop.mediaId = res.media.id;
                            this.profile.profileBackdrop.imageUrl = res.media.url;
                        }
                    } else if (this.deleteBackdrop && this.profile.profileBackdrop) {
                        this.profile.profileBackdrop.mediaId = 0;
                    }

                    // Normalize phone number format.
                    this.profile.phone = Utils.FormatPhoneNumberForStorage(
                        this.newPhone,
                        this.$store.getters.getDeviceRegionCode,
                    );

                    // Copy in social media content.
                    this.profile.socialAccounts = [] as SocialMediaItem[];
                    for (const item of this.socialMediaData) {
                        item.value = Utils.cleanSocialMediaLink(item.platform as string, item.value as string);
                        this.profile.socialAccounts.push(item);
                    }

                    this.profile = await ApiUtils.apiWrapper(UserService.separatelySaveUserAndProfile, this.profile);
                    this.origProfile = { ...this.profile }; // shallow copy

                    // Copy in social media, or create empty one
                    this.socialMediaData = [] as SocialMediaItem[];
                    for (const item of constants.SOCIAL_MEDIA_TYPES) {
                        this.socialMediaData.push({ platform: item.platform, value: '' });
                    }
                    if (this.profile.socialAccounts) {
                        this.origProfile.socialAccounts = [...this.profile.socialAccounts];
                        for (const profileItem of this.profile.socialAccounts) {
                            const idx = constants.SOCIAL_MEDIA_HASH.get(profileItem.platform);
                            if (this.socialMediaData[idx].platform === profileItem.platform) {
                                this.socialMediaData[idx].value = profileItem.value;
                            }
                        }
                    }

                    this.updateStoreWithCurrentProfile();
                    if (this.profile.imageUrl || this.profile.cachedImageUrl) {
                        if (this.profile.cachedImageUrl) {
                            this.currentImage = this.profile.cachedImageUrl;
                        } else {
                            this.currentImage = this.profile.imageUrl as string;
                        }
                        this.saveNewAvatar = false;
                        this.currentImageName = '';
                        this.currentImageType = '';
                    }

                    // Backdrop hack
                    if (
                        this.profile.profileBackdrop &&
                        (this.profile.profileBackdrop.imageUrl || this.profile.profileBackdrop.cachedImageUrl)
                    ) {
                        if (this.profile.profileBackdrop.cachedImageUrl) {
                            this.currentBackdropImage = this.profile.profileBackdrop.cachedImageUrl;
                        } else {
                            this.currentBackdropImage = this.profile.profileBackdrop.imageUrl as string;
                        }
                        this.currentBackdropImageName = '';
                        this.currentBackdropImageType = '';
                    }

                    this.saveNewBackdrop = false;
                    this.deleteBackdrop = false;

                    //
                    // Before updating phone, see if we need to bring up the phone verification modal
                    //
                    if (this.newPhone !== this.origPhone) {
                        this.showPhoneVerificationCodeOnly = true;
                    }

                    this.newPhone = Utils.FormatPhoneNumberForDisplay(
                        this.profile.phone,
                        this.$store.getters.getDeviceRegionCode,
                    );
                    this.origPhone = this.newPhone;

                    //
                    // If the allowAnalytics changed, tell analytics
                    //
                    if (originalAllowAnalytics !== this.profile.allowAnalytics) {
                        if (this.profile.allowAnalytics) {
                            analytics.turnOnUserAnalytics();
                        } else {
                            analytics.turnOffUserAnalytics();
                        }
                    }

                    analytics.logAppInteraction(analytics.ANALYTICS_ACTION_UPDATE_PROFILE, this.profile.publicUrl);

                    //
                    // If the publicUrl changed, route to the new location.  This should
                    // replace the URL with the new @publicUrl name, and continue on
                    // to show the save message.
                    //
                    if (this.$router.currentRoute.params.publicUrl !== this.profile.publicUrl) {
                        this.$router.replace({
                            name: constants.ROUTE_USER_HOME,
                            params: { publicUrl: this.profile.publicUrl as string },
                        });
                    }

                    this.saveComplete = true;
                    this.showAlert('Your profile has been updated.', 'success', false);
                } catch (error: any) {
                    //
                    // If the error was a conflict (409), and it's because of an existing
                    // email, tell user.
                    //
                    let commonError = true;
                    if (error.response.status === 409) {
                        commonError = false;
                        if (error.response.data.message === SharedConstants.CHANNEL_EMAIL) {
                            this.showAlert(newEmailInCaseOfConflict + ' is already in use', 'danger', true);
                        } else if (error.response.data.message === SharedConstants.CHANNEL_PHONE) {
                            this.showAlert(
                                Utils.FormatPhoneNumberForDisplay(
                                    newPhoneInCaseOfConflict,
                                    this.$store.getters.getDeviceRegionCode,
                                ) + ' is already in use',
                                'danger',
                                true,
                            );
                        }
                    }

                    //
                    // Get the profile and update the store to make sure we updated the fields that
                    // did update in the DB correctly.  I don't like an async function inside a catch.
                    // TODO: Ask Derek if this is ok
                    //
                    this.fetchProfile();

                    this.submitting = false;
                    if (commonError) {
                        Utils.CommonErrorHandler(error);
                    }
                }
            },

            //
            // Update the store with the new profile values.  Only needs to be
            // called after we submit the new profile, or if there's an error submitting
            // the new profile and we refetched the profile to pick up some of the new changes.
            // Also need to update if EventBus told us to refresh.
            //
            updateStoreWithCurrentProfile() {
                this.$store.commit('setSessionDataWithUpdatedProfile', {
                    displayName: this.profile.name,
                    email: this.profile.email,
                    emailVerified: this.profile.emailVerified,
                    phone: this.profile.phone,
                    phoneVerified: this.profile.phoneVerified,
                    avatarUrl: this.profile.imageUrl,
                    cachedAvatarUrl: this.profile.cachedImageUrl,
                    publicUrl: this.profile.publicUrl,
                    countryCode: this.profile.countryCode,
                    streamingCountryCode: this.profile.streamingCountryCode,
                });
            },

            getFileExtension(filePath: string): string {
                return filePath.slice((Math.max(0, filePath.lastIndexOf('.')) || Infinity) + 1);
            },

            badChannelClick() {
                if (this.profile && this.profile.emailFlagged) {
                    let reason = '';
                    switch (this.profile.emailFlagged) {
                        case 'bounce':
                            reason = 'flagged due to one or more bounced emails.';
                            break;
                        case 'spam':
                            reason =
                                'flagged due to emails from ' +
                                constants.COMPANY_DOMAIN +
                                ' to this address being marked as spam.';
                            break;
                        case 'complaint':
                            reason = 'flagged due to a complaint from another user.';
                            break;
                    }

                    let alertMsg = 'Your email (<b>' + this.profile.email + '</b>) has been ' + reason;
                    alertMsg += ' You will no longer receive email to this address until it is updated.';
                    alertMsg +=
                        '<br><br>Please contact COMPANY_DOMAIN support if you have ' +
                        'any questions (contact@COMPANY_DOMAIN).';
                    this.flaggedEmailAlertText = alertMsg;

                    this.showFlaggedChannelAlert = SharedConstants.CHANNEL_EMAIL;
                }
            },

            // TODO: Change name of modal and text.

            badPhoneClick() {
                if (this.profile && this.profile.phoneFlagged) {
                    let reason = '';
                    switch (this.profile.phoneFlagged) {
                        case 'bounce':
                            reason = 'flagged due to one or more bounced SMS text messages.';
                            break;
                        case 'spam':
                            reason = 'flagged due to SMS texts from Verified to this address being marked as spam.';
                            break;
                        case 'complaint':
                            reason = 'flagged due to a complaint from the phone owner (theoretically that was you).';
                            break;
                    }

                    const phone = Utils.FormatPhoneNumberForDisplay(
                        this.profile.phone,
                        this.$store.getters.getDeviceRegionCode,
                    );
                    let alertMsg = 'Your phone <b>' + phone + '</b> has been ' + reason;
                    alertMsg += ' You will no longer receive sms messages to this number until it is updated.';
                    alertMsg +=
                        '<br><br>Please contact ' +
                        constants.COMPANY_DOMAIN +
                        ' support if you have ' +
                        'any questions (contact@COMPANY_DOMAIN).';
                    this.flaggedEmailAlertText = alertMsg;

                    this.showFlaggedChannelAlert = SharedConstants.CHANNEL_PHONE;
                }
            },

            //
            // Normal signout means we want to signout from firebase and our server; however,
            // if coming from a deleteUser call, we don't want to signout from the server since
            // there's no user anymore.  We do need all other signouts to happen though.
            //
            signout(signoutFromServer: boolean) {
                signout(this.$store.getters.getPublicUrl, signoutFromServer, true);
            },

            //
            // Deleting a user account will also do a sign out on the server, clearing
            // the context.
            //
            // TODO: This does not delete the firebase account.  Maybe it should.
            //
            async okToDelete() {
                try {
                    const publicUrl = this.$store.getters.getPublicUrl;

                    await ApiUtils.apiWrapper(UserService.deleteUserV2);

                    analytics.logAppInteraction(analytics.ANALYTICS_ACTION_DELETE_USER, publicUrl);
                    this.signout(false);
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }

                this.showDeleteAccountModal = false;
            },

            //
            // Ask the server if this publicUrl is available.  The vanity URL
            // is unique across all users.
            //
            async publicUrlChanged(newVal: string) {
                if (newVal === this.origProfile.publicUrl) {
                    this.availablePublicUrl = true;
                    return;
                }

                // If it's already invalid, no need to hit server.
                if (!this.publicUrlValid) {
                    this.availablePublicUrl = true;
                    return;
                }

                try {
                    const ret = await ApiUtils.apiWrapper(UserService.isPublicUrlAvailable, newVal);
                    this.availablePublicUrl = ret !== undefined && ret.value === true;
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            // The genre menu selected items changed
            applyGenreFilters(data: any) {
                const selectedCheckboxes = data.top;
                this.selectedGenres = Utils.deepCopy(selectedCheckboxes);

                // Set profile.favoriteGenres
                const newGenreList = [] as string[];
                for (let i = 0; i < this.popularGenres.length; i++) {
                    if (selectedCheckboxes[i]) {
                        newGenreList.push(this.popularGenres[i]);
                    }
                }

                Vue.set(this.profile, 'favoriteGenres', newGenreList);
            },
        },
    });
