//
// Copyright 2019-2022 Reki LLC - All rights reserved.
// File: VueConstants.ts
// Project: vue
//
import Vue from 'vue';

// @ts-ignore
import * as constants from '@/tsfiles/constants';
import { SharedConstants } from 'api';


//
// Mixin of constants (SharedConstants and UI specific).  The SharedConstants map
// to the server API values, generated via servicedef (similar to protobuf/grpc apis).
//
// This is done as a mixin to make access inside templates easy. Theoretically a plugin
// could work as well.
//
// There's no reason to put any values in here if they will never used be used
// inside Vue templates.
//
// The convention at the moment is to use 'shareconstants.' inside the view code,
// even though they are accessible from 'this.'.
//
const VueConstants = Vue.extend({
    data() {
        return {
            //////////////////////////////////////////////////////////////////////////
            // General mixin constants NOT synced with server API.
            //////////////////////////////////////////////////////////////////////////
            COMPANY_NAME: constants.COMPANY_NAME,
            COMPANY_DOMAIN: constants.COMPANY_DOMAIN,
            FULL_MARKETING_DOMAIN: constants.FULL_MARKETING_DOMAIN,

            FAKE_NOTIFICATION_EMAIL_PHONE_VERIFICATION_REQUIRED:
                constants.FAKE_NOTIFICATION_EMAIL_PHONE_VERIFICATION_REQUIRED,

            // Most common routing names, needed by various components.
            ROUTE_MARKETING: constants.ROUTE_MARKETING,
            ROUTE_FIREBASE_SIGNIN: constants.ROUTE_FIREBASE_SIGNIN,
            ROUTE_USER_HOME: constants.ROUTE_USER_HOME,
            ROUTE_USER_TIMELINE: constants.ROUTE_USER_TIMELINE,
            ROUTE_USER_LISTS: constants.ROUTE_USER_LISTS,
            ROUTE_USER_FOLLOWING: constants.ROUTE_USER_FOLLOWING,
            ROUTE_USER_SETTINGS: constants.ROUTE_USER_SETTINGS,
            ROUTE_USER_PROFILE: constants.ROUTE_USER_PROFILE,
            ROUTE_USER_NOTIFICATIONS: constants.ROUTE_USER_NOTIFICATIONS,
            ROUTE_USER_BLOCKED: constants.ROUTE_USER_BLOCKED,
            ROUTE_USER_INVITES: constants.ROUTE_USER_INVITES,
            ROUTE_CONTENT_DETAILS: constants.ROUTE_CONTENT_DETAILS,
            ROUTE_LIST_CONTENTS: constants.ROUTE_LIST_CONTENTS,
            ROUTE_CONTENT_COMMENTS: constants.ROUTE_CONTENT_COMMENTS,
            ROUTE_CONTENT_CHAT: constants.ROUTE_CONTENT_CHAT,
            ROUTE_CREW_CONTENT: constants.ROUTE_CREW_CONTENT,
            ROUTE_SEARCH_RESULTS: constants.ROUTE_SEARCH_RESULTS,
            ROUTE_EXPLORE: constants.ROUTE_EXPLORE,
            ROUTE_SEARCH: constants.ROUTE_SEARCH,

            // Single menu item not a ROUTE
            MENU_ADD: constants.MENU_ADD,


            //////////////////////////////////////////////////////////////////////////
            // UI/Server synced constants
            //////////////////////////////////////////////////////////////////////////

            INVITE_SUPER_ADMIN: SharedConstants.INVITE_SUPER_ADMIN,

            DEVICE_TYPE_IOS: SharedConstants.DEVICE_TYPE_IOS,
            DEVICE_TYPE_ANDROID: SharedConstants.DEVICE_TYPE_ANDROID,

            CHANNEL_EMAIL: SharedConstants.CHANNEL_EMAIL,
            CHANNEL_PHONE: SharedConstants.CHANNEL_PHONE,

            CONTENT_FORMAT_MOVIE: SharedConstants.CONTENT_FORMAT_MOVIE,
            CONTENT_FORMAT_SHOW: SharedConstants.CONTENT_FORMAT_SHOW,


            // General warnings
            WARNING_RESOURCE_USED: SharedConstants.WARNING_RESOURCE_USED,
            WARNING_RESOURCE_EXPIRED: SharedConstants.WARNING_RESOURCE_EXPIRED,
            WARNING_RESOURCE_NOT_FOUND: SharedConstants.WARNING_RESOURCE_NOT_FOUND,

            // Notification contants (most likely from notification db table).
            NOTIFICATION_VERIFY_EMAIL: SharedConstants.NOTIFICATION_VERIFY_EMAIL,
            NOTIFICATION_VERIFY_PHONE: SharedConstants.NOTIFICATION_VERIFY_PHONE,
            NOTIFICATION_EMAIL_FLAGGED: SharedConstants.NOTIFICATION_EMAIL_FLAGGED,
            NOTIFICATION_PHONE_FLAGGED: SharedConstants.NOTIFICATION_PHONE_FLAGGED,

            // Sorting and Filtering constants
            SORT_BY_CREATED_DATE: SharedConstants.SORT_BY_CREATED_DATE,
            SORT_BY_UPDATED_DATE: SharedConstants.SORT_BY_UPDATED_DATE,
            SORT_BY_RATING: SharedConstants.SORT_BY_RATING,
            SORT_BY_RUNLENGTH: SharedConstants.SORT_BY_RUNLENGTH,
            SORT_BY_YEAR: SharedConstants.SORT_BY_YEAR,
            FILTER_BY_GENRE: SharedConstants.FILTER_BY_GENRE,
            FILTER_BY_SOURCE: SharedConstants.FILTER_BY_SOURCE,

            // Social media platforms
            SOCIAL_PERSONAL: SharedConstants.SOCIAL_PERSONAL,
            SOCIAL_LINKEDIN: SharedConstants.SOCIAL_LINKEDIN,
            SOCIAL_TWITTER: SharedConstants.SOCIAL_TWITTER,
            SOCIAL_TIKTOK: SharedConstants.SOCIAL_TIKTOK,
            SOCIAL_FACEBOOK: SharedConstants.SOCIAL_FACEBOOK,
            SOCIAL_INSTAGRAM: SharedConstants.SOCIAL_INSTAGRAM,
            SOCIAL_SPOTIFY: SharedConstants.SOCIAL_SPOTIFY,
            SOCIAL_YOUTUBE: SharedConstants.SOCIAL_YOUTUBE,
            SOCIAL_PINTEREST: SharedConstants.SOCIAL_PINTEREST,
            SOCIAL_MEDIUM: SharedConstants.SOCIAL_MEDIUM,
            SOCIAL_GITHUB: SharedConstants.SOCIAL_GITHUB,
            SOCIAL_SOUNDCLOUD: SharedConstants.SOCIAL_SOUNDCLOUD,
        };
    },
});

export default VueConstants;
