
import Vue from 'vue';

export default Vue.extend({
    name: 'ListFansAndItems',

    components: {},

    props: {
        numFollowers: {
            type: Number,
            required: false,
            default: 0,
        },

        numItems: {
            type: Number,
            required: false,
            default: 0,
        },
    },

    data() {
        return {};
    },

    watch: {},

    computed: {},

    methods: {},
});
