
    import mixins from 'vue-typed-mixins';
    import VueConstants from '@/components/VueConstants';
    import StarRating from '@/components/uiutils/StarRating.vue';

    export default mixins(VueConstants).extend({
        name: 'RecommendationEditModal',

        components: {
            'star-rating': StarRating,
        },

        props: {
            contentName: {
                type: String,
                required: true,
            },

            currentRecommendationComment: {
                type: String,
                required: true,
            },

            currentRecommendationRating: {
                type: Number,
                required: true,
            },
        },

        data() {
            return {
                newComment: this.currentRecommendationComment,
                newRating: this.currentRecommendationRating,
            };
        },

        computed: {
            getModalTitle(): string {
                return 'Edit Comment: ' + this.contentName;
            },

            newCommentValid(): boolean {
                return this.newComment === '' || this.newComment.trim() !== '';
            },

            inputValid(): boolean {
                // Only rating is required now
                if (this.newRating === 0) {
                    return false;
                }

                return (
                    this.newCommentValid &&
                    (this.newComment !== this.currentRecommendationComment ||
                    this.newRating !== this.currentRecommendationRating)
                );
            },
        },

        methods: {
            submit() {
                this.$emit('save-recommendation', {
                    comment: this.newComment.trim(),
                    rating: this.newRating,
                });
            },
        },
    });
