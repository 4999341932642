
import Vue, { PropOptions } from 'vue';
import mixins from 'vue-typed-mixins';
import EventBus from '@/eventbus';
import { ApiUtils } from '@/tsfiles/apiutils';
import * as analytics from '@/tsfiles/analytics';
import * as constants from '@/tsfiles/constants';
import VueConstants from '@/components/VueConstants';
import { SortMenuItem, RecommendationUpdateEmit } from '@/tsfiles/interfaces';
import { logInvalidParams, logInvalidResponse } from '@/tsfiles/errorlog';
import { Utils } from '@/tsfiles/utils';
import AddContent from '@/components/content/AddContent.vue';
import Avatar from '@/components/Avatar.vue';
import ContentRow from '@/components/content/ContentRowV2.vue';
import SortMenu from '@/components/uiutils/SortMenu.vue';
import FilterMenu from '@/components/uiutils/FilterMenu.vue';
import AddRecommendationModal from '@/components/content/AddRecommendationModal.vue';
import RecommendationEdit from '@/components/content/RecommendationEditModal.vue';
import TimelineListRow from '@/components/lists/TimelineListRow.vue';
import ListAddRemoveContentModal from '@/components/lists/ListAddRemoveContentModal.vue';
import ModalAlert from '@/generic-modals/ModalAlert.vue';
import {
    ContentService,
    ContentV2,
    ListService,
    TimelineList,
    SharedConstants,
    GenericPageRetrieval,
    FilterOption,
    UserRecommendation,
    GenresAndSourcesResponse,
    PagedContent,
} from 'api';

export default mixins(VueConstants).extend({
    name: 'UserTimeline',

    components: {
        'add-content': AddContent,
        'content-row': ContentRow,
        'url-avatar': Avatar,
        'sort-menu': SortMenu,
        'filter-menu': FilterMenu,
        'add-recommendation': AddRecommendationModal,
        'recommendation-edit': RecommendationEdit,
        'list-add-remove-content': ListAddRemoveContentModal,
        'timeline-list-row': TimelineListRow,
        'modal-alert': ModalAlert,
    },

    props: {},

    data() {
        return {
            initialFetchComplete: false,
            content: [] as ContentV2[],
            userWantsToAddContent: false,

            //
            // List that will get inserted into the timeline.  timelineInserts is
            // an array of number that will be mostly empty, but used to know where
            // to do the list insert.  timelineInserts will be as large a all the
            // possible content recommendations, and only have a timelineLists index
            // where it should get inserted before.
            //
            timelineLists: [] as TimelineList[],
            timelineInserts: undefined as any[] | undefined,

            userHasOwnRecommendations: false,
            ownRecommendationsCheckbox: false,

            userHasHiddenRecommendations: false,
            hiddenCheckbox: false,
            moviesOnlyCheckbox: false,
            mineOrWorldRecommendations: 'mine',
            mineOrWorldRecommendationRadios: [
                { text: 'My Feed', value: 'mine' },
                { text: 'World Feed', value: 'world' },
            ],

            //
            // Three string arrays used for filterBy when retrieving content.  These
            // container all the genres and sources for the entire list of
            // followee recommendations.
            //
            genresForFiltering: [] as string[],
            topSourcesForFiltering: [] as string[],
            allOtherSourcesForFiltering: [] as string[],

            //
            // Lists of selected items used for filtering.  They are saved
            // when the user applies genre or source filters from the FilterMenu.
            //
            selectedGenresForFiltering: [] as any[],
            selectedTopSourcesForFiltering: [] as any[],
            selectedAllOtherSourcesForFiltering: [] as any[],

            totalItems: 0,
            currentPage: 1,
            perPage: constants.RECOMMENDATIONS_PER_PAGE,

            currentSort: SharedConstants.SORT_BY_UPDATED_DATE,
            sortMenuList: [
                { name: 'Recently Recommended or Updated', value: SharedConstants.SORT_BY_UPDATED_DATE },
                { name: 'Rating', value: SharedConstants.SORT_BY_RATING },
                { name: 'Year (newest to oldest)', value: SharedConstants.SORT_BY_YEAR },
            ] as SortMenuItem[],

            genreFilterOptions: [] as FilterOption[],
            sourceFilterOptions: [] as FilterOption[],

            addRecommendation: undefined as ContentV2 | undefined,
            editRecommendation: undefined as ContentV2 | undefined,
            deleteRecommendation: undefined as ContentV2 | undefined,
            listAddRemoveContent: undefined as ContentV2 | undefined,
        };
    },

    watch: {},

    mounted() {
        this.fetchAll();
        EventBus.$on(SharedConstants.NOTIFICATION_FRIEND_RECOMMENDATION, this.fetchAll);
        EventBus.$on(SharedConstants.PUBSUB_REFRESH_FRIEND_RECOMMENDATIONS, this.fetchAll);
    },

    beforeDestroy() {
        EventBus.$off(SharedConstants.NOTIFICATION_FRIEND_RECOMMENDATION, this.fetchAll);
        EventBus.$off(SharedConstants.PUBSUB_REFRESH_FRIEND_RECOMMENDATIONS, this.fetchAll);
    },

    computed: {
        //
        // The header and footer offsets are needed to determine default height.
        //
        cssVars(): any {
            return {
                '--header-offset': constants.HEADER_HEIGHT + 'px',
                '--footer-offset': constants.FOOTER_HEIGHT + 'px',
            };
        },

        getGenreTitle(): string {
            let ret = 'Genre Filter';

            if (this.genreFilterOptions.length > 0) {
                ret += ' (' + this.genreFilterOptions.length + ')';
            }

            return ret;
        },

        getSourcesTitle(): string {
            let ret = 'Source Filter';

            if (this.sourceFilterOptions.length > 0) {
                ret += ' (' + this.sourceFilterOptions.length + ')';
            }

            return ret;
        },

        filtersApplied(): boolean {
            return (
                this.selectedGenresForFiltering.length > 0 ||
                this.selectedTopSourcesForFiltering.length > 0 ||
                this.selectedAllOtherSourcesForFiltering.length > 0
            );
        },

        //
        // We can only show the add content component if the user has a verified email or phone.
        // If they are allowed to add content, show the form if they explicitly asked to add content
        // of if there are no results at all.  The results list can be empty if all results are filtered
        // out, so we need to check for that case.  If that happens we don't want to force the
        // add content component.
        //
        showAddContent(): boolean {
            // if (!this.$store.getters.isEmailVerified && !this.$store.getters.isPhoneVerified) {
            //      return false;
            // }

            return (
                this.userWantsToAddContent ||
                (this.totalItems === 0 &&
                    !this.filtersApplied &&
                    !this.userHasHiddenRecommendations &&
                    !this.userHasOwnRecommendations)
            );
        },
    },

    methods: {
        //
        // If there are timeline list items to show, this function will
        // determine if it should be inserted before the given content recommendation.
        // If the first list item is updated before the given recommendation,
        // put it in.  If we haven't inserted any before the 6th item, put
        // it there.  Once inserted, insert after every 5 recommendations from
        // the last insert.
        //
        // We use a 'any' array to put in the list index based on the recommendation index.
        // timelineInserts can be as big as the content list (totalItems, not just the
        // current page).  When we decide we can insert a list, put that list's array
        // index into the timelineInserts array.
        //
        //
        // NOTE: Because of the way paging works, this function might be called
        // when re-rendering the existing list just before the new page is
        // retrieved.  The currentPage could be 2, but the content list is still
        // the full first page.  If we checked the idx against the totalItems after
        // accounting for the currentPage, it would be larger than totalItems.  Our
        // first logInvalidParams check is not looking for 'idx >= this.totalItems'
        // because of this case.
        //
        // WARNING: An EventBus call to FetchAll may not actually do the right thing
        // with the timelineInserts.  The timelineInserts object gets reset to
        // undefined, but it will probably get filled in here before the new content
        // comes in.  We don't reset this.content, since that will cause the page
        // to flicker (orig content goes away before new content comes in).
        //
        showTimelineListBeforeItem(idx: number): number {
            if (this.content.length === 0 || idx < 0 || this.timelineLists.length === 0) {
                logInvalidParams(this.$options.name, 'showTimelineListBeforeItem');
                return -1;
            }

            //
            // We need to account for paging.  The idx is the index into the content array for
            // the current page, not the index into the entire timelineLists array.
            //
            if (this.currentPage > 1) {
                idx = idx + (this.currentPage - 1) * constants.RECOMMENDATIONS_PER_PAGE;
            }

            //
            // Because of timing issues on paging (see NOTE above), changing the index based
            // on the page number can result in the idx being larger than the number of
            // items.  This is not an error.  Just return -1.
            //
            if (idx >= this.totalItems) {
                return -1;
            }

            //
            // If this is the first time initializing, set up timelineInserts array.
            // If already set up, meaning not undefined, then just use the value of
            // timelineInserts to determine if we should insert the list before the
            // given content idx.
            //
            if (!this.timelineInserts) {
                this.timelineInserts = [] as any[];

                if (!this.content[idx] || this.content[idx].contentId === 0) {
                    logInvalidParams(this.$options.name, 'showTimelineListBeforeItem');
                    return -1;
                }

                //
                // Loop through all the possible content items.  For time comparison, we
                // only care about content entries up to constants.TIMELINE_LIST_INSERT_FREQUENCY,
                // which would be on the first page.  Afterwards go through the remaining
                // possible content items, since we don't care about time comparisons.
                //
                let listIdx = 0;
                let contentIdx = 0;
                let listInsertFrequency = 0;
                for (; contentIdx < this.content.length; contentIdx++) {
                    const timelineList = this.timelineLists[listIdx];
                    const content = this.content[contentIdx];
                    if (
                        listIdx === 0 &&
                        timelineList.latestInsert &&
                        content.latestRecommendation &&
                        content.latestRecommendation.updatedTimestamp &&
                        timelineList.latestInsert >= content.latestRecommendation.updatedTimestamp
                    ) {
                        this.timelineInserts[contentIdx] = listIdx++;
                        listInsertFrequency = constants.TIMELINE_LIST_INSERT_FREQUENCY + contentIdx;
                    } else if (listIdx === 0 && contentIdx === constants.TIMELINE_LIST_INSERT_FREQUENCY) {
                        this.timelineInserts[contentIdx] = listIdx++;
                        listInsertFrequency = constants.TIMELINE_LIST_INSERT_FREQUENCY;
                    } else if (contentIdx % listInsertFrequency === 0) {
                        this.timelineInserts[contentIdx] = listIdx++;
                    }

                    if (listIdx >= this.timelineLists.length) {
                        break;
                    }
                }

                //
                // Now do all remaining, if there are list items left
                //
                if (listIdx < this.timelineLists.length) {
                    for (; contentIdx < this.totalItems; contentIdx++) {
                        if (contentIdx % constants.TIMELINE_LIST_INSERT_FREQUENCY === 0) {
                            this.timelineInserts[contentIdx] = listIdx++;
                        }

                        if (listIdx >= this.timelineLists.length) {
                            break;
                        }
                    }
                }
            }

            return this.timelineInserts[idx];
        },

        fetchAll() {
            //
            // WARNING: The user may have selected filtering options.  We reset
            // here, since the list can change.  If the user is in the middle
            // of filtering, this change will be jarring.  We might have to compare
            // the new set of genre and sources and leave the filters selected if they
            // still exist.  For now, just clear out so the user sees everything.
            //
            this.selectedGenresForFiltering = [] as any[];
            this.selectedTopSourcesForFiltering = [] as any[];
            this.selectedAllOtherSourcesForFiltering = [] as any[];
            this.genreFilterOptions = [] as FilterOption[];
            this.sourceFilterOptions = [] as FilterOption[];
            this.currentPage = 1;
            this.ownRecommendationsCheckbox = false;
            this.hiddenCheckbox = false;
            this.moviesOnlyCheckbox = false;
            this.timelineInserts = undefined as any[] | undefined;

            this.fetchGenresAndSources();
            this.fetchContent();
            this.fetchTimelineLists();
        },

        async fetchGenresAndSources() {
            try {
                let funcCall = ContentService.getGenresAndSourcesForTimelineRecommendations;
                if (this.mineOrWorldRecommendations === 'world') {
                    funcCall = ContentService.getGenresAndSourcesForWorldTimelineRecommendations;
                }

                const ret = await ApiUtils.apiWrapper(funcCall);

                if (ret.genres) {
                    this.genresForFiltering = ret.genres;
                } else {
                    this.genresForFiltering = [] as string[];
                }

                if (ret.topSources) {
                    this.topSourcesForFiltering = ret.topSources;
                } else {
                    this.topSourcesForFiltering = [] as string[];
                }

                if (ret.allOtherSources) {
                    this.allOtherSourcesForFiltering = ret.allOtherSources;
                } else {
                    this.allOtherSourcesForFiltering = [] as string[];
                }
            } catch (error) {
                Utils.CommonErrorHandler(error);
            }
        },

        //
        // Fetch the timeline lists, which will get injected into the recommendation
        // results using the logic in showTimelineListBeforeItem().
        //
        async fetchTimelineLists() {
            // Ignore if showing world timeline
            if (this.mineOrWorldRecommendations === 'world') {
                this.timelineLists = [] as TimelineList[];
                return;
            }

            try {
                const ret = await ApiUtils.apiWrapper(
                    ListService.getListsForTimeline,
                    constants.TIMELINE_LIST_LOOKBACK_DAYS,
                );

                if (ret.lists) {
                    this.timelineLists = ret.lists;
                } else {
                    this.timelineLists = [] as TimelineList[];
                }
            } catch (error) {
                Utils.CommonErrorHandler(error);
            }
        },

        async fetchContent() {
            try {
                const filterOptions = this.genreFilterOptions.concat(this.sourceFilterOptions);

                // Add in my own recommendations filter if not checked
                if (this.ownRecommendationsCheckbox) {
                    filterOptions.push({ type: SharedConstants.FILTER_OWN_RECOMMENDATIONS_ONLY });
                }

                // Add in hidden filter if not checked
                if (this.hiddenCheckbox) {
                    filterOptions.push({ type: SharedConstants.FILTER_HIDDEN_RECOMMENDATIONS_ONLY });
                }

                // Add in the 'movies only' if checked
                if (this.moviesOnlyCheckbox) {
                    filterOptions.push({
                        type: SharedConstants.FILTER_BY_FORMAT,
                        data: SharedConstants.FILTER_FORMAT_MOVIES,
                    });
                }

                //
                // TODO: There's a bug in the backend where it returns no results if there are only
                // hidden results.
                //

                let funcCall = ContentService.getTimelineRecommendationsV2;
                if (this.mineOrWorldRecommendations === 'world') {
                    funcCall = ContentService.getWorldTimelineRecommendations;
                }

                const ret = await ApiUtils.apiWrapper(funcCall, {
                    pageNumber: this.currentPage,
                    numberOfItems: this.perPage,
                    sortBy: [{ type: this.currentSort, descending: true }],
                    filterBy: filterOptions,
                } as GenericPageRetrieval);

                if (ret.list) {
                    this.content = ret.list;
                    this.totalItems = ret.totalItemsIrregardlessOfPaging as number;

                    // Move to top of page.  Paging from bottom will not scroll to top unless forced
                    window.scrollTo(0, 0);
                } else {
                    this.content = [] as ContentV2[];
                    this.totalItems = 0;
                }

                this.initialFetchComplete = true;

                //
                // authedUserHasHiddenRecommendations can come back even if the
                // user filtered out all results.
                //
                this.userHasHiddenRecommendations = ret.authedUserHasHiddenRecommendations !== undefined;

                //
                // authedUserHasRecommendations can come back even if the
                // user filtered out all results.
                //
                this.userHasOwnRecommendations = ret.authedUserHasRecommendations !== undefined;

                this.$emit('total-friend-items', this.totalItems);
            } catch (error) {
                Utils.CommonErrorHandler(error);
            }
        },

        pageChanged(newPage: number) {
            this.currentPage = newPage;
            this.fetchContent();
        },

        sortResults(type: string) {
            this.currentSort = type;
            this.pageChanged(1);
        },

        resetGenreFilters() {
            this.genreFilterOptions = [] as FilterOption[];
            this.fetchContent();
        },

        applyGenreFilters(data: any) {
            const selectedCheckboxes = data.top;

            this.currentPage = 1;

            //
            // Save the list so that it stays the same when reopening the filter menu.
            //
            this.selectedGenresForFiltering = Utils.deepCopy(selectedCheckboxes);

            // FilterOptions are sent to the server when fetching content.
            this.genreFilterOptions = [] as FilterOption[];

            for (let i = 0; i < this.genresForFiltering.length; i++) {
                if (selectedCheckboxes[i]) {
                    this.genreFilterOptions.push({
                        type: 'genre',
                        data: this.genresForFiltering[i],
                    } as FilterOption);
                }
            }

            this.fetchContent();
        },

        applySourceFilters(data: any) {
            const topSelected = data.top;
            const allOtherSelected = data.allOther;

            this.currentPage = 1;

            //
            // Save the list so that it stays the same when reopening the filter menu.
            //
            this.selectedTopSourcesForFiltering = Utils.deepCopy(topSelected);
            this.selectedAllOtherSourcesForFiltering = Utils.deepCopy(allOtherSelected);

            this.sourceFilterOptions = [] as FilterOption[];

            for (let i = 0; i < this.topSourcesForFiltering.length; i++) {
                if (topSelected[i]) {
                    this.sourceFilterOptions.push({
                        type: 'source',
                        data: this.topSourcesForFiltering[i],
                    } as FilterOption);
                }
            }

            for (let i = 0; i < this.allOtherSourcesForFiltering.length; i++) {
                if (allOtherSelected[i]) {
                    this.sourceFilterOptions.push({
                        type: 'source',
                        data: this.allOtherSourcesForFiltering[i],
                    } as FilterOption);
                }
            }

            this.fetchContent();
        },

        contentAdded(data: any) {
            this.userWantsToAddContent = false;
            this.fetchAll();
        },

        async saveNewRecommendation(data: any) {
            if (!this.addRecommendation) {
                logInvalidParams(this.$options.name, 'saveNewRecommendation');
                return;
            }

            const updatedText = data.comment;
            const updatedRating = data.rating;

            // Comment can be empty, but not rating
            if (!this.addRecommendation || updatedRating === 0) {
                logInvalidParams(this.$options.name, 'saveNewRecommendation');
                return;
            }

            try {
                const ret = await ApiUtils.apiWrapper(ContentService.addRecommendation, {
                    contentId: this.addRecommendation.contentId,
                    comment: updatedText,
                    rating: updatedRating,
                });

                if (!ret || !ret.recommendedBy || ret.recommendedBy.length !== 1) {
                    logInvalidResponse(this.$options.name, 'saveNewRecommendation');
                    return;
                }

                this.fetchContent();

                analytics.logAppInteraction(
                    analytics.ANALYTICS_ACTION_NEW_RECOMMENDATION,
                    this.addRecommendation.contentPublicUrl,
                );

                this.addRecommendation = undefined as ContentV2 | undefined;
            } catch (error) {
                Utils.CommonErrorHandler(error);
            }
        },

        //
        // The authenticated user is editing their own reki
        //
        async saveRecommendationUpdate(data: any) {
            const updatedText = data.comment;
            const updatedRating = data.rating;

            // Comment can be empty, but not rating
            if (!this.editRecommendation || updatedRating === 0) {
                logInvalidParams(this.$options.name, 'saveRecommendationUpdate');
                return;
            }

            try {
                const ret = await ApiUtils.apiWrapper(ContentService.updateRecommendation, {
                    contentId: this.editRecommendation.contentId,
                    comment: updatedText,
                    rating: updatedRating,
                });

                if (!ret || !ret.recommendedBy || ret.recommendedBy.length !== 1) {
                    logInvalidResponse(this.$options.name, 'saveRecommendationUpdate');
                    return;
                }

                this.fetchContent();

                analytics.logAppInteraction(
                    analytics.ANALYTICS_ACTION_UPDATE_RECOMMENDATION,
                    this.editRecommendation.contentPublicUrl,
                );

                this.editRecommendation = undefined as ContentV2 | undefined;
            } catch (error) {
                Utils.CommonErrorHandler(error);
            }
        },

        //
        // When the lower level components do like or unlike, or reply, we eventually get
        // the 'recommendation-updated' emit, with the data RecommendationUpdateEmit.  That contains the
        // new recommendation, and, if defined, the new comments totals.
        //
        recommendationUpdated(idx: number, data: RecommendationUpdateEmit) {
            if (!this.content || idx < 0 || idx >= this.content.length || !data || !data.recommendation) {
                logInvalidParams(this.$options.name, 'recommendationUpdated');
                return;
            }

            if (this.content[idx].authedUserRecommendation) {
                Vue.set(
                    this.content[idx].authedUserRecommendation as UserRecommendation,
                    'totalCommentLikes',
                    data.recommendation.totalCommentLikes,
                );

                Vue.set(
                    this.content[idx].authedUserRecommendation as UserRecommendation,
                    'authedUserLikedComment',
                    data.recommendation.authedUserLikedComment,
                );
            } else {
                if (this.content[idx].latestRecommendation) {
                    Vue.set(
                        this.content[idx].latestRecommendation as UserRecommendation,
                        'totalCommentLikes',
                        data.recommendation.totalCommentLikes,
                    );

                    Vue.set(
                        this.content[idx].latestRecommendation as UserRecommendation,
                        'authedUserLikedComment',
                        data.recommendation.authedUserLikedComment,
                    );
                }
            }

            if (data.totalComments) {
                Vue.set(this.content[idx], 'totalComments', data.totalComments);
            }

            if (data.totalFolloweeComments) {
                Vue.set(this.content[idx], 'totalFolloweeComments', data.totalFolloweeComments);
            }
        },

        getDeleteRecommendationTitle(): string {
            if (!this.deleteRecommendation) {
                logInvalidParams(this.$options.name, 'getDeleteRecommendationTitle');
                return '';
            }

            return (
                'Are you sure you want to delete your Reki: <br><br> <b>' +
                this.deleteRecommendation.name +
                '</b></br><br>'
            );
        },

        async okToDeleteRecommendation() {
            if (!this.deleteRecommendation) {
                logInvalidParams(this.$options.name, 'okToDeleteRecommendation');
                return;
            }

            try {
                const deletedPublicUrl = this.deleteRecommendation.contentPublicUrl;

                await ApiUtils.apiWrapper(
                    ContentService.deleteRecommendation,
                    this.deleteRecommendation.contentId as number,
                );

                this.fetchContent();

                analytics.logAppInteraction(analytics.ANALYTICS_ACTION_DELETE_RECOMMENDATION, deletedPublicUrl);
            } catch (error) {
                Utils.CommonErrorHandler(error);
            }
        },

        gotoDetails(item: ContentV2) {
            this.$router.push({
                name: constants.ROUTE_CONTENT_DETAILS,
                params: { contentPublicUrl: item.contentPublicUrl as string },
            });
        },

        gotoComments(item: ContentV2) {
            this.$router.push({
                name: constants.ROUTE_CONTENT_COMMENTS,
                params: { contentPublicUrl: item.contentPublicUrl as string },
            });
        },
    },
});
