
    import mixins from 'vue-typed-mixins';
    import VueConstants from '@/components/VueConstants';
    import SplitSearch from '@/components/explore/SplitSearch.vue';

    export default mixins(VueConstants).extend({
        name: 'Search',

        components: {
            'split-search': SplitSearch,
        },

        props: {},

        data() {
            return {};
        },

        watch: {},

        computed: {},

        methods: {},
    });
