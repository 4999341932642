import { render, staticRenderFns } from "./ContentRecommendationV2.vue?vue&type=template&id=5c8c2da5&scoped=true&"
import script from "./ContentRecommendationV2.vue?vue&type=script&lang=ts&"
export * from "./ContentRecommendationV2.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c8c2da5",
  null
  
)

export default component.exports