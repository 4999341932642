
    import { PropOptions } from 'vue';
    import mixins from 'vue-typed-mixins';
    import VueConstants from '@/components/VueConstants';
    import { GenericImage, ContentV2 } from 'api';
    import { logInvalidParams } from '@/tsfiles/errorlog';

    export default mixins(VueConstants).extend({
        name: 'ProfileBackdrop',

        components: {},

        props: {
            profileBackdrop: {
                type: Object,
                required: false,
                default: () => undefined as GenericImage | undefined,
            } as PropOptions<GenericImage>,

            top10List: {
                type: Array,
                required: false,
                default: () => [] as ContentV2[],
            } as PropOptions<ContentV2[]>,
        },

        data() {
            return {
                smNumRows: 1,
                smNumCols: 1,
                xsNumRows: 1,
                xsNumCols: 1,
            };
        },

        watch: {
            top10List: {
                immediate: true,
                deep: true,
                handler(newVal: ContentV2[], oldVal: ContentV2[]) {
                    this.processList(newVal);
                },
            },
        },

        computed: {},

        methods: {
            //
            // Figure out the number of columns and rows we want for XS and SM breakpoints.
            // Anything at MD or above is just one row showing everying in the list.
            //
            processList(list: ContentV2[]) {
                if (list.length > 10) {
                    logInvalidParams(this.$options.name, 'processList');
                    return;
                }

                this.xsNumRows = 1;
                this.xsNumCols = this.top10List.length;

                this.smNumRows = 1;
                this.smNumCols = this.top10List.length;

                //
                // SM will only have 2 rows if there are 10 items in the list.
                // SM will show all items except when 9 items.  9 items will default
                // back down to 8.
                //
                // XS will have 2 rows for 6 or more items.  7 and 9 items will
                // only show 6 and 8 respectively.
                //
                switch (this.top10List.length) {
                    case 6:
                    case 7:
                        this.xsNumRows = 2;
                        this.xsNumCols = 3;
                        break;
                    case 8:
                    case 9:
                        this.xsNumRows = 2;
                        this.xsNumCols = 4;
                        this.smNumCols = 8;
                        break;
                    case 10:
                        this.xsNumRows = 2;
                        this.xsNumCols = 5;
                        this.smNumRows = 2;
                        this.smNumCols = 5;
                        break;
                }
            },

            getTop10ImageSrc(idx: number): string {
                if (idx < 0 || idx >= this.top10List.length) {
                    logInvalidParams(this.$options.name, 'getTop10ImageSrc', idx);
                    return '';
                }

                if (this.top10List[idx].posterUrl) {
                    return this.top10List[idx].posterUrl as string;
                } else if (this.top10List[idx].backdropUrl) {
                    return this.top10List[idx].backdropUrl as string;
                }

                logInvalidParams(this.$options.name, 'getTop10ImageSrc: no backdrop or poster');
                return '';
            },
        },
    });
