
    import Vue from 'vue';
    import { logInvalidParams } from '@/tsfiles/errorlog';
    import * as constants from '@/tsfiles/constants';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { Utils } from '@/tsfiles/utils';
    import { UserService, User } from 'api';

    export default Vue.extend({
        name: 'SquarespaceRedirect',

        components: {},

        props: {},

        data() {
            return {
                qrcodeUrl: '',
            };
        },

        mounted() {
            this.fetchUser();
        },

        watch: {},

        computed: {},

        methods: {
            async fetchUser() {
                if (!this.$router.currentRoute.query.id) {
                    logInvalidParams(this.$options.name, 'fetchUser: empty id');
                    return;
                }

                const val = parseInt(this.$router.currentRoute.query.id as string, 10);
                if (isNaN(val)) {
                    logInvalidParams(
                        this.$options.name,
                        'fetchUser: invalid Id - ' + this.$router.currentRoute.query.id,
                    );
                    return;
                }

                try {
                    const user = (await ApiUtils.apiWrapper(UserService.getUserById, val)) as User;

                    //
                    // Goto user, adding in ds (downloadSource) as a parameter.  The router
                    // afterEach will pick up and store the ds in the adChannel
                    //
                    this.$router.replace({ path: '/@' + user.publicUrl + '?ds=user-' + val });
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },
        },
    });
