
    import mixins from 'vue-typed-mixins';
    import { Utils } from '@/tsfiles/utils';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { logInvalidParams } from '@/tsfiles/errorlog';
    import { PageNavigationData } from '@/tsfiles/interfaces';
    import * as constants from '@/tsfiles/constants';
    import VueConstants from '@/components/VueConstants';
    import EventBus from '@/eventbus';
    import NewsPost from '@/components/post/NewsPost.vue';
    import Avatar from '@/components/Avatar.vue';
    import StarRating from '@/components/uiutils/StarRating.vue';
    import { DateTime as LuxonDateTime } from 'luxon';
    import { PubSubEvent } from '@/tsfiles/pubsub';
    import { ContentService, ContentV2, PostService, Post, GetPostsRequest, SharedConstants } from 'api';

    export default mixins(VueConstants).extend({
        name: 'ContentChat',

        components: {
            'news-post': NewsPost,
            'url-avatar': Avatar,
            'star-rating': StarRating,
        },

        props: {
            readOnly: {
                type: Boolean,
                default: false,
            },

            // Display comments for this content
            contentPublicUrl: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                currentTab: 0,
                message: '',
                posts: [] as Post[],
                submitting: false,
                fetchingPosts: false,

                content: undefined as ContentV2 | undefined,
                currentNavigation: { page: constants.ROUTE_CONTENT_CHAT } as PageNavigationData,
            };
        },

        watch: {},

        mounted() {
            // The user may not be able to post, but they still should
            // see the post updates.
            EventBus.$on(SharedConstants.NOTIFICATION_NEW_POST, this.postAdded);

            this.getContentId();

            //
            // If page navigation contains data, process.
            //
            const nav = this.$store.getters.getPageNavigation(constants.ROUTE_CONTENT_CHAT);
            if (nav && nav.tab) {
                this.$nextTick(() => {
                    this.currentTab = nav.tab;
                    this.currentNavigation.tab = nav.tab;
                });
            }
        },

        beforeDestroy() {
            EventBus.$off(SharedConstants.NOTIFICATION_NEW_POST, this.postAdded);
            this.$store.commit('setPageNavigation', this.currentNavigation);
        },

        computed: {
            maxPostCharacterLength(): number {
                return 100;
            },

            getGenreStr(): string {
                if (!this.content || !this.content.genreJson) {
                    return '';
                }

                const data = JSON.parse(this.content.genreJson);
                if (!data.genres || data.genres.length === 0) {
                    return '';
                }

                return data.genres.join(', ');
            },
        },

        methods: {
            async getContentId() {
                try {
                    const ret = await ApiUtils.apiWrapper(
                        ContentService.getContentIdByPublicUrl,
                        this.contentPublicUrl,
                    );

                    if (ret && ret.id) {
                        this.content = await ApiUtils.apiWrapper(ContentService.getContentDetailsV2, {
                            contentId: ret.id,
                            publicUrl: this.$router.currentRoute.params.publicUrl,
                        });

                        this.fetchData();
                    }
                } catch (error: any) {
                    if (error.response.status !== 404) {
                        Utils.CommonErrorHandler(error);
                    }
                }
            },

            showDateHeader(idx: number): boolean {
                if (idx < 0 || idx >= this.posts.length) {
                    return false;
                }

                const curDate = LuxonDateTime.fromISO(this.posts[idx].timestamp).toRelativeCalendar();
                if (curDate === 'today') {
                    return false;
                } else if (idx === 0) {
                    return true;
                } else {
                    const prevDate = LuxonDateTime.fromISO(this.posts[idx - 1].timestamp).toRelativeCalendar();
                    return curDate !== prevDate;
                }

                return false;
            },

            currentDateHeader(item: Post): string {
                if (!item) {
                    return '';
                }

                const curDate = LuxonDateTime.fromISO(item.timestamp).toRelativeCalendar();
                if (curDate === 'today') {
                    return '';
                } else if (curDate === 'yesterday') {
                    return 'Yesterday';
                } else {
                    return LuxonDateTime.fromISO(item.timestamp).toLocaleString(LuxonDateTime.DATE_FULL);
                }
            },
            postAdded(msg: PubSubEvent) {
                this.fetchData();
            },

            async fetchData() {
                if (!this.content) {
                    logInvalidParams(this.$options.name, 'fetchData');
                    return;
                }

                if (this.fetchingPosts) {
                    return;
                }

                this.fetchingPosts = true;

                try {
                    const ret = await ApiUtils.apiWrapper(PostService.getPosts, {
                        contentId: this.content.contentId,
                    } as GetPostsRequest);

                    if (ret.posts) {
                        this.posts = ret.posts as Post[];
                    } else {
                        this.posts = [] as Post[];
                    }
                    this.fetchingPosts = false;
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async handleSubmit(event: Event) {
                if (!this.content) {
                    logInvalidParams(this.$options.name, 'handleSubmit');
                    return;
                }

                if (this.fetchingPosts || this.submitting || this.message === '') {
                    return;
                }

                this.submitting = true;

                const post: Post = {
                    message: JSON.stringify({ message: this.message }),
                    contentId: this.content.contentId,
                };

                try {
                    //
                    // I wanted to play the sound after the server added the Post, but
                    // safari refuses to play the sound sometimes, with their
                    // permission denied message, thinking it's programmatic playing not
                    // related to a user interaction.  Putting just before await, which
                    // seems to work
                    // If more audio work is done, pull this into something common...
                    //
                    let audio = this.$store.getters.getPostBeep;
                    if (!audio) {
                        audio = new Audio('beep.mp3');
                        this.$store.commit('setPostBeep', audio);
                    }
                    if (audio) {
                        audio.play();
                    }

                    //
                    // TODO: Should we insert the returnedPost directly into the
                    // array of Posts, versus retrieving all posts again?
                    //
                    const finalPost = await ApiUtils.apiWrapper(PostService.addPost, post);

                    this.fetchData();
                    this.message = '';
                    this.submitting = false;
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            gotoDetails() {
                if (!this.content) {
                    logInvalidParams(this.$options.name, 'gotoDetails');
                    return;
                }

                this.$router.push({
                    name: constants.ROUTE_CONTENT_DETAILS,
                    params: { contentPublicUrl: this.content.contentPublicUrl as string },
                });
            },
        },
    });
