
    import Vue from 'vue';
    import validator from 'validator';
    import { logInvalidResponse } from '@/tsfiles/errorlog';
    import * as analytics from '@/tsfiles/analytics';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { Utils } from '@/tsfiles/utils';
    import { User, InviteService, InviteFriendRequest, GenericInvite } from 'api';

    export default Vue.extend({
        name: 'AddFriendsInput',

        components: {},

        props: {
            invitesAvailable: {
                type: Number,
                required: true,
            },

            showCancel: {
                type: Boolean,
                required: false,
                default: false,
            },
        },

        data() {
            return {
                submitting: false,
                phoneEmail: '',
                name: '',
                noInvitesAvailable: false,
            };
        },

        computed: {
            inputsValid(): boolean {
                if (this.phoneEmail === '') {
                    return false;
                }

                const val = this.phoneEmail.replace(/[\s\/]/g, ''); // Trim isn't good enough for validator.
                if (!validator.isEmail(val) && !validator.isMobilePhone(val, 'any')) {
                    return false;
                }

                return true;
            },
        },

        methods: {
            //
            // If phoneEmail is invalid, what is the reason displayed to the user.
            //
            invalidEmailReason(): string {
                const val = this.phoneEmail;
                if (val === '') {
                    return '';
                }

                if (val.lastIndexOf('@') !== -1) {
                    if (!validator.isEmail(val)) {
                        return 'Enter a valid email address';
                    }
                } else {
                    if (!validator.isMobilePhone(val)) {
                        return 'Enter a valid mobile number or email';
                    }
                }

                return '';
            },

            //
            // Originally a comma separated list of emails/phones could be sent to the
            // server, but the UI is now only sending in one, since we ask for the user's
            // name.  The inviteFriends api returns a list of warnings that might happen.
            // We send that one error back to the parent on the 'invite-sent' emit.
            //
            async handleSave(event: Event) {
                if (this.submitting) {
                    return;
                }

                this.submitting = true;

                try {
                    let email = '';
                    let phone = Utils.FormatPhoneNumberForStorage(
                        this.phoneEmail,
                        this.$store.getters.getDeviceRegionCode,
                    );
                    if (validator.isEmail(this.phoneEmail)) {
                        email = this.phoneEmail;
                        phone = '';
                    }

                    const userList = [] as User[];
                    userList.push({
                        email,
                        phone,
                        name: this.name,
                    } as User);

                    const ret = await ApiUtils.apiWrapper(InviteService.inviteFriends, {
                        contactList: userList,
                    } as InviteFriendRequest);

                    if (!ret || !ret.list || ret.list.length !== 1) {
                        logInvalidResponse(this.$options.name, 'handleSave');
                        return;
                    }

                    const invite = ret.list[0] as GenericInvite;

                    this.submitting = false;

                    // Get contact str for emit
                    let contact = email;
                    if (!contact || contact === '') {
                        contact = phone;
                    }

                    this.$emit('invite-sent', { invite, contact });

                    // Reset inputs
                    this.phoneEmail = '';
                    this.name = '';

                    analytics.logAppInteraction(analytics.ANALYTICS_ACTION_INVITE_FRIEND);
                } catch (error: any) {
                    if (error.response.status === 429) {
                        this.noInvitesAvailable = true;
                    } else {
                        Utils.CommonErrorHandler(error);
                    }
                }
            },
        },
    });
