
    import Vue from 'vue';
    import validator from 'validator';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { Utils } from '@/tsfiles/utils';
    import { SharedConstants, AdminService } from 'api';

    export default Vue.extend({
        name: 'FlagChannelModal',

        data() {
            return {
                badInput: '',
                badReason: 'bounce',
                options: [
                    { value: 'bounce', text: 'Bounce' },
                    { value: 'spam', text: 'Spam' },
                    { value: 'complaint', text: 'Complaint' },
                ],

                alertMessage: '',
                alertHeaderText: '',
                alertType: 'danger',
            };
        },

        computed: {
            inputValid(): boolean {
                const val = this.badInput.replace(/[\s\/]/g, ''); // Trim isn't good enough for validator.
                return validator.isEmail(val) || validator.isMobilePhone(val, 'any');
            },
        },
        methods: {
            closeModal() {
                this.$emit('closed', null);
                this.badInput = '';
                this.badReason = 'bounce';
                this.alertMessage = '';
            },

            submit() {
                const val = this.badInput.replace(/[\s\/]/g, ''); // Trim isn't good enough for validator.
                if (validator.isEmail(val)) {
                    this.tellServer(true);
                } else if (validator.isMobilePhone(val, 'any')) {
                    this.tellServer(false);
                }
            },

            async tellServer(isEmail: boolean) {
                try {
                    let type = SharedConstants.CHANNEL_EMAIL;
                    let badVal = this.badInput;
                    let displayVal = this.badInput;
                    if (!isEmail) {
                        type = SharedConstants.CHANNEL_PHONE;
                        badVal = Utils.FormatPhoneNumberForStorage(
                            this.badInput,
                            this.$store.getters.getDeviceRegionCode,
                        );
                        displayVal = Utils.FormatPhoneNumberForDisplay(
                            this.badInput,
                            this.$store.getters.getDeviceRegionCode,
                        );
                    }

                    const ret = await ApiUtils.apiWrapper(AdminService.feFlagChannel, {
                        channelType: type,
                        value: badVal,
                        reason: this.badReason,
                    });

                    this.alertType = 'danger';
                    this.alertHeaderText = 'Error:';
                    switch (ret && ret.value) {
                        case SharedConstants.WARNING_RESOURCE_NOT_FOUND:
                            this.alertMessage = '<b>' + displayVal + '</b> was not found on server.';
                            break;
                        case 'AlreadyVerified':
                            this.alertMessage =
                                '<b>' +
                                displayVal +
                                '</b> is a verified email/phone authenticated user.  Investigate...';
                            break;
                        case 'AlreadyFlagged':
                            this.alertMessage = '<b>' + displayVal + '</b> has already been flagged.  Investigate...';
                            break;
                        case 'Success':
                            this.alertMessage =
                                '<b>' +
                                displayVal +
                                '</b> was successfully flagged with reason <b>' +
                                this.badReason +
                                '<b>.';
                            this.alertType = 'success';
                            this.alertHeaderText = 'Success:';
                            break;
                    }

                    this.badInput = '';
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },
        },
    });
