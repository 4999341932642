//
// Copyright 2019-2022 Reki LLC - All rights reserved.
// File: timeutils.ts
// Project: rekitv
//
import { parseISO, formatDistance, formatDistanceStrict, formatDistanceToNow, formatDistanceToNowStrict, isBefore } from 'date-fns';
import { logInvalidParams } from './errorlog';

//
// Used for converting seconds to a string, with multiple calls.
//
interface TimeUtilsHMS {
    currentStr: string;
    currentSeconds: number;
}

export class TimeUtils {

    //
    // Returns the given time value as words describing how
    // far in the future the given date is, using helper words like 'about'.
    //
    public static futureTimeDistance(timeVal: Date | undefined): string {
        if (!timeVal) {
            return '';
        }

        const dt = parseISO(timeVal.toString());
        if (isNaN(dt.getTime())) {
            logInvalidParams('TimeUtils', 'futureTimeDistance', timeVal);
            return '';
        }
        return formatDistance(dt, new Date(), { addSuffix: true });
    }

    //
    // Returns the given time value as words describing how
    // far in the future the given date is, without using helper words like 'about'.
    //
    public static futureTimeDistanceWithoutHelperWords(timeVal: Date | undefined): string {
        if (!timeVal) {
            return '';
        }

        const dt = parseISO(timeVal.toString());
        if (isNaN(dt.getTime())) {
            logInvalidParams('TimeUtils', 'futureTimeDistance', timeVal);
            return '';
        }
        return formatDistanceStrict(dt, new Date(), { addSuffix: true });
    }

    //
    // Returns the given time value as words describing how
    // far in the past the given date is, using helper words like 'about'.
    //
    public static pastTimeDistance(timeVal: Date | undefined): string {
        if (!timeVal) {
            return '';
        }

        const dt = parseISO(timeVal.toString());
        if (isNaN(dt.getTime())) {
            logInvalidParams('TimeUtils', 'pastTimeDistance', timeVal);
            return '';
        }
        return formatDistanceToNow(dt, { addSuffix: true });
    }

    //
    // Returns the given time value as words describing how
    // far in the past the given date is, without using helper words like 'about'.
    //
    public static pastTimeDistanceStringWithoutHelperWords(timeVal: Date | undefined): string {
        if (!timeVal) {
            return '';
        }

        const dt = parseISO(timeVal.toString());
        if (isNaN(dt.getTime())) {
            logInvalidParams('TimeUtils', 'pastTimeDistance', timeVal);
            return '';
        }
        return formatDistanceToNowStrict(dt, { addSuffix: true });
    }


    //
    // Returns true if the first time if before the second one.
    //
    public static isFirstTimeBeforeSecondTime(timeVal1: Date | undefined, timeVal2: Date | undefined): boolean {
        if (!timeVal1) {
            logInvalidParams('TimeUtils', 'isFirstTimeBeforeSecondTime - invalid timeVal1', timeVal1);
            return false;
        }

        if (!timeVal2) {
            logInvalidParams('TimeUtils', 'isFirstTimeBeforeSecondTime - invalid timeVal2', timeVal2);
            return false;
        }

        const dt1 = parseISO(timeVal1.toString());
        if (isNaN(dt1.getTime())) {
            logInvalidParams('isFirstTimeBeforeSecondTime',
                'isFirstTimeBeforeSecondTime - cannot parse timeVal1', timeVal1);
            return false;
        }

        const dt2 = parseISO(timeVal2.toString());
        if (isNaN(dt2.getTime())) {
            logInvalidParams('isFirstTimeBeforeSecondTime',
                'isFirstTimeBeforeSecondTime - cannot parse timeVal2', timeVal2);
            return false;
        }

        return isBefore(dt1, dt2);
    }


    //
    // Convert number of days, hours, minutes, seconds into seconds.  This
    // is used with survey runLength and runLengthType.
    //
    public static convertToSeconds(val: number, valType: string): number {
        switch (valType) {
            case 'days':
                return val * 24 * 60 * 60;
            case 'hours':
                return val * 60 * 60;
            case 'minutes':
                return val * 60;
            case 'seconds':
                return val;
            default:
                logInvalidParams('TimeUtils', 'convertToSeconds', valType);
        }

        return 0;
    }




    //
    // processSingleConversion is used with ConvertSecondsToHMSString(), once for each type (days, hours, etc.).
    // It's just a utility function to reduce the if/then inside ConvertSecondsToHMSString.  The function
    // is given total number of seconds, the math value used to dive or multiple (essentially how many
    // seconds are in this name).  For example, a name of 'days', would have a mathVal equivalent to 24*60*60.
    //
    // If there is a found interval (days, hours, etc.), return the seconds by the amount.  This is needed to
    // keep the next call working properly.  For example, the first call will be days, and the second hours.  If
    // the days's number of seconds is not removed, hours would be inflated.
    //
    // tslint:disable-next-line:max-line-length
    public static processSingleConversion(totalSeconds: number, mathVal: number, name: string, currentReturnStr: string): TimeUtilsHMS {

        const interval = Math.floor(totalSeconds / mathVal);
        if (interval > 0) {
            let pluralOrNot = name;
            if (interval > 1) {
                pluralOrNot += 's';
            }

            let ret = currentReturnStr;
            if (currentReturnStr !== '') {
                ret += ', '; // Add comma, since string already exists
            }

            return {
                currentStr: ret + interval.toString() + ' ' + pluralOrNot,
                currentSeconds: totalSeconds - (interval * mathVal),
            };
        }

        return {
            currentStr: currentReturnStr,
            currentSeconds: totalSeconds,
        };
    }

    //
    // ConvertSecondsToHMSString converts number of seconds to a string containing days, hours, minutes, seconds.
    // For example, 122 seconds would become:
    //   2 minutes, 2 seconds
    // The returned format would look like the following, if all fields filled in:
    //   N days, N hours, N minutes, N seconds
    // The 's' is added only if N is greater than 1.
    //
    public static ConvertSecondsToHMSString(givenSeconds: number): string {
        let ret = TimeUtils.processSingleConversion(givenSeconds, 60 * 60 * 24, 'day', '');
        ret = TimeUtils.processSingleConversion(ret.currentSeconds, 60 * 60, 'hour', ret.currentStr);
        ret = TimeUtils.processSingleConversion(ret.currentSeconds, 60, 'minute', ret.currentStr);
        ret = TimeUtils.processSingleConversion(ret.currentSeconds, 1, 'second', ret.currentStr);
        return ret.currentStr;
    }
}

const timeutils = new TimeUtils();
export default timeutils;
