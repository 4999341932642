
    import Vue from 'vue';

    export default Vue.extend({
        name: 'ViewAllComments',

        components: {},

        props: {
            numFolloweeComments: {
                type: Number,
                required: false,
                default: 0,
            },
            numWorldComments: {
                type: Number,
                required: false,
                default: 0,
            },
        },

        data() {
            return {};
        },

        watch: {},

        computed: {},

        methods: {
            getCommentCount(val: number | undefined): number {
                if (!val) {
                    return 0;
                }

                return val;
            },
        },
    });
