
    import Vue from 'vue';
    import * as constants from '@/tsfiles/constants';
    import Marketing from '@/components/Marketing.vue';

    export default Vue.extend({
        name: 'MarketingHome',

        components: {
            'marketing-ui': Marketing,
        },

        props: {},

        data() {
            return {};
        },

        //
        // If signed in go to their home page (timeline).
        //
        mounted() {
            if (this.$store.getters.isSignedIn) {
                this.$router.replace({
                    name: constants.ROUTE_USER_TIMELINE,
                    params: { publicUrl: this.$store.getters.getPublicUrl },
                });
            }
        },

        computed: {},

        methods: {},
    });
