
    import mixins from 'vue-typed-mixins';
    import store from '@/tsfiles/store';
    import { mapGetters } from 'vuex';
    import { log } from '@/tsfiles/errorlog';
    import * as analytics from '@/tsfiles/analytics';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import config from '@/config';
    import { afterIdentityKnown } from '@/tsfiles/router';
    import EventBus from '@/eventbus';
    import VueConstants from '@/components/VueConstants';
    import * as constants from '@/tsfiles/constants';
    import { PageMessage } from '@/tsfiles/interfaces';
    import { profileRefresh } from '@/tsfiles/profilerefresh';
    import { PubSubClient, PubSubEvent } from '@/tsfiles/pubsub';
    import { Utils } from '@/tsfiles/utils';
    import { SigninStatus } from '@/tsfiles/enums';
    import { Session } from '@/tsfiles/interfaces';
    import StickyHeader from '@/components/StickyHeader.vue';
    import MainMenu from '@/components/MainMenu.vue';
    import StickyFooter from '@/components/StickyFooter.vue';
    import {
        SharedConstants,
        AuthService,
        UserIdentity,
        NotificationList,
        Notification,
        NotificationService,
        GenericPageRetrieval,
    } from 'api';

    export default mixins(VueConstants).extend({
        name: 'MainApp',

        components: {
            'sticky-header': StickyHeader,
            'main-menu': MainMenu,
            'sticky-footer': StickyFooter,
        },

        data() {
            return {
                pubSubClient: undefined as PubSubClient | undefined,
                notificationList: undefined as NotificationList | undefined,
                requireName: false,
                usersName: '',

                // TEST: If testing signin out of sync, set to true
                testSignin: false && config.runEnvironment === 'dev',
            };
        },

        watch: {
            //
            // We only want to fetch the data, when signed-in goes from false to true.
            //
            // Whenever we refresh (reload MainApp), we will start out as not signed in,
            // and then flip to signed in if identity is retrieved.  The registerDevice
            // happens inside this state change, which will detect a device token change
            // whenever the app starts.  During firebase signin, we do send in the
            // device, but that's only used to see if this is an new account creation from
            // the app.  We cannot use that to detect a device token change, which can happen
            // at any time (at least for iOS).
            //
            signinState: {
                immediate: true,
                handler(newVal, oldVal) {
                    if (newVal && !oldVal) {
                        // fresh signin, or identity retrieval complete.
                        this.retrieveAndProcessNotifications();
                        this.setupSubscriber();

                        this.registerDevice(this.$store.getters.getDevice);

                        this.requireName =
                            this.$store.getters.isSignedIn && this.$store.state.session.displayName === '';

                        //
                        // Reset some store values that may have been set previously, such
                        // as from a header search result.
                        //
                        this.$store.commit('setCurrentMenu', constants.ROUTE_USER_TIMELINE);
                    }
                },
            },

            //
            // Add fake friends if we are explicitly signed out (not UNKNOWN or SIGNEDIN state).
            // Do any other cleanup needed when signed out.  Don't forget to test going from
            // signed in to out.
            //
            signoutState: {
                immediate: true,
                handler(newVal, oldVal) {
                    if (newVal) {
                        this.notificationList = undefined as NotificationList | undefined;
                        this.stopSubscriber();
                        this.requireName = false;
                    }
                },
            },

            isPhoneVerified: {
                immediate: true,
                handler(newVal, oldVal) {
                    if (newVal !== oldVal) {
                        this.setPhoneEmailVerifiedPageMessage();
                    }
                },
            },

            isEmailVerified: {
                immediate: true,
                handler(newVal, oldVal) {
                    if (newVal !== oldVal) {
                        this.setPhoneEmailVerifiedPageMessage();
                    }
                },
            },
        },

        //
        // It appears Google login via Firebase causes MainApp mounted() to be called again, but
        // it looks like it's doing a full refresh of the page.  Doing normal username/password
        // signin does not do this. I don't think it's a real problem, since it's acting like a
        // full refresh.  We aren't getting multiple EventListeners or EventBus callbacks
        // registered, but it does make app debugging look like something it wrong.  You will
        // see secondary 'appMounted' and 'appWebViewLoaded' calls.  Other than extra overhead
        // all the way down to the DB, nothing is really wrong.
        //
        // One note, when debugging, you can get mounted called each time you save a file in
        // the editor, triggered by webpack/babel?
        //
        mounted() {
            // See if we are on a touch device, and what type if possible.
            window.addEventListener(
                'touchstart',
                function onFirstTouch() {
                    store.commit('setTouchScreen', true);

                    // Only need to get the first touch event
                    window.removeEventListener('touchstart', onFirstTouch, false);
                },
                false,
            );

            // Store beep used for post submission
            this.$store.commit('setPostBeep', new Audio('beep.mp3'));

            //
            // Set the AppType cookie to 'web', which will included in error output.  This
            // helps determine if the error is from the web, ios, or android.
            // NOTE: if the UI is not refreshed within 7 days, the cookie will expire.
            // In Safari and Brave, client side cookies cannot have an expiration
            // longer than 7 days.  It's possible the user leaves the page open and never
            // does anything that will cause a refresh, but the apps won't have this
            // problem so we can still distinguish between apps and webUI.
            //
            Utils.setCookie('AppType', 'web', 365);
        },

        beforeDestroy() {
            this.stopSubscriber();
        },

        computed: {
            // Map store isSignedIn so we can watch for completion of signin.
            ...mapGetters({
                signinState: 'isSignedIn',
                signoutState: 'isSignedOut',
                isPhoneVerified: 'isPhoneVerified',
                isEmailVerified: 'isEmailVerified',
            }),

            nameValid(): boolean {
                return this.usersName !== '';
            },
        },

        methods: {
            //
            // If the nginx oos.html page is up (site down or in maintenance), no one can
            // get into the site. It might be running, but having trouble when we put
            // up the OOS page.  Devs should be able to get in to help diagnose.  The
            // cookie can be set, which will let the dev in, and make the UI background
            // a specific, nasty looking color.  This way they know they have the cookie
            // set, and the site might actually be down.  If we didn't do this the dev
            // could think the site is fine, when it's actually down for real users.
            // It should be ok to list the cookie name here, since a hacker would not
            // bet able to set the cookie to the needed value.
            //
            hasDevSiteDownAccess(): boolean {
                return document.cookie.match(new RegExp('LetMeIn' + '=([^;]+)')) !== null;
            },

            //
            // Set up pubsub (nginx nchan), so we can get async messages from server.  This is
            // only done once we know there's a valid signin.
            // To test, which must be done inside a container (use api):
            // curl --request POST --data '{"message": "Hello"}' http://nginx:10081/pub/<userId>
            //
            setupSubscriber() {
                this.pubSubClient = new PubSubClient((message) => this.messageNotification(message));
                if (!this.pubSubClient) {
                    log('Error creating pubSubClient');
                }
            },

            //
            // If the user just signed out, or when destroying the vue, stop listening
            // for subscriber messages.
            //
            stopSubscriber() {
                if (this.pubSubClient) {
                    this.pubSubClient.stop();
                    this.pubSubClient = undefined;
                }
            },

            //
            // Handle published messages from the server (via nginx nchan).  Use the EventBus to
            // publish to components that need to handle in a special way.  Only some are
            // handled here.
            //
            messageNotification(message: PubSubEvent) {
                // console.log('messageNotification: ', message);
                if (!message || message.event === '') {
                    log('Empty PubSub Message');
                    return;
                }

                // The UI will figure out what to do based on the message
                switch (message.event) {
                    case SharedConstants.PUBSUB_REFRESH_NOTIFICATIONS:
                        this.retrieveAndProcessNotifications();
                        break;
                    case SharedConstants.PUBSUB_REFRESH_USER_PROFILE:
                        profileRefresh();

                        // Reprocess notifications as part of the refresh.
                        this.retrieveAndProcessNotifications();
                        break;
                    case SharedConstants.PUBSUB_TURN_ON_USER_ANALYTICS:
                        analytics.turnOnUserAnalytics();
                        break;
                    case SharedConstants.PUBSUB_TURN_OFF_USER_ANALYTICS:
                        analytics.turnOffUserAnalytics();
                        break;
                    case SharedConstants.PUBSUB_REFRESH_FRIENDS:
                    case SharedConstants.PUBSUB_REFRESH_FRIEND_RECOMMENDATIONS:
                        // Reprocess notifications since they may have changed
                        this.retrieveAndProcessNotifications();
                        break;
                    default:
                        // Event might be handled by a component accessing via EventBus.
                        break;
                }

                EventBus.$emit(message.event, message);
            },

            async retrieveAndProcessNotifications() {
                try {
                    //
                    // For the header notification list, just retrieve the most
                    // recent 10 notifications.
                    //
                    const ret = await ApiUtils.apiWrapper(NotificationService.getUserNotifications, {
                        pageNumber: 1,
                        numberOfItems: constants.HEADER_NUMBER_OF_NOTIFICATIONS,
                        filterBy: [{ type: SharedConstants.FILTER_NOTIFICATIONS_UNREAD_ONLY }],
                    } as GenericPageRetrieval);

                    if (ret && ret.list) {
                        //
                        // Find out which ones are new, so we can retrieve any info that's needed, or
                        // let other components know about the new notification.
                        //
                        if (this.notificationList && this.notificationList.list) {
                            const idHash: { [key: string]: number } = {};
                            for (const n of this.notificationList.list) {
                                const id = n.id as number;
                                idHash[id] = !n.count ? 0 : n.count;
                            }

                            for (const n of ret.list) {
                                if (!n || n.event === undefined || n.event === '') {
                                    continue;
                                }

                                const id = n.id as number;
                                // Is this a new notification we don't already have?
                                if (!idHash[id] || idHash[id] !== n.count) {
                                    // Let components decide if they need to do anything specific.
                                    const newEvent = { event: n.event, jsonData: n.jsonData } as PubSubEvent;
                                    EventBus.$emit(n.event, newEvent);

                                    console.log('publish notification db message: ', newEvent);
                                }
                            }
                        }
                        this.notificationList = ret; // Contains total unread, which Notifications.vue needs.
                    } else {
                        this.notificationList = undefined as NotificationList | undefined;
                    }

                    this.checkPageMessages();
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            //
            // PageMessages are tied to notifications coming from the server.  This
            // function will see if any existing page messages need to be delete.
            //
            // At the end, see if we need to add the fake message about phone/email verification.
            //
            checkPageMessages() {
                for (const msg of this.$store.getters.getPageMessages) {
                    let foundMatch = false;
                    if (this.notificationList && this.notificationList.list) {
                        for (const notification of this.notificationList.list) {
                            if (notification.id === msg.notification.id) {
                                foundMatch = true;
                                break;
                            }
                        }
                    }

                    if (!foundMatch) {
                        this.$store.commit('clearPageMessage', msg.notification.id);
                    }
                }

                this.setPhoneEmailVerifiedPageMessage();
            },

            //
            // The user must have their email or phone verified in order to add content.
            // Put in a PageMessage if needed.
            //
            setPhoneEmailVerifiedPageMessage() {
                if (
                    this.$store.getters.isSignedIn &&
                    !this.$store.getters.isPhoneVerified &&
                    !this.$store.getters.isEmailVerified
                ) {
                    this.$store.commit('setPageMessage', {
                        notification: {
                            id: 0,
                            event: constants.FAKE_NOTIFICATION_EMAIL_PHONE_VERIFICATION_REQUIRED,
                        } as Notification,
                        permanent: true,
                        targetPages: [constants.ROUTE_USER_HOME],
                    } as PageMessage);
                } else {
                    // Make sure the fake notification is cleared
                    this.$store.commit('clearPageMessage', 0);
                }
            },

            //
            // Mark as read any notifications that match the given data.  This comes from a child,
            // most likely the header notification component.
            //
            async clearSingleNotification(data: Notification) {
                if (!data || !data.id) {
                    return; // ERROR
                }

                // Find the notification in our list, remove it and tell the server.
                try {
                    let foundIt = false;
                    if (this.notificationList && this.notificationList.list) {
                        for (let i = 0; i < this.notificationList.list.length; i++) {
                            if (this.notificationList.list[i].id === data.id) {
                                await ApiUtils.apiWrapper(
                                    NotificationService.markAsReadNotification,
                                    this.notificationList.list[i].id as number,
                                );

                                // Must come after server notification, since we are indexing array directly
                                this.notificationList.list.splice(i, 1);
                                foundIt = true;
                                break;
                            }
                        }
                    }

                    if (foundIt) {
                        this.retrieveAndProcessNotifications();
                    }
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            //
            // Clear out all notifications.  This comes from a child, most likely the header
            // notification component.
            //
            async clearNotifications(data: any) {
                this.notificationList = undefined as NotificationList | undefined;

                try {
                    await ApiUtils.apiWrapper(NotificationService.markAsReadAllNotifications);
                    this.retrieveAndProcessNotifications();
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async impersonateUser(publicUrl: string) {
                this.stopSubscriber();

                try {
                    let identity = null as UserIdentity | null;
                    let revertedToMaster = false;

                    // Revert to master if publicUrl is the sane as masterPublicUrl
                    if (publicUrl === this.$store.state.session.masterPublicUrl) {
                        identity = await ApiUtils.apiWrapper(
                            AuthService.revertToMaster,
                            this.$store.state.session.masterPublicUrl,
                        );
                        revertedToMaster = true;
                    } else {
                        identity = await ApiUtils.apiWrapper(AuthService.adminSigninAs, publicUrl);
                    }

                    if (identity) {
                        const session: Session = {
                            ...identity.profileData,
                        } as Session;

                        this.$store.commit('setSession', session);
                        this.setupSubscriber();
                        this.retrieveAndProcessNotifications();

                        //
                        // If reverting to master, make sure they are on their home page.  We
                        // don't want to be sitting on the previously impersonated user's pages.
                        //
                        if (revertedToMaster && identity.profileData && identity.profileData.publicUrl) {
                            const mastersPublicUrl = identity.profileData.publicUrl;
                            if (this.$router.currentRoute.params.publicUrl !== mastersPublicUrl) {
                                this.$router.replace({
                                    name: constants.ROUTE_USER_HOME,
                                    params: { publicUrl: mastersPublicUrl },
                                });
                            }
                        }
                    }
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            incrementSigninState(whichOne: string) {
                let curState = this.$store.getters.getFirebaseSigninState;
                if (whichOne !== 'firebase') {
                    curState = this.$store.getters.getServerSigninState;
                }

                let newState = SigninStatus.UNKNOWN;
                switch (curState) {
                    case SigninStatus.UNKNOWN:
                        newState = SigninStatus.SIGNEDIN;
                        break;
                    case SigninStatus.SIGNEDIN:
                        newState = SigninStatus.SIGNEDOUT;
                        break;
                }

                if (whichOne === 'firebase') {
                    this.$store.commit('setFirebaseSigninState', newState);
                } else {
                    this.$store.commit('setServerSigninState', newState);
                }
            },

            //
            // Handle incoming notification from the app, which only come in when the app
            // is in the background, or if shutdown and user clicks on the ios notification
            // to start up the app.  This appNotification would come in after the site
            // is loaded in webview and running.
            //
            // TODO: look into notificationClick() in StickyHeader to see if we can make this stuff common,
            // once we see how notifications actually are working.
            //
            processAppNotification(req: any) {
                const data = req.data;
                console.log('processAppNotification: ', data);
            },

            //
            // We should only send in registration information for signed in users.
            //
            async registerDevice(data: any) {
                if (data && data.type && data.token !== '') {
                    try {
                        // store if we need to do keyboard hack, now that we have the device info.
                        if (Utils.needNumericKeyboardHack(data.info, navigator.userAgent)) {
                            this.$store.commit('setNumericKeyboardHack', true);
                        }
                        await ApiUtils.apiWrapper(AuthService.registerDevice, data);
                    } catch (error) {
                        Utils.CommonErrorHandler(error);
                    }
                }
            },

            //
            // Set the given required user name
            //
            async handleNameSubmit() {
                try {
                    const ident = await ApiUtils.apiWrapper(AuthService.postSigninSetNameAndPublicUrl, {
                        displayName: this.usersName,
                        publicUrl: this.$store.getters.getPublicUrl,
                    });

                    afterIdentityKnown(ident);

                    this.requireName = this.$store.getters.isSignedIn && this.$store.state.session.displayName === '';

                    // Go to home page if not already there
                    if (this.$router.currentRoute.name !== constants.ROUTE_USER_HOME) {
                        this.$router.push({
                            name: constants.ROUTE_USER_HOME,
                            params: { publicUrl: this.$store.getters.getPublicUrl },
                        });
                    }
                } catch (error: any) {
                    Utils.CommonErrorHandler(error);
                }
            },
        },
    });
