
    import Vue from 'vue';
    import { logInvalidParams, logAccessDenied } from '@/tsfiles/errorlog';
    import * as constants from '@/tsfiles/constants';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { Utils } from '@/tsfiles/utils';
    import { RoleUtils } from '@/tsfiles/roleutils';
    import SuperAdminInviteModal from '@/generic-modals/SuperAdminInviteModal.vue';
    import ModalAlert from '@/generic-modals/ModalAlert.vue';
    import { SharedConstants, AdminService, InviteService, AdminRole, AdminRoleList, GenericInvite } from 'api';

    export default Vue.extend({
        name: 'SuperAdminRoles',

        components: {
            'super-admin-invite-modal': SuperAdminInviteModal,
            'modal-alert': ModalAlert,
        },

        props: {},

        data() {
            return {
                showAdminInviteModal: false,
                inviteToDelete: undefined as GenericInvite | undefined,
                submitting: false,
                adminsFetched: false,
                currentAdmins: [] as AdminRoleList[],
                invitedAdmins: [] as GenericInvite[],
                selectedCheckboxes: [] as string[][],

                options: [
                    { text: 'Server Admin', value: SharedConstants.USER_SERVER_ADMIN },
                    { text: 'Support Admin', value: SharedConstants.USER_SUPPORT_ADMIN },
                    { text: 'Stats Admin', value: SharedConstants.USER_STATS_ADMIN },
                ],
            };
        },

        mounted() {
            // Just double-check that the current user has access
            if (!RoleUtils.IsServerSuperAdmin()) {
                logAccessDenied(this.$options.name, 'mounted');
                this.$router.replace({ name: constants.ROUTE_MARKETING });
            }

            this.getSuperAdmins();
        },

        computed: {
            //
            // Get the text inside the delete alert box.
            //
            deleteOkText(): string {
                if (
                    !this.inviteToDelete ||
                    !this.inviteToDelete.recipient ||
                    !this.inviteToDelete.recipient.name ||
                    !this.inviteToDelete.recipient.publicUrl
                ) {
                    logInvalidParams(this.$options.name, 'deleteOkText');
                    return '';
                }

                return (
                    'Are you sure you want to delete the super admin invitation for: <br><br><b>' +
                    this.inviteToDelete.recipient.name +
                    ' (@' +
                    this.inviteToDelete.recipient.publicUrl +
                    ')</b><br><br>'
                );
            },
        },

        methods: {
            //
            // If true is returned, the submit button will be disabled.  The submit
            // button should only be active if something changed.
            //
            disableSubmit(): boolean {
                return !this.anyAdminDirty() || !this.atLeastOneSuperAdmin();
            },

            //
            // Return true if any of the admins are dirty, meaning one of their
            // checkboxes has been changed.
            //
            anyAdminDirty(): boolean {
                for (let i = 0; i < this.currentAdmins.length; i++) {
                    if (this.isDirty(i)) {
                        return true;
                    }
                }

                return false;
            },

            //
            // Return true if at least one super admin as USER_SERVER_ADMIN role.
            // There needs to be at least one user that can edit roles.
            //
            atLeastOneSuperAdmin(): boolean {
                for (let i = 0; i < this.currentAdmins.length; i++) {
                    for (const checkboxValue of this.selectedCheckboxes[i]) {
                        if (checkboxValue === SharedConstants.USER_SERVER_ADMIN) {
                            return true;
                        }
                    }
                }

                return false;
            },

            //
            // Not very efficient doing double-loop twice.
            //
            isDirty(idx: number): boolean {
                // Loop1: anything in selectedCheckboxes but not in user's roles would be dirty
                for (const checkboxValue of this.selectedCheckboxes[idx]) {
                    let foundIt = false;
                    for (const role of this.currentAdmins[idx].roles as AdminRole[]) {
                        if (checkboxValue === role.role) {
                            foundIt = true;
                            break;
                        }
                    }

                    if (!foundIt) {
                        return true;
                    }
                }

                // Loop2: anything in user's roles but not in selectedCheckboxes would be dirty
                for (const role of this.currentAdmins[idx].roles as AdminRole[]) {
                    let foundIt = false;
                    for (const checkboxValue of this.selectedCheckboxes[idx]) {
                        if (checkboxValue === role.role) {
                            foundIt = true;
                            break;
                        }
                    }
                    if (!foundIt) {
                        return true;
                    }
                }

                return false;
            },

            // Cancel button clicked, so reset checkboxes.
            cancel() {
                this.setCheckboxes();
            },

            //
            // Set the selectedCheckboxes array for each admin.  This string array
            // is used by b-form-checkbox-group as the v-model.
            //
            setCheckboxes() {
                for (let idx = 0; idx < this.currentAdmins.length; idx++) {
                    const roles = this.currentAdmins[idx].roles as AdminRole[];

                    Vue.set(this.selectedCheckboxes, idx, [] as string[]);

                    let num = 0;
                    for (const role of roles) {
                        this.selectedCheckboxes[idx][num] = role.role as string;
                        num++;
                    }
                }
            },

            //
            // Retrieve all the super admins from the server.
            //
            async getSuperAdmins() {
                try {
                    //
                    // Get the list of current admins first
                    //
                    const ret = await ApiUtils.apiWrapper(AdminService.feGetSuperAdminUsers);
                    if (ret && ret.users && ret.users.length > 0) {
                        this.currentAdmins = JSON.parse(JSON.stringify(ret.users));
                    } else {
                        this.currentAdmins = [] as AdminRoleList[];
                    }

                    this.setCheckboxes();

                    //
                    // Now get the list of invited super admins.
                    //
                    const inviteRet = await ApiUtils.apiWrapper(InviteService.getSuperAdminInvites);
                    if (inviteRet && inviteRet.list && inviteRet.list.length > 0) {
                        this.invitedAdmins = JSON.parse(JSON.stringify(inviteRet.list));
                    } else {
                        this.invitedAdmins = [] as GenericInvite[];
                    }

                    this.adminsFetched = true;
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            //
            // Submit will send in the list of checked values.  The server will
            // figure out what's new and what needs to be deleted.
            //
            async submit() {
                if (this.submitting) {
                    return;
                }
                this.submitting = true;

                //
                // Loop through each super admin, sending the new selected values to
                // the server if the roles have changed
                //
                try {
                    const currentUserRoles = [] as AdminRole[];
                    for (let i = 0; i < this.currentAdmins.length; i++) {
                        if (this.isDirty(i)) {
                            const ret = (await ApiUtils.apiWrapper(AdminService.feSetSuperAdminRoles, {
                                userId: this.currentAdmins[i].userId as number,
                                selectedRoles: this.selectedCheckboxes[i],
                            })) as AdminRoleList;

                            // If it's me being changed, I need to update my profile
                            if (this.$store.getters.isUsersPublicUrl(this.currentAdmins[i].publicUrl)) {
                                this.$store.commit('setSessionRoles', ret.roles);
                            }
                        }
                    }

                    //
                    // Refetch all super admins, which will reset the checkboxes.  Not
                    // super efficient, but ok for minimal usage it will get.
                    //
                    this.getSuperAdmins();
                    this.submitting = false;
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            //
            // Return the display line of the invited super admin's roles.  We look at the
            // options array used by the checkboxes to get the human readable
            // value of the role.
            //
            getSuperAdminInviteRoleDisplay(inviteJsonData: string | undefined): string {
                if (!inviteJsonData) {
                    logInvalidParams(this.$options.name, 'getSuperAdminInviteRoleDisplay-1');
                    return '';
                }

                const roles = JSON.parse(inviteJsonData);
                if (!roles || roles.length === 0) {
                    logInvalidParams(this.$options.name, 'getSuperAdminInviteRoleDisplay-2');
                    return '';
                }

                let ret = '';
                for (const role of roles) {
                    for (const option of this.options) {
                        if (option.value === role) {
                            if (ret !== '') {
                                ret += ', ';
                            }
                            ret += option.text;
                        }
                    }
                }

                return ret;
            },

            //
            // The super admin invite modal closed.
            //
            superAdminInviteSent() {
                this.showAdminInviteModal = false;
                this.getSuperAdmins();
            },

            //
            // User said it's ok to delete the super admin invite
            //
            async okToDeleteInvite() {
                if (!this.inviteToDelete || !this.inviteToDelete.code) {
                    logInvalidParams(this.$options.name, 'okToDeleteInvite');
                    return;
                }

                try {
                    await ApiUtils.apiWrapper(InviteService.deleteSuperAdminInvite, this.inviteToDelete.code);
                    this.inviteToDelete = undefined as GenericInvite | undefined;
                    this.getSuperAdmins();
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },
        },
    });
