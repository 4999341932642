
    import mixins from 'vue-typed-mixins';
    import { logInvalidParams } from '@/tsfiles/errorlog';
    import VueConstants from '@/components/VueConstants';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { Utils } from '@/tsfiles/utils';
    import { profileRefresh } from '@/tsfiles/profilerefresh';
    import DotAnimation from '@/components/uiutils/DotAnimation.vue';
    import { UserService, SharedConstants } from 'api';

    const minWaitTimeForCompletionMessage = 2; // Seconds

    export default mixins(VueConstants).extend({
        name: 'VerifyPhone',

        components: {
            'dot-animation': DotAnimation,
        },

        props: {
            // Phone, which should be the signed in user's phone
            phone: {
                type: String,
                required: true,
            },

            // Ask for verification only
            codeOnly: {
                type: Boolean,
                required: false,
                default: false,
            },
        },

        data() {
            return {
                showPhoneSending: false,
                verifyCodeSent: false,
                alertMessage: '',
                verificationCode: undefined as number | undefined,
                localCodeOnly: this.codeOnly,
            };
        },

        computed: {
            getFormattedPhoneForDisplay(): string {
                return Utils.FormatPhoneNumberForDisplay(this.phone, this.$store.getters.getDeviceRegionCode);
            },

            verificationCodeValid(): boolean {
                if (!this.verificationCode || String(this.verificationCode).includes('.')) {
                    return false;
                }
                return this.verificationCode > 0 && String(this.verificationCode).length === 6;
            },
        },

        methods: {
            getNewCode() {
                this.sendVerificationCode();
                this.localCodeOnly = false;
            },

            async sendVerificationCode() {
                this.showPhoneSending = true;

                //
                // We want to show the "Sending..." message for at least a few seconds;
                // otherwise the user might see the mesages flash.  We want to show something
                // in case the server takes a bit to complete.
                //
                const startTime = Math.round(new Date().getTime() / 1000);

                try {
                    //
                    // Default is to redirect back where the user is now.  The redirect is
                    // the path name, without the leading slash.
                    //
                    let redirectPath = this.$router.currentRoute.path;
                    if (redirectPath.startsWith('/')) {
                        redirectPath = redirectPath.substring(1);
                    }

                    await ApiUtils.apiWrapper(UserService.sendChannelVerification, {
                        channel: SharedConstants.CHANNEL_PHONE,
                        redirect: redirectPath,
                    });

                    // Wait at least a few seconds, so waitingMessage isn't a quick flash
                    const nowTime = Math.round(new Date().getTime() / 1000);
                    if (nowTime - startTime < minWaitTimeForCompletionMessage) {
                        await new Promise((resolve) =>
                            setTimeout(resolve, (minWaitTimeForCompletionMessage - (nowTime - startTime)) * 1000),
                        );
                    }

                    this.verifyCodeSent = true;
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async verifyCode() {
                if (!this.verificationCode) {
                    logInvalidParams(this.$options.name, 'verifyCode');
                    return;
                }

                try {
                    const ret = await ApiUtils.apiWrapper(
                        UserService.confirmChannelVerification,
                        this.verificationCode.toString(),
                    );

                    if (ret && ret.value) {
                        switch (ret.value) {
                            case SharedConstants.WARNING_RESOURCE_EXPIRED:
                                this.alertMessage = 'Error: That code has expired.';
                                break;
                            case SharedConstants.WARNING_RESOURCE_NOT_FOUND:
                            case SharedConstants.WARNING_ALREADY_VERIFIED:
                                this.alertMessage = 'Error: That code is invalid';
                                break;
                        }
                    } else {
                        profileRefresh();
                        this.$emit('channel-verified', SharedConstants.CHANNEL_PHONE);
                    }
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },
        },
    });
