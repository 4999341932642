
    import Vue, { PropOptions } from 'vue';
    import { SortMenuItem } from '@/tsfiles/interfaces';

    export default Vue.extend({
        name: 'SortMenu',

        components: {},

        props: {
            items: {
                type: Array,
                required: true,
            } as PropOptions<SortMenuItem[]>,

            title: {
                type: String,
                required: false,
                default: 'Sort By',
            },

            selectedItem: {
                type: String,
                required: false,
                default: 0,
            },
        },

        data() {
            return {};
        },

        watch: {},

        computed: {},

        methods: {},
    });
